import React from 'react'
import { Accordion } from "flowbite-react";

const Results = (props) => {
    const { data } = props;
    function convertToHtmlFormat(inputString) {
        // Replace "\n\n" or "\n" with <br>
        var stringWithLineBreaks = inputString.replace(/\\n\\n|\\n/g, '<br>');
    
        // Replace "\t\t\t" with three spaces enclosed in a span for indentation
        var indentedString = stringWithLineBreaks.replace(/\\t\\t\\t/g, '<span style="white-space: pre;">&nbsp;&nbsp;&nbsp;</span>');
        
        // Replace "\t\t" with two spaces enclosed in a span for indentation
        indentedString = indentedString.replace(/\\t\\t/g, '<span style="white-space: pre;">&nbsp;&nbsp;</span>');
    
        // Replace "\t" with a single space enclosed in a span for indentation
        var htmlFormattedString = indentedString.replace(/\\t/g, '<span style="white-space: pre;">&nbsp;</span>');
    
        return htmlFormattedString;
    }

  return (
    <div className="w-full rounded-xl">
            <div className="bg-white p-3 rounded-lg shadow-md mb-8">
              <div className="text-3xl font-medium">
                {data.result.title}
              </div>
              <a
                target="_blank"
                href={data.url}
                className="underline text-sm my-1 cursor-pointer"
              >
                {data.url}
              </a>
              <div className="flex justify-between w-full">
                <div className="text-sm mt-2 border px-2 py-1 rounded-md">
                  {data.result.date}
                </div>
                <div className="text-sm mt-2 border px-2 py-1 rounded-md font-medium">
                  {data.status}
                </div>
              </div>
            </div>
            {data.result.text.map((elem) => {
              return (
                <div className="p-3 relative bg-white rounded-lg w-full shadow-md mb-6">
                  <div className="flex justify-between mb-2">
                    <div className="text-xl font-medium">{elem.heading[0]}</div>
                    <div className="text-sm  rounded-md text-gray-800 border w-max px-2 py-1 ">
                      Number of words : {elem.num_words}
                    </div>
                  </div>
                  <Accordion collapseAll className="shadow-none border-0">
                    <Accordion.Panel>
                      <Accordion.Title className="p-0">
                        <p className="text-xl font-medium px-4 py-2 text-gray-800 text-left">
                          Headings
                        </p>
                      </Accordion.Title>
                      <Accordion.Content className="px-4 py-2">
                        {elem.heading.map((element) => {
                          return (
                            <li className="mb-2 font-medium text-gray-800 text-left">
                              {element}
                            </li>
                          );
                        })}
                      </Accordion.Content>
                    </Accordion.Panel>
                    <Accordion.Panel>
                      <Accordion.Title className="w-full p-0 ">
                        <p className="text-xl font-medium px-4 py-2 text-gray-800 text-left">
                          Content
                        </p>
                      </Accordion.Title>
                      <Accordion.Content className="px-4 py-2">
                        <p className="tex-sm">
                          {/* {elem.content.split("\n").map((item, idx) => {
                            return (
                              <span className="" key={idx}>
                                {item}
                                <br />
                              </span>
                            );
                          })} */}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: convertToHtmlFormat(
                                elem.content.toString()
                              ),
                            }}
                          />
                          {/* <div dangerouslySetInnerHTML={{ */}
                          {/* __html:elem.content.toString().replace(/\\n\\n|\\n/g, '<br>')}}/>  */}
                          {/* <p>{nl2br(elem.content)}</p> */}
                          {/* <ReactQuill theme="snow" readOnly preserveWhitespace placeholder={elem.content}/> */}
                        </p>
                      </Accordion.Content>
                    </Accordion.Panel>
                  </Accordion>
                </div>
              );
            })}
          </div>
  )
}

export default Results
