import LoginPage from "./pages/loginPage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/dashboard.jsx";
import { useDispatch, useSelector } from "react-redux";
import Layout from "./layout";
import UrlScrapper from "./pages/UrlScrapper.jsx";
import FileUploader from "./pages/FIleUploader/index.jsx";
import FileDetailsPage from "./pages/FIleUploader/fileDetailsPage.jsx";
import ActivationEmailSender from "./pages/ActivationEmailSender/index.jsx";
import TaskRestarter from "./pages/TaskRestarter/index.jsx";
import ActivationEmailSenderPaid from "./pages/ActivationEmailSenderPaid/index.jsx";
import AddCompany from "./pages/AddCompany/index.jsx";
import FileUpload_2 from "./pages/FileUpload_2.jsx";
import DashboardTiles from "./pages/DashboardTiles/index.jsx";
import Subscriptions from "./pages/Subscriptions/index.jsx";
import { login, logout } from "./store/slice/authSlice.js";
import Layouts from "./pages/Layouts/index.jsx";
import useAxiosInstance from "./hooks/useAxios.jsx";
import React from "react";
import FileDetailsPage_v1 from "./pages/FIleUploader/fileDetailesPageV1.jsx";
import CompanyRequests from "./pages/CompanyRequests/index.jsx";
import CompanyMigrations from "./pages/CompanyMigrations/index.jsx";
import GenerateActivationLink from "./pages/ActivationLink/index.jsx";
import ActivationEmailBulkSupport from "./pages/ActivationEmailBulkSupport/index.jsx";
import FileUploads from "./pages/FileUploads/index.jsx";
import AllOrganisation from "./pages/Organisations/allOrganisation.jsx";
import { setOrganisation } from "./store/slice/GlobalAuth.js";
import AddOrganisation from "./pages/Organisations/addOrganisation.jsx";
import EditOrganisation from "./pages/Organisations/editOrganisation.jsx";
import AllUsers from "./pages/Users/allUsers.jsx";
import EditUsers from "./pages/Users/editUsers.jsx";

export const listOfSubscription = ["Pro", "Beta", "default", "Hidden", "Lock"] 


function App() {
  const axios = useAxiosInstance();
  const authData = useSelector((state) => state.auth);
  const localStorageData = JSON.parse(localStorage.getItem('wokelo-sandbox-auth-token'))
  const dispatch = useDispatch();
  if(localStorageData!==null){
    dispatch(login(localStorageData));
  }else{
    dispatch(logout())
  } 
  React.useEffect(() => {
        axios.get(`accounts/organisations/`).then((res) => {  
          dispatch(setOrganisation(res.data.organisations))
        }).catch(err=>{
          console.log('Error detected so logging out')
        })
  }, []);

  return (
    <div>
      <BrowserRouter>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Routes>
        <Route
            path="/"
            element={
              authData.username === "" ? (
                <LoginPage />
              ) : (
                <Layout>
                  <Dashboard />
                </Layout>
              )
            }
          />
          <Route
            path="/companyRequests"
            element={
              authData.username === "" ? (
                <LoginPage />
              ) : (
                <Layout>
                  <CompanyRequests />
                </Layout>
              )
            }
          />
          <Route
            path="/companyMigrations"
            element={
              authData.username === "" ? (
                <LoginPage />
              ) : (
                <Layout>
                  <CompanyMigrations />
                </Layout>
              )
            }
          />
          <Route
            path="/generateActivationLink"
            element={
              authData.username === "" ? (
                <LoginPage />
              ) : (
                <Layout>
                  <GenerateActivationLink />
                </Layout>
              )
            }
          />
          <Route
            path="/urlScrapper"
            element={
              authData.username === "" ? (
                <LoginPage />
              ) : (
                <Layout>
                  <UrlScrapper />
                </Layout>
              )
            }
          />
          <Route
            path="/subscriptions"
            element={
              authData.username === "" ? (
                <LoginPage />
              ) : (
                <Layout>
                  <Subscriptions />
                </Layout>
              )
            }
          />
          <Route
            path="/AllUsers"
            element={
              authData.username === "" ? (
                <LoginPage />
              ) : (
                <Layout>
                  <AllUsers />
                </Layout>
              )
            }
          />
          <Route
            path="/EditUsers"
            element={
              authData.username === "" ? (
                <LoginPage />
              ) : (
                <Layout>
                  <EditUsers />
                </Layout>
              )
            }
          />
          <Route
            path="/AllOrganisation"
            element={
              authData.username === "" ? (
                <LoginPage />
              ) : (
                <Layout>
                  <AllOrganisation />
                </Layout>
              )
            }
          />
          <Route
            path="/addOrganisation"
            element={
              authData.username === "" ? (
                <LoginPage />
              ) : (
                <Layout>
                  <AddOrganisation />
                </Layout>
              )
            }
          />
          <Route
            path="/editOrganisation"
            element={
              authData.username === "" ? (
                <LoginPage />
              ) : (
                <Layout>
                  <EditOrganisation />
                </Layout>
              )
            }
          />
          <Route
            path="/layouts"
            element={
              authData.username === "" ? (
                <LoginPage />
              ) : (
                <Layout>
                  <Layouts />
                </Layout>
              )
            }
          />
          <Route
            path="/fileUploader"
            element={
              authData.username === "" ? (
                <LoginPage />
              ) : (
                <Layout>
                  <FileUploader />
                </Layout>
              )
            }
          />
          <Route
            path="/FileUploads"
            element={
              authData.username === "" ? (
                <LoginPage />
              ) : (
                <Layout>
                  <FileUploads />
                </Layout>
              )
            }
          />
          <Route
            path="/dashboardTiles"
            element={
              authData.username === "" ? (
                <LoginPage />
              ) : (
                <Layout>
                  <DashboardTiles />
                </Layout>
              )
            }
          />
          <Route
            path="/V2fileUploader"
            element={
              authData.username === "" ? (
                <LoginPage />
              ) : (
                <Layout>
                  <FileUpload_2 />
                </Layout>
              )
            }
          />
          <Route
            path="/ActivationEmailTrial"
            element={
              authData.username === "" ? (
                <LoginPage />
              ) : (
                <Layout>
                  <ActivationEmailSender />
                </Layout>
              )
            }
          />
          <Route
            path="/ActivationEmailPaid"
            element={
              authData.username === "" ? (
                <LoginPage />
              ) : (
                <Layout>
                  <ActivationEmailSenderPaid />
                </Layout>
              )
            }
          />
          <Route
            path="/taskRestarter"
            element={
              authData.username === "" ? (
                <LoginPage />
              ) : (
                <Layout>
                  <TaskRestarter />
                </Layout>
              )
            }
          />
          <Route
            path="/addCompany"
            element={
              authData.username === "" ? (
                <LoginPage />
              ) : (
                <Layout>
                  <AddCompany />
                </Layout>
              )
            }
          />
          <Route
            path="/fileUploader/:id"
            element={
              authData.username === "" ? (
                <LoginPage />
              ) : (
                <Layout>
                  <FileDetailsPage />
                </Layout>
              )
            }
          />
          <Route
            path="/fileUploader-V1/:id"
            element={
              authData.username === "" ? (
                <LoginPage />
              ) : (
                <Layout>
                  <FileDetailsPage_v1 />
                </Layout>
              )
            }
          />
          <Route
            path="/ActivationEmailBulkAddition"
            element={
              authData.username === "" ? (
                <LoginPage />
              ) : (
                <Layout>
                  <ActivationEmailBulkSupport />
                </Layout>
              )
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
