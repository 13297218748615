import React, { useEffect, useState } from 'react'
import Input from "../../components/input";
import { MdArrowBack, MdCheckCircle, MdHourglassEmpty, MdHourglassFull } from "react-icons/md";
import { useSearchParams } from "react-router-dom";
import useAxiosInstance from '../../hooks/useAxios';
import Select from "react-select";
import { toast } from 'react-toastify';

const EditUsers = () => {
  const [searchParams,setSearchParams] = useSearchParams()
  const user_id = searchParams.get('user_id')
  const [formData,setFormData] = useState({});
  const [loaderUserDetails,setLoaderUserDetails] = useState(false);
  const [steps, setSteps] = useState(0);
  
  const [isOrgLoading,setOrgLoading] = useState(false);
  const [isLoading,setIsLoading] = useState(false);
  const [organisation,setAllOrganisation] = useState([]);
  const [orgProfileTypeMapping,setOrgProfileTypeMapping] = useState([]);
  const axiosInstance = useAxiosInstance()   
  
  useEffect(()=>{
    if(
        user_id != null
    ){setLoaderUserDetails(true)
    axiosInstance.get(`/accounts/get_user_details/?user_id=${user_id}`).then(res=>{
        setFormData(res.data.data)
        setOrgProfileTypeMapping(res?.data?.data?.org_profile_type_id_to_details || [])
        setLoaderUserDetails(false)
    }).catch(err=>{
        console.log(err)
        setLoaderUserDetails(false)
    })}
  },[user_id])

  useEffect(() => {
    setOrgLoading(true);
    axiosInstance
      .get("admin/organisations/")
      .then((res) => {
        setAllOrganisation(res.data.organisations);
        setOrgLoading(false)
        })
      .catch((err) => {
        console.log(err);
        // toast.error("Something went while getting orgs");
        setOrgLoading(false);
      });
  }, []);

  const handleSubmit = () => {
    setIsLoading(true)
    axiosInstance.post('/accounts/edit_user_details/',{...formData,user_id:user_id}).then(res=>{
        if(res.data.status === 'success'){
            toast.success(`User is successfully created`)
            setIsLoading(false)
        }
    }).catch(err=>{
        console.log(err)
        setIsLoading(false)
        toast.error('something went wrong!')
    })
  }

  return (
    <div>
    <div className="">
      <div className="flex justify-center">
        <div className="w-[676px] flex items-center mt-10">
          <div className="w-16 flex flex-col justify-center items-center">
            {steps === 0 ? <MdHourglassEmpty /> : <MdCheckCircle />}
            <div className="text-xs">Step 1</div>
          </div>
          <div
            className={`w-full h-1 ${
              steps === 0 ? "bg-slate-300" : "bg-black"
            }`}
          ></div>
          <div className="w-16 flex flex-col justify-center items-center">
            {steps === 0 ? <MdHourglassEmpty /> : <MdHourglassFull />}
            <div className="text-xs">Preview</div>
          </div>
        </div>
      </div>
      <div
        className={` w-full justify-center p-5 ${"items-start "} flex`}
      >
        {steps === 0 ? (
          <>
          {loaderUserDetails ? <p className="mt-5 p-3">
            Loading...
          </p> :
          <div className="w-[676px] p-3 mt-5 border rounded-lg bg-white">
                <p className='text-base font-semibold mb-2'>User Table Information</p>
                <div className='px-2'>
                <div className="text-sm mt-4 font-semibold mb-2">Username</div>
                <Input
                  value={formData.user_data?.username}
                  onChange={(e) => {
                    let user_formData = JSON.parse(JSON.stringify(formData.user_data))
                    setFormData({ ...formData, user_data: {...user_formData,username:e.target.value} });
                  }}
                  placeholder="Enter the username"
                />

                <div className="text-sm font-semibold mt-4 mb-2">First name</div>
                <Input
                  value={formData.user_data?.first_name}
                  onChange={(e) => {
                    let user_formData = JSON.parse(JSON.stringify(formData.user_data))
                    setFormData({ ...formData, user_data: {...user_formData,first_name:e.target.value} });
                  }}
                  placeholder="Enter the fist name"
                />

                <div className="text-sm mt-4 font-semibold mb-2">Last name</div>
                <Input
                  value={formData.user_data?.last_name}
                  onChange={(e) => {
                    let user_formData = JSON.parse(JSON.stringify(formData.user_data))
                    setFormData({ ...formData, user_data: {...user_formData,last_name:e.target.value} });
                  }}
                  placeholder="Enter the last name"
                />
                <div className="text-sm mt-4 font-semibold mb-2">Email</div>
                <Input
                  value={formData.user_data?.email}
                  onChange={(e) => {
                    let user_formData = JSON.parse(JSON.stringify(formData.user_data))
                    setFormData({ ...formData, user_data: {...user_formData,email:e.target.value} });
                  }}
                  placeholder="Enter the Email"
                />

                <div className='grid grid-cols-3'>
                {/* <div className='flex mt-4 mb-2 items-center gap-x-2'>
                <div className="text-sm font-semibold">Is Staff</div>
                <input
                  type='checkbox'  
                  checked={formData.user_data?.is_staff}
                  onClick={(e) => {
                    let user_formData = JSON.parse(JSON.stringify(formData.user_data))
                    setFormData({ ...formData, user_data: {...user_formData, is_staff: !user_formData.is_staff} });
                  }}
                />
                </div> */}

                <div className='flex mt-4 mb-2 items-center gap-x-2'>
                <div className="text-sm font-semibold">Is Superuser</div>
                <input
                  type='checkbox'  
                  checked={formData.user_data?.is_superuser}
                  onClick={(e) => {
                    let user_formData = JSON.parse(JSON.stringify(formData.user_data))
                    setFormData({ ...formData, user_data: {...user_formData, is_superuser: !user_formData.is_superuser} });
                  }}
                />
                </div>

                <div className='flex mt-4 mb-2 items-center gap-x-2'>
                <div className="text-sm font-semibold">Is Active</div>
                <input
                  type='checkbox'  
                  checked={formData.user_data?.is_active}
                  onClick={(e) => {
                    let user_formData = JSON.parse(JSON.stringify(formData.user_data))
                    setFormData({ ...formData, user_data: {...user_formData, is_active: !user_formData.is_active} });
                  }}
                />
                </div>
                </div>
                </div>    
                {/* profile_details_started */}

                <p className='text-base font-semibold mb-2'>Profile Table Information</p>
                <div className='px-2'>
                <div className="text-sm font-semibold mb-2">Org name : {organisation?.find(item=>item?.org_id == formData?.user_profile_data?.org_name)?.name}</div>
                <Select
                  isLoading={isOrgLoading}
                //   placeholder={`${formData.user_profile_data?.org_name}`}
                  className="w-full"
                  options={organisation.map(elem=>({label:elem.name,value:elem.org_id}))}
                  onChange={(e) => {
                    let user_formData = JSON.parse(JSON.stringify(formData.user_profile_data))
                    setFormData({ ...formData, user_profile_data: {...user_formData,org_name:e.value} });
                  }}
                />

                <div className="text-sm font-semibold mt-4 mb-2">Org designation :</div>
                <Input
                  value={formData.user_profile_data?.org_designation}
                  onChange={(e) => {
                    let user_formData = JSON.parse(JSON.stringify(formData.user_profile_data))
                    setFormData({ ...formData, user_profile_data: {...user_formData, org_designation:e.target.value} });
                  }}
                  placeholder="Enter the org_designation"
                />

                <div className="text-sm font-semibold mt-4 mb-2">Org profile type :</div>
                <select
                  value={formData.user_profile_data?.org_profile_type}
                  onChange={(e) => {
                    let user_formData = JSON.parse(JSON.stringify(formData.user_profile_data))
                    setFormData({ ...formData, user_profile_data: {...user_formData, org_profile_type:e.target.value} });
                  }}
                  className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 disabled:bg-gray-300"  
                  placeholder="Enter the org_profile_type"
                >
                   {[{id:"",name:"choose"},...orgProfileTypeMapping].map(item=>{return <option 
                        value={item.id}
                    >
                        {item.name}
                   </option>})}
                </select>

                <div className="text-sm font-semibold mt-4 mb-2">Org timezone :</div>
                <Input
                  value={formData.user_profile_data?.timezone}
                  onChange={(e) => {
                    let user_formData = JSON.parse(JSON.stringify(formData.user_profile_data))
                    setFormData({ ...formData, user_profile_data: {...user_formData, timezone:e.target.value} });
                  }}
                  placeholder="Enter the timezone"
                />        

                <div className='flex mt-4 mb-2 items-center gap-x-2'>
                <div className="text-sm font-semibold">active</div>
                <input
                  type='checkbox'  
                  checked={formData.user_profile_data?.active}
                  onClick={(e) => {
                    let user_formData = JSON.parse(JSON.stringify(formData.user_profile_data))
                    setFormData({ ...formData, user_profile_data: {...user_formData, active: !user_formData.active} });
                  }}
                />
                </div>

                {Object.keys(formData.user_profile_data?.meta||{}).includes('guide_screens') &&   
                <>
                <div className="text-sm font-semibold">Meta</div>
                <div className="text-sm pl-2 mt-2 font-semibold">guide_screens</div>
                <div className="text-sm pl-4 mt-2 font-semibold">lexical</div>
                <div className='ml-4  mt-2 mr-4'>
                <Input
                  type='number'   
                  value={formData.user_profile_data?.meta?.guide_screens?.lexical}
                  onChange={(e) => {
                    let user_formData = JSON.parse(JSON.stringify(formData.user_profile_data))
                    setFormData({ ...formData, user_profile_data: {...user_formData, meta: {...user_formData.meta, guide_screens : {...user_formData.meta.guide_screens,lexical:Number(e.target.value)}}} });
                  }}
                />
                </div>
                </>
                }

                <button
              className="bg-black disabled:bg-[#B6b6b6] disabled:cursor-not-allowed disabled:text-white text-white py-3 hover:shadow-lg  text-center w-full rounded-lg mt-4"
              type="button"
              disabled = {formData?.user_data?.username == ""}
              onClick={() => {
                let newformData = JSON.parse(JSON.stringify(formData))
                delete newformData['org_profile_type_id_to_details']
                setFormData(newformData)
                setSteps(1)
              }}
            >
              Next
            </button>
                </div>  

          </div>}
          </>
        ) : (
            // <></>
          <Preview isLoading={isLoading} setSteps={setSteps} formData={formData} handleSubmit={handleSubmit}/>
        )}
      </div>
    </div>    
    </div>
  )
}

export default EditUsers

const Preview =({formData,setSteps,isLoading,handleSubmit})=>{
    return <div>
    <div className="w-[676px] p-3 mt-5 border rounded-lg bg-white">
      <p className="text-2xl font-semibold mb-4">Meta Data Preview</p>
      <div className="border rounded-xl bg-gray-50 px-3 py-2">
      {/* "overflow-y:scroll; max-width:400px; height:80px; border:0.5px solid lightgray; padding:5px; white-space:pre-wrap;" */}
        <pre style={{overflowY:'scroll',maxWidth:'100%',height:'80vh',whiteSpace:'pre-wrap'}}>
            {JSON.stringify(formData,{},4)}
        </pre>
      </div>
      <div className="flex justify-between">
        <button
          title="Go Back and Edit"
          className="flex gap-1 items-center px-2 py-1 mt-2 border rounded-lg"
          onClick={() => {
            setSteps(0);
          }}
        >
          <MdArrowBack />
        </button>

        <button
          disabled={isLoading}
          title="Go Back and Edit"
          className="flex gap-1 items-center px-2 py-1 mt-2 border border-black bg-black disabled:bg-[#b6b6b6] disabled:text-white text-white rounded-lg"
          onClick={() => {
            handleSubmit();
          }}
        >
          {!isLoading ? "Edit this User" : "Loading...."}
        </button>
      </div>
    </div>
  </div>
}