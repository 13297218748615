import React, { useEffect, useState } from 'react'
import useAxiosInstance from "../../hooks/useAxios";
import { ModalComp } from "../../components/Modal";
import { toast } from "react-toastify";
import Input from '../../components/input';
import { useDebounce } from 'use-debounce';
import { FaChartArea, FaFile, FaGlobe, FaInfoCircle, FaUserCircle } from 'react-icons/fa';
import { GoInfo, GoOrganization } from 'react-icons/go';
import { MdArrowBack, MdBackHand, MdCopyAll, MdEmail } from 'react-icons/md';
import { DateRange, Download, DownloadDoneOutlined } from '@mui/icons-material';
import { Button } from 'flowbite-react';
import axios from 'axios';
import JSZip from "jszip";

const FileUploads = () => {
  const axiosInstance = useAxiosInstance();  
  const [step,setSteps] = useState(0)
  const [orgs,setOrgs] = useState()  
  const [orgLoadings,setOrgLoading] = useState(false);
  const [org_id_to_details_mapping,setOrg_id_to_details_mapping] = useState({});
  

  const [selectedOrg,setSelectedOrg] = useState([]);
  
  const [searchOrg,setSearchOrg] = useState('');
  const [UserListIsLoading,setUserListIsLoading] = useState(false);
  const [selectedUserList, setSelectedUserList] = useState([]);
  const [userList, setUserList] = useState({})
  const [user_id_to_details_mapping,setuser_id_to_details_mapping] = useState(()=>{});
  const [
    userPaginatedIntermidatePageNumber,
    setUserPaginatedIntermidatePageNumber,
  ] = useState(1);
  const [userPaginatedPageNumber, setUserPaginatedPageNumber] = useState(1);
  const [userSearch, setUserSearch] = useState("");
  const [userQuery] = useDebounce(userSearch, 1000);

  useEffect(()=>{
    setFileUploadsPageNumber(1)
  },[userQuery])

  const [fileUploadsPageNumber,setFileUploadsPageNumber] = useState(1);
  const [fileUploadsIntermidiatePageNumber,setFileUploadsIntermidiatePageNumber] = useState(1);
  const [fileUploadList,setFileUploadList] = useState({})
  const [fileUploadLoading,setFileUploadLoading] = useState(false);
  const [fileUploadSearch, setfileUploadSearch] = useState("");
  const [fileUploadQuery] = useDebounce(fileUploadSearch, 1000);
  const [apiFileUploadRetrigger,setApiFileUploadRetrigger] = useState(1)
  const [downloadButtonLoading,setDownloadButtonLoading] = useState([]);
  const [selectedFileUploads,setSelectedFileUploads] = useState({});
  const [downloadFileBulkLoading,setDownloadFileBulkLoading] = useState(false);
  const [downloadCounter,setDownloadCounter] = useState(0);
  const [pageSize,setPageSize] = useState(50);

  const [modal,setModal] = useState(false);
  const [currentMetaData,setCurrentMetaData] = useState({})

  useEffect(() => {
    if(step == 2){
        setFileUploadLoading(true);
        const controller = new AbortController();
        axiosInstance
            .get(
        `/assets/get_file_uploads/?&pgsz=${pageSize}&page=${fileUploadsPageNumber}&users_ids=${selectedUserList}&org_ids=${selectedOrg}&query=${fileUploadQuery}&start_date=${filterTimeRange.startDate}&end_date=${filterTimeRange.endDate}`
      ,{
        signal: controller.signal
      }
            )
        .then((res) => {
            setFileUploadList(res.data);
            setFileUploadLoading(false);
            setFileUploadsIntermidiatePageNumber(res.data.current_page)
        })
        .catch((err) => {
            console.log(err);
        });
    return () => {
      controller.abort();
    };
    }  
  }, [fileUploadsPageNumber,fileUploadQuery,apiFileUploadRetrigger,step,pageSize]);    

  useEffect(() => {
    setOrgLoading(true);
    axiosInstance
      .get("admin/organisations/")
      .then((res) => {
        setOrgs(res.data);
        let new_mapping = {};
        let all_orgs = res?.data?.organisations;
        all_orgs.forEach((org) => {
          new_mapping[org.org_id] = org;
        });
        setOrgLoading(false)
        // setcompanyRequestPaginatedPageNumber(1)
        setOrg_id_to_details_mapping(new_mapping);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went while getting users");
        setOrgLoading(false);
      });
  }, []);

  
  useEffect(() => {
    setUserListIsLoading(true);
    const controller = new AbortController();
    axiosInstance
      .get(
        `/admin/get-org-users/?&pgsz=20&page=${userPaginatedPageNumber}&org_ids=${selectedOrg}&query=${userQuery}`
      ,{
        signal: controller.signal
      }
      )
      .then((res) => {
        setUserList(res.data);
        setUserListIsLoading(false);
        setUserPaginatedIntermidatePageNumber(res.data.current_page)
        let new_mapping = {};
        let all_users = res.data.users;
        all_users.forEach((user) => {
            new_mapping[user.user_id] = user;
        });
        setuser_id_to_details_mapping(new_mapping)
      })
      .catch((err) => {
        console.log(err);
      });
    
    return () => {
      controller.abort();
    };  
  }, [userPaginatedPageNumber,userQuery, selectedOrg]);

    useEffect(()=>{
      setUserPaginatedPageNumber(1)
    },[userQuery])

    
    const todays = (isStartDate) => {
    if(isStartDate){
        let startDate = new Date();
        startDate.setDate(startDate.getDate() - 5)
        return `${startDate.getFullYear()}-${("0"+(startDate.getMonth()+1)).slice(-2)}-${("0" + startDate.getDate()).slice(-2)}`
    }
    const date = new Date();
    const dateFormattedDate = `${date.getFullYear()}-${("0"+(date.getMonth()+1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}`;
    return dateFormattedDate;
    };
    const [filterTimeRange,setFilterTimeRange] = useState({
    startDate:todays(true),
    endDate:todays()
    })   

    useEffect(()=>{
      setFileUploadsPageNumber(1)
    },[filterTimeRange,fileUploadQuery])
  
    const getOrgImage = (img,name) =>{
        if(img === null || img == ''){
            return `https://wklogo.blob.core.windows.net/logos-small/${name[0]?.toUpperCase()}.png`
        }else{
            return img
        }
    }
    const getDirectory=()=>{
        // console.log(selectedUserList)
        if(selectedOrg.length!=0 && step == 1){
            return '/'+org_id_to_details_mapping[selectedOrg]?.name
        }
        else if(selectedUserList.length!=0 && selectedOrg.length!=0){
            return '/'+org_id_to_details_mapping[selectedOrg]?.name +'/' + user_id_to_details_mapping[selectedUserList]?.username
        }
        return '/'
    }
    const getFIltered_orgs_data = (searchOrg,orgs)=>{
        if(searchOrg){
            return orgs.filter(org=>org?.name?.toLowerCase().includes(searchOrg?.toLowerCase()))
        }
        return orgs
    }

    const handleDownload = async (url,name,setIsXLSXDownloading,alias,fileType) => {
        setIsXLSXDownloading(prev=>[...prev,alias]);
    
        await axiosInstance.post('/assets/download_renamed_file/', {
            "filename":name,
            "filetype":fileType,
            "url":url
        },
        {
          responseType: "blob", 
        })
        .then((response) => {
            setIsXLSXDownloading(prev=>prev.filter(item=>item!=alias));
            const url = window.URL.createObjectURL(
                new Blob([response.data],{ type: "application/"+fileType })
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                name
            );
            document.body.appendChild(link);
            link.click();
        })
        .catch((err) => {
            setIsXLSXDownloading(prev=>prev.filter(item=>item!=alias));
            // NotificationService.success('Downloading Failed');
        });
    };
    const formateDate = (dateGiven) => {
        if (dateGiven !== "") {
        const date = new Date(dateGiven);
        const dateFormattedDate = `${date.getFullYear()}/${date.getMonth()}/${date.getDate()} , ${date.getHours()}:${date.getMinutes()}`;
        return dateFormattedDate;
        } else {
         return "";
        }
    };

    const handleMultipleDownload = async(files)=>{
      setDownloadCounter(0)
      setDownloadFileBulkLoading(true)
      const zip = new JSZip();
      let newFiles = {}
      // console.log(files)
      files.forEach(file=>{
        let newfileName = file.name
        if(Object.keys(newFiles).includes(newfileName)){
          newfileName = file.name.split(`.${file.fileType}`)[0] +" " +file.id+"."+file.fileType
        }
        newFiles[newfileName] = {
          "name":newfileName,
          "filetype":file.fileType,
          "url":file.url
        }
      })
      // console.log(newFiles)
      files = JSON.parse(JSON.stringify(Object.values(newFiles)))
      // console.log(files)
      
      for (let i = 0; i < files.length; i++) {
          await axiosInstance.post('/assets/download_renamed_file/', {
              "filename":files[i].name,
              "filetype":files[i].fileType,
              "url":files[i].url
          },
          {
            responseType: "blob", 
          })
          .then((response) => {
                  let blob = new Blob([response.data],{ type: "application/"+files[i].fileType })
                  zip.file(files[i].name, blob);
                  setDownloadCounter(prev=>prev+1)
          })
          .catch((err) => {
              console.log(err)
              // setIsXLSXDownloading(prev=>prev.filter(item=>item!=alias));
              // NotificationService.success('Downloading Failed');
          });
      };
      
      const zipData = await zip.generateAsync({
        type: "blob",
        streamFiles: true,
      });
      setDownloadFileBulkLoading(false)

      // Create a download link for the zip file
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(zipData);
      link.download = `${userList?.users?.find((item)=>item.user_id==selectedUserList[0])?.username}_data.zip`;
      link.click();
    }
  const get_select_all_checked_status = (files, selectedFileUploads)=>{
    if(Object.keys(selectedFileUploads).length == 0){
      return false
    }
    let result = true
    // console.log(files)
    files?.forEach(file=>{
      if(!Object.keys(selectedFileUploads).includes(String(file.id))){
        result = false
      }
    })
    return result
  }

  return (
    <div className="min-h-screen w-full">
        <ModalComp
        width={800}
        open={modal}
        onClose={() => {
            setModal(false);
        }}
      >
        <div className="p-4 rounded-xl bg-white max-h-[800px] overflow-y-auto">
        <pre>
            {JSON.stringify(currentMetaData,{},4)}
        </pre>
        </div>
      </ModalComp>
      <div className="p-6 w-full">
        <div className="mb-4">
          <div className="text-2xl font-semibold">File Uploads</div>
            <div className='mt-1 flex items-center text-xl'>
                <div
                onClick={()=>{
                    if(step == 1){
                        setSteps(0)
                        setSelectedOrg([])
                    }
                    if(step == 2){
                        setSteps(1)
                        setSelectedUserList([])
                    }
                }}
                className='bg-black rounded cursor-pointer text-white mr-3'>
                    <MdArrowBack /></div> {getDirectory()}</div>
          {
            step == 0&&<div className='mt-4'>
                <div className='text-lg font-semibold mb-2'>All Organisations</div>
                <Input
                    placeholder={'Search org'}
                    value={searchOrg}
                    className='mt-2'
                    onChange = {e=>{setSearchOrg(e.target.value)}}
                />
                {orgLoadings ? <>
                    <p className='mt-4'>
                        Loading...
                    </p>
                    </>:<div className='grid gap-4 mt-4 grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
                        {getFIltered_orgs_data(searchOrg,orgs?.organisations || [])?.map(org=>{
                            return <div
                            onClick={()=>{
                                setSteps(1)
                                let new_org_array = []
                                new_org_array.push(org.org_id)
                                setSelectedOrg(new_org_array)
                            }}
                            className='border cursor-pointer rounded-lg bg-slate-200 p-2'>
                                <div 
                                style={{backgroundImage:`url('${getOrgImage(org?.org_img,org?.name)}')`}} 
                                className="h-[150px] w-full bg-center bg-no-repeat bg-contain"/>
                                <p className='font-semibold text-base text-center mt-2'>{org?.name}</p>
                            </div>
                        })}
                    </div>}
            </div>
            }
            {step==1&&<div className='mt-4'>
                <div className='text-lg font-semibold mb-2'>All Users</div>
                <Input
                    placeholder={'Search user'}
                    value={userSearch}
                    className='mt-2'
                    onChange = {e=>{setUserSearch(e.target.value)}}
                />
                <div className='mt-4'>
                    {UserListIsLoading?<div>
                        Loading...
                    </div>:<div className='grid gap-4 grid-cols-3'>
                        {userList.users.map(user=>{
                            return <div 
                                onClick={()=>{
                                    setSteps(2)
                                    let new_user_list = []
                                    new_user_list.push(user?.user_id)
                                    setSelectedUserList(new_user_list)
                                }}
                                className='p-2 cursor-pointer bg-slate-200 rounded-lg'>
                                <div className='flex'>    
                                <div 
                                    style={{backgroundImage:`url('${getOrgImage(user?.profile_image,user?.first_name)}')`}} 
                                    className="h-[100px] w-[100px] rounded-lg mr-3 bg-center bg-no-repeat bg-contain"/>
                                <div>
                                    <p className='font-semibold text-xl'>{user?.username}</p>
                                    <p className='font-semibold text-base flex gap-2 items-center'><MdEmail /><span>{user?.email}</span></p>
                                    <div className='flex justify-between'>
                                    <p className='font-semibold text-base flex gap-2 items-center'><FaUserCircle />{user?.first_name} {user?.last_name}</p>
                                    </div>
                                    <p className='font-semibold text-base  flex gap-2 items-center'><span><GoOrganization /></span>{org_id_to_details_mapping[user?.org_id]?.name}</p>
                                </div>
                                </div>
                            </div>
                        })}    
                    </div>}
                    <div className="mt-2 flex justify-between items-center">
                      <div className="flex space-x-1 items-center">
                        {userList.current_page > 1 && (
                          <button
                            disabled={UserListIsLoading}
                            onClick={() => {
                              setUserPaginatedPageNumber((prev) => prev - 1);
                            }}
                            className="disabled:cursor-not-allowed text-black underline px-0 py-0 rounded-lg"
                          >
                            Back
                          </button>
                        )}

                        <div className="p-1 rounded border-black border-2 flex space-x-1 items-center">
                          <input
                            value={
                              UserListIsLoading
                                ? "loading..."
                                : userPaginatedIntermidatePageNumber
                            }
                            type="number"
                            className="disabled:cursor-not-allowed mx-2 w-10"
                            disabled={UserListIsLoading}
                            onChange={(e) => {
                              setUserPaginatedIntermidatePageNumber(
                                Number(e.target.value)
                              );
                            }}
                          />
                          <button
                            onClick={() => {
                              if(userPaginatedIntermidatePageNumber>0 && userPaginatedIntermidatePageNumber<=userList.num_pages)
                              {setUserPaginatedPageNumber(
                                userPaginatedIntermidatePageNumber
                              );}else{
                                alert('Please enter correct number range')
                              }
                            }}
                            className="bg-black px-1 py-1 text-xs rounded text-white"
                          >
                            Apply
                          </button>
                        </div>
                        {userList.current_page < userList.num_pages && (
                          <button
                            disabled={UserListIsLoading}
                            onClick={() => {
                              setUserPaginatedPageNumber((prev) => prev + 1);
                            }}
                            className="ml-3 disabled:cursor-not-allowed text-black underline px-0 py-0 rounded-lg"
                          >
                            Next
                          </button>
                        )}
                      </div>
                      {!UserListIsLoading&&<div className="flex space-x-1">
                        <div>Total Pages: {userList.num_pages}</div>

                        <div>Total Users: {userList.total_users}</div>
                      </div>}
                    </div>
                </div>
            </div>}

            {step==2 && <div className='mt-4'>
                <div className='text-lg font-semibold mb-2'>All files</div>
                <Input
                    placeholder={'Search file name or alias'}
                    value={fileUploadSearch}
                    className='mt-2'
                    onChange = {e=>{setfileUploadSearch(e.target.value)}}
                />
                <p className="font-medium text-sm mb-2 mt-4">Select from Date : </p>
                  <Input
                    className='w-full'
                    value={filterTimeRange.startDate}
                    type={'date'}
                    onChange={(e) => {
                      setFilterTimeRange(prev=>({...prev,startDate:e.target.value}))
                    }}
                    placeholder="Enter the email"
                  />
                  <p className="font-medium text-sm mb-2 mt-4">Select to Date : </p>
                  <Input
                    className='w-full'
                    value={filterTimeRange.endDate}
                    type={'date'}
                    onChange={(e) => {
                      setFilterTimeRange(prev=>({...prev,endDate:e.target.value}))
                    }}
                    placeholder="Enter the email"
                  />
                  <div className='flex mt-4 mb-2 justify-end w-full'>
                    <button
                    onClick={()=>{
                        setFileUploadsPageNumber(1)
                        setApiFileUploadRetrigger(prev=>prev+1)
                      }}
                      disabled={fileUploadLoading}
                      className="disabled:cursor-not-allowed text-white bg-black px-2 py-1 rounded-lg">Apply filter</button>

                  </div>
                  {/* <button></button> */}
                  {fileUploadLoading? <p className='mt-4'>Loading...</p> :
                  
                  <div className='p-2 overflow-x-auto bg-slate-100 mt-4 rounded-xl'>
                  <table className='w-full bg-slate-50 '>
                   <thead>
                   <th className='px-2 py-1 text-center'>
                        <p className='flex items-center justify-left  text-xm font-medium mx-2 my-1 gap-x-1 text-center'><input type='checkbox' 
                        checked={get_select_all_checked_status(fileUploadList?.file_uploads,selectedFileUploads)}
                        onClick={()=>{
                          let old_obj = {}
                          if ( !get_select_all_checked_status(fileUploadList?.file_uploads,selectedFileUploads) ){
                            fileUploadList?.file_uploads.forEach(file__obj=>{
                              let mimeType = ''
                                    if(file__obj?.meta?.mimeType == undefined){
                                      mimeType = file__obj?.file_name?.split('.').pop() 
                                    }else{
                                      mimeType = file__obj?.meta?.mimeType
                              }
                              old_obj[file__obj.id]= {
                                'name':file__obj?.file_name,
                                'id':file__obj?.id,
                                'fileType':mimeType,
                                'url':file__obj?.original_file
                              } 
                            })
                          }
                          setSelectedFileUploads(old_obj)
                        }}
                        /> Select</p>
                    </th>
                    
                   <th className='px-2 py-1 text-center'>
                        <p className='flex items-center justify-left  text-xm font-medium mx-2 my-1 gap-x-1 text-center'><Download/>Scarped</p>
                    </th>
                    <th className='px-2 py-1 text-center'>
                        <p className='flex items-center justify-left  text-xm font-medium mx-2 my-1 gap-x-1 text-center'><Download/>Orginal</p>
                    </th>
                    <th className='px-2 py-1'>
                        <p className='flex items-center justify-left  text-xm font-medium mx-2 my-1 gap-x-1 text-center'><FaFile/>Name</p>
                    </th>
                    <th className='px-2 py-1 text-center'>
                        <p className='flex items-center justify-left  text-xm font-medium mx-2 my-1 gap-x-1 text-center'> <FaInfoCircle/> Alias</p>
                    </th>
                    <th className='px-2 py-1 text-center'>
                        <p className='flex items-center justify-left  text-xm font-medium mx-2 my-1 gap-x-1 text-center'><FaChartArea/>Status</p>
                    </th>
                    <th className='px-2 py-1 text-center'>
                        <p className='flex items-center justify-left  text-xm font-medium mx-2 my-1 gap-x-1 text-center'><DateRange/>Date</p>
                    </th>
                    {/* <th className='px-2 py-1 text-center'>
                        <p className='flex items-center justify-left  text-xm font-medium mx-2 my-1 gap-x-1 text-center'><GoInfo/>Mine Type</p>
                    </th>
                    <th className='px-2 py-1 text-center'>
                        <p className='flex items-center justify-left  text-xm font-medium mx-2 my-1 gap-x-1 text-center'><GoInfo/>File Quality</p>
                    </th>
                    <th className='px-2 py-1 text-center'>
                        <p className='flex items-center justify-left  text-xm font-medium mx-2 my-1 gap-x-1 text-center'><GoInfo/>Info Message</p>
                    </th>
                    <th className='px-2 py-1 text-center'>
                        <p className='flex items-center justify-left  text-xm font-medium mx-2 my-1 gap-x-1 text-center'><GoInfo/>File Relevance</p>
                    </th> */}
                    <th className='px-2 py-1 text-center'>
                        <p className='flex items-center justify-left  text-xm font-medium mx-2 my-1 gap-x-1 text-center'><GoInfo/>Meta</p>
                    </th>
                   
                    
                   </thead>  
                  <tbody className='mt-4 divide-y'>
                        {fileUploadList?.file_uploads?.map(file=>{
                            return <tr key={file.alias} className='p-2 rounded-xl border-b last:border-b-0 odd:bg-slate-100 even:bg-slate-50'>
                                <td className='px-2 py-1'><input type='checkbox' checked={
                                    Object.keys(selectedFileUploads).includes(String(file.id))
                                  } 
                                  onClick={()=>{
                                  // console.log('click',selectedFileUploads)  
                                  let oldSelectedData = JSON.parse(JSON.stringify(selectedFileUploads))
                                  // console.log(oldSelectedData)
                                  // console.log(Object.keys(selectedFileUploads))
                                  // console.log(String(file.id))
                                  
                                  if(Object.keys(selectedFileUploads).includes(String(file.id))){
                                    delete oldSelectedData[file.id]
                                  }else{
                                    let mimeType = ''
                                    if(file?.meta?.mimeType == undefined){
                                      mimeType = file?.file_name?.split('.').pop() 
                                    }else{
                                      mimeType = file?.meta?.mimeType
                                    }
                                    oldSelectedData[file.id] = {
                                      'name':file?.file_name,
                                      'id':file?.id,
                                      'fileType':mimeType,
                                      'url':file?.original_file
                                    }
                                  }
                                  // console.log(oldSelectedData)
                                  setSelectedFileUploads(oldSelectedData)
                                  }}/></td>
                                <td className='px-2 py-1'><p className='flex justify-start  w-full'><span className='font-medium text-base'></span><a href={file?.scraped_data} target='_blank' download={file.file_name}><button className='bg-black text-white disabled:bg-slate-50 px-2 py-1 rounded-lg'>Download</button></a> </p> </td>
                                <td className='px-2 py-1'><p className='flex justify-start  w-full'><span className='font-medium text-base'></span><button 
                                disabled={downloadButtonLoading.includes(file?.alias)}
                                onClick={()=>{handleDownload(file.original_file,file.file_name,setDownloadButtonLoading,file.alias,file?.meta?.mimeType)}} className='bg-black text-white disabled:bg-slate-50 disabled:text-black disabled:cursor-not-allowed px-2 py-1 rounded-lg'>{downloadButtonLoading.includes(file?.alias) ? "Loading":"Download"}</button></p> </td>
                                <td className='px-2 py-1'><p className='flex justify-start  w-full'><span className='font-medium text-base'></span> {file?.file_name}</p></td>
                                <td className='px-2 py-1'><p className='flex justify-start  w-full'><span className='font-medium text-base'></span> {file?.alias?.slice(0,10)+'...'}<MdCopyAll 
                                    className='cursor-pointer'
                                    onClick={()=>{
                                    toast.info(`${file.alias} copied to clickboard`)
                                    navigator.clipboard.writeText(file.alias)
                                }}/></p></td>
                                <td className='px-2 py-1'><p className='flex justify-start  w-full'><span className='font-medium text-base'></span>{file?.status} </p></td>
                                <td className='px-2 py-1'><p className='flex justify-start  w-full'><span className='font-medium text-base'></span>{formateDate(file?.dt_createdOn)} </p> </td>
                                {/* <td className='px-2 py-1'><p className='flex justify-start  w-full'><span className='font-medium text-base'></span> {file?.meta?.mimeType}</p></td>
                                <td className='px-2 py-1'><p className='flex justify-start  w-full'><span className='font-medium text-base'></span> {file?.meta?.fileQuality}</p></td>
                                <td className='px-2 py-1'><p className='flex justify-start  w-full'><span className='font-medium text-base'></span> {file?.meta?.infoMessage}</p></td>
                                <td className='px-2 py-1'><p className='flex justify-start  w-full'><span className='font-medium text-base'></span> {file?.meta?.fileRelevance}</p></td> */}
                                <td className='px-2 py-1'><p className='flex justify-start  w-full'><span className='font-medium text-base'></span> <button 
                                className='underline'
                                onClick={()=>{
                                    setCurrentMetaData(file.meta)
                                    setModal(true)
                                }}>View Meta</button></p></td>
                                
                            </tr>
                        })}
                  </tbody>
                  </table>
                  
                  <div className="mt-2 flex justify-between items-center">
                      <div className="flex space-x-1 items-center">
                        {fileUploadList.current_page > 1 && (
                          <button
                            disabled={fileUploadLoading}
                            onClick={() => {
                              setFileUploadsPageNumber((prev) => prev - 1);
                            }}
                            className="disabled:cursor-not-allowed text-black underline px-0 py-0 rounded-lg"
                          >
                            Back
                          </button>
                        )}

                        <div className="p-1 rounded border-black border-2 flex space-x-1 items-center">
                          <input
                            value={
                                fileUploadLoading
                                ? "loading..."
                                : fileUploadsIntermidiatePageNumber
                            }
                            type="number"
                            className="disabled:cursor-not-allowed mx-2 w-10"
                            disabled={fileUploadLoading}
                            onChange={(e) => {
                              setFileUploadsIntermidiatePageNumber(
                                Number(e.target.value)
                              );
                            }}
                          />
                          <button
                            onClick={() => {
                              if(fileUploadsIntermidiatePageNumber>0 && fileUploadsIntermidiatePageNumber<=fileUploadList.num_pages)
                              {setFileUploadsIntermidiatePageNumber(
                                fileUploadsIntermidiatePageNumber
                              );}else{
                                alert('Please enter correct number range')
                              }
                            }}
                            className="bg-black px-1 py-1 text-xs rounded text-white"
                          >
                            Apply
                          </button>
                        </div>
                        {fileUploadList.current_page < fileUploadList.num_pages && (
                          <button
                            disabled={fileUploadLoading}
                            onClick={() => {
                              setFileUploadsPageNumber((prev) => prev + 1);
                            }}
                            className="ml-3 disabled:cursor-not-allowed text-black underline px-0 py-0 rounded-lg"
                          >
                            Next
                          </button>
                        )}

                        <select value={pageSize} onChange={(e)=>{
                          setPageSize(e.target.value)
                        }}>
                          <option value={''}>choose</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                          <option value={100}>150</option>
                          <option value={fileUploadList.total_file_uploads}>All</option>
                        </select>
                      </div>
                      <div className="flex space-x-1">
                        <button
                         className='bg-black text-white disabled:bg-slate-50 disabled:text-black disabled:cursor-not-allowed px-2 py-1 rounded-lg' 
                         disabled={downloadFileBulkLoading} 
                         
                         onClick={()=>{handleMultipleDownload(Object.values(selectedFileUploads))}}>{!downloadFileBulkLoading? "Download all":`downloading...(${downloadCounter}/${Object.keys(selectedFileUploads).length})`}
                         </button>
                        <div>Total Pages: {fileUploadList.num_pages}</div>

                        <div>Total file uploads: {fileUploadList.total_file_uploads}</div>
                      </div>
                    </div>
                  </div>
                  }
                </div>}            
        </div>
       </div>
    </div>
  )
}

export default FileUploads