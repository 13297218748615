import { Spinner } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FaUpload } from 'react-icons/fa';

const DragDrop = (props) => {
    const {
        onChange,
        className,
        disabled = false,
        loading,
        onError,
        setErrorFiles,
    } = props
	const [key, setKey] = useState(1);
	const [dragging, setDragging] = useState(false);
	const acceptableFileTypesObj = {
		'application/pdf': [],
		'application/vnd.ms-powerpoint': [],
		'application/vnd.openxmlformats-officedocument.presentationml.presentation':
			[],
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
			[],
		'application/msword': [],
	};

	const handleOnChange = (file) => {
		setKey(key + 1);
		onChange && onChange(file);
	};

	const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
		useDropzone({
			accept: acceptableFileTypesObj,
			onDrop: (acceptedFiles) => handleOnChange(acceptedFiles),
		});

	// const acceptedFileItems = acceptedFiles.map(file => (
	//     <li key={file.path}>
	//       {file.path} - {file.size} bytes
	//     </li>
	//   ));

	const fileRejectionItems = fileRejections.map(
		({ file, errors }) => (
			<li key={file.path}>
				{file.path} - {file.size} bytes
				<ul>
					{errors.map((e) => (
						<li key={e.code}>{e.message}</li>
					))}
				</ul>
			</li>
		)
	);

	useEffect(() => {
		const errors = fileRejections.map(
			({ file, errors }) => {
				return `${file.path}`;
			}
		);

		setErrorFiles(errors);
	}, [fileRejections]);

	return (
		<div
            {...getRootProps()}
				style={{
					flexDirection: 'column',
					borderRadius: '3px',
				}}
				className={`h-[160px] mb-6 w-full flex justify-center items-center border border-newTheme-gray border-dashed font-DM-Sans-Medium bg-[#FFFCF9]  p-6 cursor-pointer rounded-lg ${disabled ? "cursor-not-allowed opacity-60" : ""} ${className}`}>
				{loading ? (
					<>
						<Spinner />

						<h4 className="text-base font-semibold">
							Uploading...
						</h4>
					</>
				) : (
					<>
						<div className="mt-4 font-medium text-newTheme-black text-xs text-center" >
							Drag and drop files from your device, or
						</div>
						{/* <div className="font-medium text-primary-gray-text text-xs text-center">
							(only pdf, doc files supported), or
						</div> */}
						<div className="flex text-newTheme-gray gap-2 mt-4 text-sm">
							<p>Upload</p>
							<FaUpload/>
                            <input {...getInputProps()} />
						</div>
					</>
				)}
			</div>
	);
};

export default DragDrop;