import React, { useEffect, useState } from 'react'
import * as XLSX from 'xlsx';
import { toast } from "react-toastify";
import useAxiosInstance from "../../hooks/useAxios";
import Select from "react-select";
import { v4 as uuid } from "uuid";
import Input from '../../components/input';
import { MdDelete } from 'react-icons/md';

const ActivationEmailBulkSupport = () => {
    const [FileUploaded,setFileUploaded] = useState([]);
    const [isOrgLoading, setIsOrgLoading] = useState(false);
    const [allOrg, setAllOrg] = useState({});
    const [orgName,setOrgName] = useState('')
    const [accountType,setAccountType] = useState('');
    const [expireDate,setExpireDate] = useState('')
    const [isLoading,setIsLoading] = useState(false);
    const axios = useAxiosInstance();
    // const [isCsvCorrect]
    function formatDate(inputDate) {
        const originalDate = new Date(inputDate);
        const year = originalDate.getFullYear();
        const month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const day = originalDate.getDate().toString().padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
      }

    const handleUpload = (e) => {
        let formated_data = []
        e.preventDefault();
        var files = e.target.files, f = files[0];
        if(f!==null){
        var reader = new FileReader();
        console.log(f)
        // if(f.type!=='text/csv'){
        //     toast.info('Uploaded file is not a csv, converting it.')
        // }
        reader.onload = function (e) {
            var data = e.target.result;
            let readedData = XLSX.read(data, {type: 'binary'});
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];
            let dataParse=''
            
            /* Convert array to json*/
            dataParse = XLSX.utils.sheet_to_json(ws, {header:1});
            if(f.type!=='text/csv'){
                toast.info('Uploaded file is not a csv.')                
            }

            dataParse.forEach((row,index)=>{
                if(index !== 0){
                    formated_data.push(
                        {   
                            "id":uuid(),
                            "first_name":row[0],
                            "last_name":row[1],
                            "email":row[2],
                            "org_name":orgName,
                            "force_email":true,
                            // "expiry_date":expireDate
                        }
                    )
                }
            })
            setFileUploaded(formated_data);
        };
        reader.readAsBinaryString(f)
        }
    }

    useEffect(()=>{
        setFileUploaded(prev=>{
            let data = prev
            data.forEach(item=>{
                item['org_name'] = orgName
                // item['expiry_date'] = expireDate
            })
            return data
        })
    },[orgName])
    
    useEffect(() => {
        setIsOrgLoading(true);
        axios
          .get("accounts/organisations/")
          .then((res) => {
            setAllOrg(
              res.data.organisations
            );
            setIsOrgLoading(false);
          })
          .catch((err) => {
            setIsOrgLoading(false);
            toast.error("Bad Network");
          });
      }, []);    

    const handleFormChange=(e,id,key)=>{
        setFileUploaded((prev)=>{ 
            let prevData = prev;
            let perticularConfig = prevData.find(item=>item.id===id)
            perticularConfig[key] = e.target.value
            prevData = [...prevData.filter(item=>item.id!==id),perticularConfig]   
            return prevData})
    }  
    return (
    <div>
    <div className='mr-4  p-3 mt-5 border rounded-lg bg-white'>
        <p className="text-2xl font-semibold mb-4">Activation Email Bulk</p>
        <div className="text-sm font-semibold mb-2">Org Name</div>        
            <Select
              isLoading={isOrgLoading}
              className="w-full"
              options={Object.keys(allOrg).map(elem=>({label:elem,value:elem}))}
              onChange={(e) => {
                setAccountType(allOrg[e.value])
                setOrgName(e.value)
              }}
            />
            {/* <div className="text-sm font-semibold mb-2 mt-4">
              Expiry Date<span className="text-[10px] ml-1">(optional)</span>
            </div>
            <Input
              value={expireDate}
              type={'date'}
              onChange={(e) => {
                setExpireDate(formatDate(e.target.value))
              }}
              placeholder="Expiry Date"
            /> */}

            <div className="text-sm font-semibold mb-2 mt-4">
              Account Type
            </div>
        
            <Input
              value={accountType}
              disabled  
            //   type={'date'}
            //   onChange={(e) => {
            //     setExpireDate(formatDate(e.target.value))
            //   }}
              placeholder="Account type"
            />

        {(accountType == 'trial' || accountType == 'paid') && 
        <>    
        <div className="text-sm font-semibold mb-2 mt-4">Choose file(csv format)</div>
            <p className='text-xs font-medium mb-2'>Expected sequence:- first_name || last_name || email</p>
        <input
        id='file_upload_activation_email_bulk'
        disabled={orgName=='' || accountType==""}
        onChange={e=>handleUpload(e)} 
        className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 disabled:bg-gray-300"
        type='file'/>
        <div className='flex justify-end w-full'>
            <button 
            className='bg-black disabled:bg-[#B6b6b6] disabled:cursor-not-allowed disabled:text-white text-white py-2 px-2 hover:shadow-lg  text-center max-w-max rounded-lg mt-4'
            onClick={()=>{
                document.getElementById('file_upload_activation_email_bulk').value=null
            }}>Reset</button>
        </div>
        </>}    
        {FileUploaded.length>0 &&     
        <>    
        <p className='text-2xl font-semibold mb-4 mt-4'>Preview</p>
        {FileUploaded.sort((a,b)=>a.id.localeCompare(b.id)).map(row=>{return <div className="w-full p-3 mt-5 border-t border rounded-lg bg-slate-100">
            <div className='flex justify-between items-center mb-4'>
                <p className='text-lg font-semibold'>{row.email}</p>                
                <MdDelete 
                onClick={()=>{
                    let con=window.confirm('Are you sure ?')
                    if(con){
                        setFileUploaded((prev)=>prev.filter(item=>item.id!==row.id))
                        toast.success('Successfully deleted')
                    }
                }}
                className='cursor-pointer'/>
            </div>
            
            <div className="text-sm font-semibold mb-2">Email</div>
            <Input
              value={row.email}
              onChange={(e) => {
                handleFormChange(e,row.id,'email')
              }}
              placeholder="Enter the email"
            />
            <div className=" mt-4 grid grid-cols-2 gap-2">
              <div>
                <div className="text-sm font-semibold mb-2"> First Name</div>
                <Input
                  value={row.first_name}
                  onChange={(e) => {
                    handleFormChange(e,row.id,'first_name')
                  }}
                  placeholder="Enter the first name"
                />
              </div>
              <div>
                <div className="text-sm font-semibold mb-2"> Last Name</div>
                <Input
                  value={row.last_name}
                  onChange={(e) => {
                    handleFormChange(e,row.id,'last_name')
                  }}
                  placeholder="Enter the last name"
                />
              </div>
            </div>
            {/* <div className="text-sm font-semibold mb-2 mt-4">
              Organization Name
            </div>
            <Input
              value={row.org_name}
              onChange={(e) => {
                handleFormChange(e,row.id,'org_name')
              }}
              disabled
              placeholder="Enter the email"
            /> */}

            <div className="text-sm font-semibold mb-2 mt-4">Force Email</div>
            <select
              value={row.force_email}
              onChange={(e) => {
                handleFormChange(e,row.id,'force_email')
              }}
              className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 "
              placeholder="Enter your password"
            >
              <option value={''}>choose</option>  
              <option value={true}>True</option>
              <option value={false}>False</option>
            </select>

            {/* <div className="text-sm font-semibold mb-2 mt-4">
              Expiry Date<span className="text-[10px] ml-1">(optional)</span>
            </div>
            <Input
              value={row.expiry_date}
              type={'date'}
              onChange={(e) => {
                handleFormChange(e,row.id,'date')
              }}
              placeholder="Expiry Date"
            />  */}
          </div>
        })}  
        </>}
        {FileUploaded.length>0&&
         <div className='flex justify-end w-full'>
            <button
            disabled={isLoading} 
            className='bg-black disabled:bg-[#B6b6b6] disabled:cursor-not-allowed disabled:text-white text-white py-2 px-2 hover:shadow-lg  text-center max-w-max rounded-lg mt-4'
            onClick={()=>{
                setIsLoading(true)
                let payload = {
                    'user_list':FileUploaded,
                    'account_type':accountType
                }
                axios
                .post("/accounts/bulk_account_create/", payload)
                .then((res) => {
                  setIsLoading(false)
                  toast.success('Successfully Send')
                  console.log(res.data);
                })
                .catch((err) => {
                  setIsLoading(false)
                  toast.error('Something went wrong!')
                  console.log(err);
                });
            }}>{isLoading?"sending...":"Submit"}</button>  
         </div>}
    </div>
    </div>
  )
}

export default ActivationEmailBulkSupport