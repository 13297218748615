// authSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  username: "",
  access_token: "",
  expires_in:"",
  token_type: "",
  scope: "",
  refresh_token: "",
  env:""
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      state.username = action.payload.username;
      state.access_token=action.payload.access_token;
      state.expires_in=action.payload.expires_in;
      state.token_type=action.payload.token_type;
      state.scope=action.payload.scope;
      state.refresh_token=action.payload.refresh_token;
      state.env=action.payload.env;
      localStorage.setItem('wokelo-sandbox-auth-token',JSON.stringify({...action.payload}))
    },
    logout: (state) => {
      state.username = "";
      state.access_token = "";
      state.expires_in ="";
      state.token_type = "";
      state.scope = "";
      state.refresh_token = "";
      state.env="";
      localStorage.removeItem('wokelo-sandbox-auth-token')    
    },
  },
});

export const { login, logout } = authSlice.actions;
export const selectUser = (state) => state.auth.user;
export default authSlice.reducer;