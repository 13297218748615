import React from "react";
import { MdArrowBack, MdDateRange, MdEmail, MdSend, MdSettings } from "react-icons/md";
import { GoOrganization } from "react-icons/go";
import { FaUserCircle } from "react-icons/fa";
const Preview = (props) => {
  const { formData, handleSubmit, setSteps, isLoading} = props;
  console.log(formData)
  return (
    <div>
      <div className="w-[676px] p-3 mt-5 border rounded-lg bg-white">
        <p className="text-2xl font-semibold mb-4">Preview</p>
        <div className="border rounded-xl bg-gray-50 px-3 py-2">
          <p className="flex justify-between mb-2">
            <span className="flex gap-1 items-center">
              <MdEmail />
              Email{" "}
            </span>{" "}
            {formData.email}
          </p>
          <p className="flex justify-between mb-2">
            <span className="flex gap-1 items-center">
              <GoOrganization />
              Organization Name{" "}
            </span>{" "}
            {formData.org_name}
          </p>
          <p className="flex justify-between mb-2">
            <span className="flex gap-1 items-center">
              <FaUserCircle />
              First Name{" "}
            </span>{" "}
            {formData.first_name}
          </p>
          <p className="flex justify-between mb-2">
            <span className="flex gap-1 items-center">
              <FaUserCircle />
              Last Name{" "}
            </span>{" "}
            {formData.last_name}
          </p>
          <p className="flex justify-between mb-2">
            <span className="flex gap-1 items-center">
              <MdSettings />
              Force Email{" "}
            </span>{" "}
            {formData?.force_email.toString().toUpperCase()}
          </p>
          {formData?.expiry_date && <p className="flex justify-between">
            <span className="flex gap-1 items-center">
              <MdDateRange />
              Expiry Date{" "}
            </span>{" "}
            {formData?.expiry_date}
          </p>}
        </div>
        <div className="flex justify-between">
          <button
            title="Go Back and Edit"
            className="flex gap-1 items-center px-2 py-1 mt-2 border rounded-lg"
            onClick={() => {
              setSteps(0);
            }}
          >
            <MdArrowBack />
          </button>

          <button
            disabled={isLoading}
            title="Go Back and Edit"
            className="flex gap-1 items-center px-2 py-1 mt-2 border border-black bg-black disabled:bg-[#b6b6b6] disabled:text-white text-white rounded-lg"
            onClick={() => {
              handleSubmit();
            }}
          >
            Send <MdSend />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Preview;
