import React, { useState } from 'react'
import useAxiosInstance from '../../hooks/useAxios'
import { MdCopyAll } from 'react-icons/md'
import Input from '../../components/input'
import { toast } from 'react-toastify'

const GenerateActivationLink = () => {  
    const [email,setEmail] = useState('')
    const [generatedLink ,setGeneratedLink] = useState("")
    const [isLoading,setIsLoading] = useState("")
    // const []

    const axios = useAxiosInstance() 

    const handleSubmit=()=>{
        setIsLoading(true)
        axios.post('/accounts/view_get_activation_link/',{
            email:email,
        }).then(res=>{
            setIsLoading(false)
            setGeneratedLink(res.data.link)
        }).catch(err=>{
            setIsLoading(false)
            
        })
    }

    return (
    <div className="min-h-screen">
    <div className="flex justify-center">
     <div className="w-[676px] flex bg-white border rounded-lg items-center mt-10">  
     <div className="p-6 w-full">
     <div className="mb-4">
       <div className="text-2xl font-semibold mb-4">Generate activation link</div>
        <div className="text-sm font-semibold mt-4 mb-2">Email*</div>
       <Input
         value={email}
         onChange={(e) => {
            setEmail(e.target.value);
         }}
         placeholder="Enter the generatedLink"
       />
       {( email ) &&   
       <button disabled={isLoading} onClick={()=>handleSubmit()} className='px-3 py-2 mt-4 rounded-xl bg-black text-white'>{isLoading ?"generating..." :"Submit"}</button>  
       }
       {generatedLink && <div className='w-full'>
        <div className='flex justify-end w-full'><MdCopyAll onClick={()=>{(navigator.clipboard.writeText(generatedLink))
        toast.success('Copied to clipboard')
       }} className='ml-2 cursor-pointer'/></div>
        <div className='p-2 mt-4 bg-slate-50 border rounded-lg flex items-center'>{generatedLink}</div>
        </div>}
       </div>
     </div>
 </div>
 </div>
 </div>
  )
}

export default GenerateActivationLink