import React, { useState } from "react";
import useAxiosInstance from "../hooks/useAxios";
import Input from "../components/input";
import { Button } from "flowbite-react";
import { toast } from "react-toastify";
import { FaSpinner } from "react-icons/fa";

const FileUpload_2 = () => {
  const [step, setStep] = useState(1);
  const [reportId, setReportID] = useState("");
  const [pdfFile,setPdfFile] = useState(null);
  const [docFile,setDocFile] = useState(null);
  const axios = useAxiosInstance();
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitFileLoading] = useState(false);

  const handleFileChangePdf = (event) => {
    setPdfFile(event.target.files[0]);
  };

  const handleFileChangeDoc = (event) => {
    setDocFile(event.target.files[0]);
  };

  const handleUpload = () => {
    const formData = new FormData();
    formData.append('docx_file', docFile);
    formData.append('pdf_file', pdfFile);
    formData.append('report_id', reportId);
    
    setIsFileLoading(true)
    axios.post('/admin/upload_file/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(response => {
      setIsFileLoading(false)
      toast.success('Successfully uploaded')
    })
    .catch(error => {
      setIsFileLoading(false)
      toast.error('Uploading Failed')
      console.error(error);
    });
  };

  const handleSubmit = () =>{
    setIsSubmitFileLoading(true)
    axios.post('/admin/send_email/', {report_id:reportId})
    .then(response => {
      setIsSubmitFileLoading(false) 
      toast.success('Successfully sent')
    })
    .catch(error => {
      setIsSubmitFileLoading(false)
      toast.error('failed')
      console.error(error);
    });
  }
  return (
    <div>
      <div className={`h-screen w-full justify-center p-5 items-center flex`}>
        {step === 1 ? (
          <div className="w-[676px] p-3 mt-5 border rounded-lg bg-white">
            <p className="text-2xl font-semibold mb-4">Publish Report</p>
            <div className="text-sm font-semibold mb-2">Report ID</div>
            <Input
              type="text"
              value={reportId}
              onChange={(e) => {
                setReportID(e.target.value);
              }}
            />
            <div className="text-sm font-semibold mt-4 mb-2">Pdf File</div>
            <Input
              disabled={reportId===""}
              type="file"
              onChange={handleFileChangePdf}
            />
            <div className="text-sm font-semibold mt-4 mb-2">Doc File</div>
            <Input
              disabled={reportId===""}
              type="file"
              onChange={handleFileChangeDoc}
            />
          <button
            disabled={
             docFile===null || pdfFile === null || isFileLoading
            }
            className="bg-black disabled:bg-[#B6b6b6] disabled:text-white disabled:cursor-not-allowed text-white py-3 hover:shadow-lg  text-center w-full rounded-lg mt-4"
            type="button"
            onClick={() => {
                handleUpload();
            }}
          >
            {isFileLoading ? "Loading...":"Upload"}
          </button>
          <button
            disabled={reportId==="" || isFileLoading || isSubmitLoading}
            className="bg-black disabled:bg-[#B6b6b6] disabled:text-white disabled:cursor-not-allowed text-white py-3 hover:shadow-lg  text-center w-full rounded-lg mt-4"
            type="button"
            onClick={() => {
              handleSubmit();
            }}
          >
            {isSubmitLoading ? "Loading...":"Submit"}
          </button>
  
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default FileUpload_2;
