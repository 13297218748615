import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import wokeloLogo from "../assests/wokeloLogo.svg";
import {
  FaHome,
  FaPlus,
  FaSearch,
} from "react-icons/fa";
import Select, {components} from "react-select";
import { BsGlobeCentralSouthAsia } from "react-icons/bs";

import { useNavigate } from "react-router-dom";
import { logout } from "../store/slice/authSlice";
import { Accordion } from "flowbite-react";
import { options, typeEnmus } from "../helper";

const Sidebar = () => {
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(
    window.location.href.split("/")[0]
  );
  useEffect(() => {
    setCurrentPage(window.location.href.split("/")[3]);
  }, [window.location.href]);

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <FaSearch />
      </components.DropdownIndicator>
    );
  };

  const getOptionsIntoDesiredFormat =(options)=>{
    let allType = Object.values(typeEnmus)
    let formatedOptions = {}
    allType.map(item=>item.label).forEach(type=>{
      formatedOptions[type] = []
    })
    options.forEach(option=>{
      formatedOptions[option.type].push(option)
    })
    return formatedOptions
  }

  return (
    <div className="px-3 py-4 border h-screen mr-3 bg-gray-200 w-96">
      <div className="flex h-full flex-col justify-between overflow-y-auto">
        <div className="pr-2">
          <div className="flex w-full justify-center">
            <img className="w-[80px] mb-4" src={wokeloLogo} />
          </div>
          <div className="mt-8">
            <Select
              placeholder={'Search'}
              components={{DropdownIndicator}}
              unstyled
              classNames={{
                control: () => "p-2 h-12 border text-sm  rounded-xl mb-3  border-gray-900",
                menuList:()=> "border bg-gray-300 rounded-xl shadow-sm",
                placeholder:()=>"text-gray-600 font-thin",
                option:()=>"hover:bg-teal-50 p-2",
              }}
              
              formatOptionLabel={(data)=><div className="flex gap-2 items-center cursor-pointer">
                {data.icon} {data.label}
              </div>}
              openMenuOnClick={false}
              onChange={(e)=>{
                navigate('/'+e.value)
              }}
              options={options}
              isSearchable={true}
            />
            <div
              onClick={() => navigate("/")}
              className={`${
                currentPage === "" ? "bg-gray-100" : ""
              } text-lg flex gap-2 cursor-pointer items-center border rounded-xl px-2 py-2 mb-2 hover:bg-gray-100`}
            >
              <FaHome className="text-xl" />
              <div>Home</div>
            </div>

            <Accordion collapseAll arrowIcon={FaPlus} className="text-black">
              {/* scarpper */}
              {Object.keys(getOptionsIntoDesiredFormat(options)).map(type=>{return <Accordion.Panel key={type} className="!bg-white">
                <Accordion.Title className="text-lg flex gap-2 cursor-pointer items-center border font-normal rounded-xl bg-gray-200 px-2 py-2 mb-1 text-black dark:text-black">
                  <div className="flex gap-2 items-center text-black">
                    {Object.values(typeEnmus).find(item=>item.label === type).logo} 
                    {type}
                  </div>
                </Accordion.Title>
                <Accordion.Content className="bg-gray-300 py-2 px-2 rounded-xl">
                {getOptionsIntoDesiredFormat(options)[type].map(card=>{return <div
                    key={card.value}
                    onClick={() => navigate(`/${card.value}`)}
                    className={`${
                      currentPage === card.value ? "bg-gray-100" : ""
                    } text-lg flex gap-2 cursor-pointer items-center  rounded-xl px-2 py-2 mb-2 hover:bg-gray-100`}
                  >
                    {card.icon}
                    <div>{card.label}</div>
                  </div>
                })}
                </Accordion.Content>
              </Accordion.Panel>
              })}
            </Accordion>
          </div>
        </div>
        <div>
          <div className="mb-2">{authData.env}</div>
          <div>{authData.username}</div>
          <div
            onClick={() => dispatch(logout())}
            className="text-lg mt-2 flex gap-2 cursor-pointer items-center border rounded-xl px-2 py-2 mb-2 hover:bg-gray-100"
          >
            Logout
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
