import axios from "axios";
import React, { useState } from "react";
import FileUploadSection from "./FileUploadSection";
import { useSelector } from "react-redux";
import useAxiosInstance from "../../hooks/useAxios";

const WokeloFileUploader = ({version='v2'}) => {
  const [files, setFiles] = useState([]);
  const [fileUploadLoading, setFileUploadLoading] = useState(false);
  const [errorFiles, setErrorFiles] = useState([]);
  const [errorTotalFiles, setErrorTotalFiles] = useState(null);
  const [showDetailErrorFiles, setShowDetailErrorFiles] = useState(0);
  const [fileError, setFileError] = useState("");
  const token = useSelector(state=>state.auth.access_token);

  const SINGLE_FILE_LIMIT = 50 * 1024 * 1024;
  const TOTAL_FILES_LIMIT = 200 * 1024 * 1024;
  const authDetails = useSelector((state) => state.auth);
  const config = {
    source: {
      endpoint: process.env.REACT_APP_SOURCE_API_ENDPOINT,
    },
    test: {
      endpoint: process.env.REACT_APP_TEST_API_ENDPOINT,
    },
    prod: {
      endpoint: process.env.REACT_APP_PROD_API_ENDPOINT,
    },
  };
  const handleFileUpload = async (file) => {
    let newFiles = [];
    Object.keys(file).map((key) => {
      newFiles.push(file[key]);
    });
    newFiles = updatedFilesData(newFiles);
    if (newFiles?.length == 0) return;
    setFileUploadLoading(true);
    const data = new FormData();
    newFiles?.map((f) => { 
      data.append("files", f);
    });
    const response = await axios(
      `${config[authDetails.env].endpoint}/assets/${version==='v2'?'upload_V2':'upload'}/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': `Bearer ${token}`
        },
        data,
      }
    );
    if (response?.data) {
      console.log(response.data)
      setErrorFiles(response?.data.fails);
      setFiles([...files, ...getResponseFilesData(response?.data.success)]);
    }
    setFileUploadLoading(false);
  };

  function getResponseFilesData(data) {
    const fileData = [];
    data?.forEach((item) => {
      fileData?.push({
        original: item?.originalName,
        fileQuality:item?.fileQuality,
        fileRelevance:item?.fileRelevance,
        infoMessage:item?.infoMessage,
        uniqueName: item?.fileName,
      });
    });
    if(version==='v2'){
      const recentlySubmittedFiles = JSON.parse(localStorage.getItem('recent-files'));
      if(recentlySubmittedFiles === null){
        localStorage.setItem('recent-files',JSON.stringify(fileData));
      }else{
        const updatedFileData = [...recentlySubmittedFiles,...fileData];
        localStorage.setItem('recent-files',JSON.stringify(updatedFileData));  
      }
    }else{
      const recentlySubmittedFiles = JSON.parse(localStorage.getItem('recent-files-v1'));
      if(recentlySubmittedFiles === null){
        localStorage.setItem('recent-files-v1',JSON.stringify(fileData));
      }else{
        const updatedFileData = [...recentlySubmittedFiles,...fileData];
        localStorage.setItem('recent-files-v1',JSON.stringify(updatedFileData));  
      }
    }
    return fileData;
  }

  function createGooglePayload(data, token) {
    const IdArray = [];
    const fileName = [];
    data?.forEach((item) => {
      IdArray.push(item?.id);
      fileName.push(item?.name);
    });
    return {
      IdArray,
      fileName,
    };
  }

  const UploadTypes = {
    GDrive: 'gdrive',
    DropBox: 'dropbox',
    OneDrive: 'onedrive',
  };
  

  const updatedFilesData = (data) => {
    let newFiles = [];
    let sameFiles = [];
    let overSizedFiles = [];
    let totalSize = 0;
    setErrorFiles([]);
    setErrorTotalFiles(null);
    data?.map((f) => {
      totalSize = totalSize + f?.size;
      if (f?.size > SINGLE_FILE_LIMIT) {
        overSizedFiles = [...overSizedFiles, { originalName: f?.name }];
      }
      if (
        files
          ?.map((a) => a.original.toLowerCase())
          ?.includes(f?.name.toLowerCase())
      ) {
        sameFiles = [...sameFiles, { originalName: f?.name }];
      } else {
        newFiles = [...newFiles, f];
      }
    });
    if (sameFiles?.length > 0) {
      setErrorFiles(sameFiles);
    } else if (overSizedFiles?.length) {
      setErrorFiles(overSizedFiles);
    } else if (totalSize > TOTAL_FILES_LIMIT) {
      setErrorTotalFiles(
        `Total size of all files exceeds ${
          TOTAL_FILES_LIMIT / (1024 * 1024)
        }MB limit`
      );
      return false;
    }
    return newFiles;
  };
    const handleAddFiles = async (data, type) => {
      data = updatedFilesData(data);
      if (data?.length == 0) return;
      setFileUploadLoading(true);
      let payload = {};
      const GAuthToken = sessionStorage.getItem("google-drive-token");
      const OneAuthToken = sessionStorage.getItem("one-drive-token");
      const dataPoint = createGooglePayload(data, GAuthToken);

      if (type === UploadTypes.GDrive) {
        payload = {
          access_token: GAuthToken,
          type: UploadTypes.GDrive,
          name: dataPoint?.fileName,
          data: data,
        };
      } else if (type === UploadTypes.DropBox) {
        payload = {
          type: UploadTypes.DropBox,
          name: dataPoint?.fileName,
          data: data,
        };
      } else if (type === UploadTypes.OneDrive) {
        payload = {
          access_token: OneAuthToken,
          type: UploadTypes.OneDrive,
          name: dataPoint?.fileName,
          data: data,
        };
      }
      // await Api.driveFileUpload({ payload }, (callbackResponse) => {
      //   if (callbackResponse) {
      //     setFileError(callbackResponse);
      //   } else {
      //     setFileError("");
      //   }
      //   // setTimeout(() => {
      //   // 	setFileError('');
      //   // }, 3000);
      // })
      //   .then((res) => {
      //     if (res.response) {
      //       setErrorFiles(res.response?.fails);
      //       setFiles([...files, ...getResponseFilesData(res.response?.success)]);
      //     }
      //   })
      //   .catch((err) => console.error(err))
      //   .finally(() => setFileUploadLoading(false));
    };

    const handleRemoveFile = (fileToBeDeleted) => {
      const uploadedFiles = [...files];
      const updatedFiles = uploadedFiles.filter(
          (file) => !fileToBeDeleted?.includes(file?.uniqueName)
      );
      setFiles([...updatedFiles]);
    };

    function toggleDetailErrorFiles(event, dataLength) {
      if (showDetailErrorFiles === 0) {
          event.target.innerText = 'Hide details';
          setShowDetailErrorFiles(dataLength);
      } else {
          event.target.innerText = 'See details';
          setShowDetailErrorFiles(0);
      }
  }

  return (
    <div>
      <FileUploadSection
        fileTypes={["pdf", "docx", "pptx", "ppt", "doc"]}
        handleFileUpload={handleFileUpload}
        fileUploadLoading={fileUploadLoading} // Update this based on your app state
        files={files}
        errorFiles={errorFiles}
        showDetailErrorFiles={showDetailErrorFiles}
        handleAddFiles={handleAddFiles}
        handleRemoveFile={handleRemoveFile}
        toggleDetailErrorFiles={toggleDetailErrorFiles}
        setFileError={setFileError}
      />
    </div>
  );
};

export default WokeloFileUploader;
