import React, { useEffect, useState } from "react";
import wokeloLogo from "../assests/wokeloLogo.svg";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { login } from "../store/slice/authSlice";
import Input from "../components/input";
import { FaSpinner } from "react-icons/fa";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [env, setEnv] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const getDate=(seconds)=>{
    let currentDate = new Date();
    let secondsToAdd = seconds;
    currentDate.setSeconds(currentDate.getSeconds() + secondsToAdd);
    return currentDate;
  }
  
  const onSubmit = () => {
    const config = {
      source: {
        endpoint: process.env.REACT_APP_SOURCE_AUTH_ENDPOINT,
        client_id: process.env.REACT_APP_SOURCE_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_SOURCE_APP_CLIENT_SECRET,
      },
      test: {
        endpoint: process.env.REACT_APP_TEST_AUTH_ENDPOINT,
        client_id: process.env.REACT_APP_TEST_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_TEST_APP_CLIENT_SECRET,
      },
      prod: {
        endpoint: process.env.REACT_APP_PROD_AUTH_ENDPOINT,
        client_id: process.env.REACT_APP_PROD_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_PROD_APP_CLIENT_SECRET,
      },
    };

    const { endpoint, client_id, client_secret } = config[env];
    setIsLoading(true);
    axios
      .post(`${endpoint}/token/`, {
        username: email,
        password,
        grant_type: "password",
        client_id,
        client_secret,
      })
      .then(async(res) => {
        const response = await axios(
          `${endpoint}/accounts/user_detail/`,
          {
            method: "GET",
            headers: {
              'Authorization': `Bearer ${res.data.access_token}`
            },
          }
        );
        if((response.data.staff)){
          toast.success("Login Successful");
          setIsLoading(false);
          // ,expire_date:getDate(action.payload.expires_in)
          dispatch(login({ username: email, ...res.data, env: env }));

          //NOTE:-> TO ASK DEEP BHAIYA FOR IT.
          // localStorage.setItem('wokelo-sandbox-auth-token',JSON.stringify({ username: email, ...res.data, env: env ,expire_date:getDate(res.data.expires_in)}))  
          
        }else{
          toast.error("You are not authorised");
          setIsLoading(false);
        }  
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        toast.error("Login Failed");
      });
  };

  return (
    <div className="h-[100vh] bg-gray-100 w-full flex justify-center items-center">
      <div className="w-[500px] bg-white p-5 rounded-lg shadow-md border text-gray-800">
        <div className="flex w-full justify-center">
          <img className="w-[80px] mb-4" src={wokeloLogo} />
        </div>

        <div className="mb-4 text-xl font-semibold">Welcome to Sandbox</div>

        <div>
          <div className="text-sm font-semibold mb-2 ">Email</div>
          <Input
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            type="email"
            className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 "
            placeholder="Enter your email"
          />
        </div>

        <div className="mt-2">
          <div className="text-sm font-semibold mb-2 ">Password</div>
          <Input
            value={password}
            type={"password"}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 "
            placeholder="Enter your password"
          />
        </div>

        <div className="mt-2">
          <div className="text-sm font-semibold mb-2 ">Environment</div>
          <select
            value={env}
            onChange={(e) => {
              setEnv(e.target.value);
            }}
            className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 "
            placeholder="Enter your password"
          >
            <option value={""}>Choose</option>
            <option value={"prod"}>Production</option>
            <option value={"test"}>Test</option>
            <option value={"source"}>Source</option>
          </select>
        </div>

        <button
          className="bg-black text-white py-3 hover:shadow-lg disabled:bg-[#b6b6b6] font-semibold disabled:cursor-not-allowed text-center w-full rounded-lg mt-4"
          type="button"
          disabled={env === "" || email === "" || password === "" || isLoading}
          onClick={() => {
            onSubmit();
          }}
        >
          {isLoading ? (
            <div className="animate-spin flex justify-center">
              <FaSpinner />
            </div>
          ) : (
            <p className="font-semibold text-sm">Login</p>
          )}
        </button>
      </div>
    </div>
  );
};

export default LoginPage;
