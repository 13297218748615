import React, { useEffect, useState } from "react";
import Input from "../../../components/input";
import { FaPen, FaPlus, FaTrash } from "react-icons/fa";
import { v4 as uuid } from "uuid";
import { Accordion } from "flowbite-react";

const TableForm = (props) => {
  const {
    fundingRoundArray,
    setFundingRounfArray,
    sub_orgsArray,
    setsub_orgsArray,
    list_acquisitionsArray,
    setlist_acquisitionsArray,
    listInvestmenttsArray,
    setlistInvestmenttsArray,
    setlist_executives,
    list_executives,
    org_uuids,
    setorg_uuids
  } = props;

  const [fundingRoundState, setFundingRoundState] = React.useState({
    date: "",
    uuid_x:"",
    image_id: "",
    org_uuid:"",
    permalink: "",
    funding_round: "",
    num_investors: 0,
    list_investors: [],
    amount_raised_usd: 0,
  });

  const [fundingRound_list_investors, setFundingRound_list_investors] =
    React.useState({
      url: "",
      name: "",
      type: "organization",
      image: "",
      gender: "",
      last_name : "",
      permalink : "",
      first_name : "",
      social_links : {
        twitter_url : "",
        facebook_url : "",
        linkedin_url : "",
      },
      flag_lead_investor: false,
      funding_round_name: ""
    });

  useEffect(()=>{
    setFundingRoundState(prev=>({...prev,num_investors:listOfInvestors.length}))
  },[fundingRound_list_investors])  

  const [listOfInvestors, setListOfInvestors] = useState([]);

//   {
//     "comp": "Imagination Technologies",
//     "date": "2008-12-01",
//     "news": "Apple investment in Post-IPO Equity - Imagination Technologies",
//     "image": "ply8i4ojosztcldaswiw",
//     "org_uuid": "98ced7fb-c747-4920-9470-ebcda6f1077b",
//     "permalink": "imagination-technologies",
//     "funding_round": "Post-IPO Equity",
//     "investment_uuid": "c830f7e1-4579-532f-8442-fae01af7dcc9",
//     "raised_amount_usd": 4774153,
//     "funding_round_uuid": "dee7ff10-f310-c392-6903-cd3eba439931"
// },

  const [listInvestmenttsState, setlistInvestmenttsState] = React.useState({
    comp: "",
    date: "",
    news: "",
    image: "",
    org_uuid:"",
    permalink: "",
    funding_round: "",
    investment_uuid:"",
    raised_amount_usd: 0,
    funding_round_uuid:""
  });

  const [list_acquisitionsState, setlist_acquisitionsState] = React.useState({
    url:"",
    comp: "",
    date: "",
    news: "",
    image_id: "",
    permalink: "",
    amount_raised_usd : ""
  });

  const [sub_orgsState, setsub_orgsState] = React.useState({
    name: "",
    permalink: "",
  });

  const [list_executivesState, setlist_executivesState] = React.useState({
    name: "",
    title : "",
    permalink: "",
    job_type : ""
  });
  
  const listOfInvestors____ = (fundingRoundArray) => {
    let newData = [];
    fundingRoundArray.forEach(round => {
        round.list_investors.forEach(investor => {
            const existingInvestor = newData.find(elem => elem.permalink === investor.permalink);
            if (existingInvestor) {
                existingInvestor.amount += Number(investor.amount);
            } else {
                newData.push({ ...investor,amount:Number(investor.amount) }); // Clone the investor object before pushing
            }
        });
    });
    return newData.sort((a, b) => b.amount - a.amount).slice(0, 5);
};

  
  const getOrgUuid = (org_name) =>{
    let id = uuid()  
    if(org_uuids[org_name]){
        return org_uuids[org_name]
      }else{
        let old_orgIds = org_uuids
        old_orgIds[org_name] = id
        setorg_uuids(old_orgIds)
        return id
      }
  }

  return (
    <div>
      {/* <p className="text-2xl font-semibold mb-4"> List of Investors</p>
      <div className="overflow-x-auto mt-4 border rounded-lg mb-8">
            <table className="w-full">
              <thead className="border-b text-left">
                <th className="px-2 py-1 text-gray-700 text-sm">Name</th>
                <th className="px-2 py-1 text-gray-700 text-sm">type</th>
                <th className="px-2 py-1 text-gray-700 text-sm">image</th>
                <th className="px-2 py-1 text-gray-700 text-sm">permalink</th>
                <th className="px-2 py-1 text-gray-700 text-sm">Amount</th>
              </thead>
              <tbody className="divide-y">
                {listOfInvestors____(fundingRoundArray)?.map((s, index) => {
                  return (
                    <tr key={index} className="bg-white odd:bg-gray-50">
                      <td className="whitespace-nowrap px-2 py-2 font-medium text-gray-900">
                        {s.name}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                        {s.type}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                        {s.image}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                        {s.permalink}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 flex gap-3">
                        {s.amount}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>   */}

      <p className="text-2xl font-semibold mb-4"> Add Funding Rounds</p>
      <div className="grid grid-cols-7 gap-4 mb-3">
    
        <div>
          <div className="text-sm font-semibold mb-2">Date</div>
          <Input
            type={"date"}
            value={fundingRoundState.date}
            onChange={(e) => {
              setFundingRoundState({
                ...fundingRoundState,
                date: e.target.value,
              });
            }}
            placeholder="date"
          />
        </div>
        
        <div>
          <div className="text-sm font-semibold mb-2">Image Id</div>
          <Input
            value={fundingRoundState.image_id}
            onChange={(e) => {
              setFundingRoundState({
                ...fundingRoundState,
                image_id: e.target.value,
              });
            }}
            placeholder="Image Id"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">Permalink</div>
          <Input
            value={fundingRoundState.permalink}
            onChange={(e) => {
              setFundingRoundState({
                ...fundingRoundState,
                permalink: e.target.value,
              });
            }}
            placeholder="Permalink"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">Funding Round</div>
          <Input
            value={fundingRoundState.funding_round}
            onChange={(e) => {
              setFundingRoundState({
                ...fundingRoundState,
                funding_round:(e.target.value),
              });
            }}
            placeholder="Funding round"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">Number of investors</div>
          <Input
          disabled
          type='Number'
            value={fundingRoundState.num_investors}
            onChange={(e) => {
              setFundingRoundState({
                ...fundingRoundState,
                num_investors: Number(e.target.value),
              });
            }}
            placeholder="Number Investors"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">Amount raised usd</div>
          <Input
          
          type='Number'
            value={fundingRoundState.amount_raised_usd}
            onChange={(e) => {
              setFundingRoundState({
                ...fundingRoundState,
                amount_raised_usd: Number(e.target.value),
              });
            }}
            placeholder="Amount raised usd"
          />
        </div>
      </div>
      {/* React.useState({
      url: "",
      name: "",
      type: "",
      image: "",
      gender: "",
      last_name : "",
      permalink : "",
      first_name : "",
      social_links : {
        twitter_url : "",
        facebook_url : "",
        linkedin_url : "",
      },
      flag_lead_investor: false,
      funding_round_name: ""
    }); */}
      <div className="text-lg font-medium mb-4">Add Investor</div>
      <div className="flex gap-4 mb-3">
        <div>
          <div className="text-sm font-semibold mb-2">url</div>
          <Input
            value={fundingRound_list_investors.url}
            onChange={(e) => {
              setFundingRound_list_investors({
                ...fundingRound_list_investors,
                url: e.target.value,
              });
            }}
            placeholder="url"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">name</div>
          <Input
            value={fundingRound_list_investors.name}
            onChange={(e) => {
              setFundingRound_list_investors({
                ...fundingRound_list_investors,
                name: e.target.value,
              });
            }}
            placeholder="name"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">type</div>
          <select
            className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 disabled:bg-gray-300"
            value={fundingRound_list_investors.type}
            onChange={(e) => {
              setFundingRound_list_investors({
                ...fundingRound_list_investors,
                type: e.target.value,
              });
            }}
            placeholder="type"
          >
            <option value='organization'>organization</option>
            <option value='person'>person</option> 
          </select>
        </div>
        
        <div>
          <div className="text-sm font-semibold mb-2">gender</div>
          <Input
            value={fundingRound_list_investors.gender}
            onChange={(e) => {
              setFundingRound_list_investors({
                ...fundingRound_list_investors,
                gender: e.target.value,
              });
            }}
            placeholder="gender"
          />
        </div>

        <div>
          <div className="text-sm font-semibold mb-2">first_name</div>
          <Input
            value={fundingRound_list_investors.first_name}
            onChange={(e) => {
              setFundingRound_list_investors({
                ...fundingRound_list_investors,
                first_name: e.target.value,
              });
            }}
            placeholder="first_name"
          />
        </div>

        <div>
          <div className="text-sm font-semibold mb-2">last_name</div>
          <Input
            value={fundingRound_list_investors.last_name}
            onChange={(e) => {
              setFundingRound_list_investors({
                ...fundingRound_list_investors,
                last_name: e.target.value,
              });
            }}
            placeholder="last_name"
          />
        </div>
        
        <div>
          <div className="text-sm font-semibold mb-2">image</div>
          <Input
            value={fundingRound_list_investors.image}
            onChange={(e) => {
              setFundingRound_list_investors({
                ...fundingRound_list_investors,
                image: e.target.value,
              });
            }}
            placeholder="image"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">permalink</div>
          <Input
            value={fundingRound_list_investors.permalink}
            onChange={(e) => {
              setFundingRound_list_investors({
                ...fundingRound_list_investors,
                permalink: e.target.value,
              });
            }}
            placeholder="permalink"
          />
        </div>

        <div>
          <div className="text-sm font-semibold mb-2">funding_round_name</div>
          <Input
            value={fundingRound_list_investors.funding_round_name}
            onChange={(e) => {
              setFundingRound_list_investors({
                ...fundingRound_list_investors,
                funding_round_name: e.target.value,
              });
            }}
            placeholder="funding_round_name"
          />
        </div>

        <div>
          <div className="text-sm font-semibold mb-2">flag_lead_investor</div>
          <select
            className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 disabled:bg-gray-300"
            value={fundingRound_list_investors.flag_lead_investor}
            onChange={(e) => {
              setFundingRound_list_investors({
                ...fundingRound_list_investors,
                flag_lead_investor: Boolean(e.target.value),
              });
            }}
            placeholder="flag_lead_investor"
          >
            <option value={""}>false</option>
            <option value={true}>true</option>
            
          </select>
        </div>
            
        <button
          onClick={() => {
            let id = uuid()
            let submit_data = fundingRound_list_investors
            if(submit_data.type === 'organization'){
              delete submit_data.first_name
              delete submit_data.last_name
              delete submit_data.gender 
              delete submit_data.social_links
            }
            setListOfInvestors((prev) => [
              ...prev,
              { ...submit_data, id: id },
            ]);
            setFundingRound_list_investors({
              url: "",
              name: "",
              type: "organization",
              image: "",
              gender: "",
              last_name : "",
              permalink : "",
              first_name : "",
              social_links : {
                twitter_url : "",
                facebook_url : "",
                linkedin_url : "",
              },
              flag_lead_investor: false,
              funding_round_name: ""
            });
          }}
          className="border-black border rounded h-max py-2  px-3 hover:bg-black hover:text-white mt-6"
        >
          Add
        </button>
        <button
          onClick={() => {
            let id = uuid()
            setFundingRounfArray((prev) => [
              ...prev,
              {
                ...fundingRoundState,
                list_investors: listOfInvestors,
                id: id,
                uuid_x : id,
                org_uuid : getOrgUuid(fundingRoundState.permalink)
              },
            ]);
            setFundingRoundState({
              date: "",
              uuid_x:"",
              image_id: "",
              org_uuid:"",
              permalink: "",
              funding_round: "",
              num_investors: 0,
              list_investors: [],
              amount_raised_usd: 0,
            });
            setListOfInvestors([]);
          }}
          className="border-black border rounded h-max py-2  px-3 hover:bg-black hover:text-white mt-6"
        >
          Add this Round
        </button>
      </div>

      {listOfInvestors.length > 0 && (
        <div>
          {/* url: "",
              name: "",
              type: "",
              image: "",
              gender: "",
              last_name : "",
              permalink : "",
              first_name : "",
              social_links : {
                twitter_url : "",
                facebook_url : "",
                linkedin_url : "",
              },
              flag_lead_investor: false,
              funding_round_name: "" */}
          <p className="text-sm font-semibold">List of Investors</p>
          <div className="overflow-x-auto mt-4 border rounded-lg mb-8">
            <table className="w-full">
              <thead className="border-b text-left">
                <th className="px-2 py-1 text-gray-700 text-sm">url</th>
                <th className="px-2 py-1 text-gray-700 text-sm">name</th>
                <th className="px-2 py-1 text-gray-700 text-sm">type</th>
                <th className="px-2 py-1 text-gray-700 text-sm">image</th>
                <th className="px-2 py-1 text-gray-700 text-sm">gender</th>
                <th className="px-2 py-1 text-gray-700 text-sm">last_name</th>
                <th className="px-2 py-1 text-gray-700 text-sm">permalink</th>
                <th className="px-2 py-1 text-gray-700 text-sm">first_name</th>
                <th className="px-2 py-1 text-gray-700 text-sm">flag_lead_investor</th>
                <th className="px-2 py-1 text-gray-700 text-sm">Action</th>
              </thead>
              <tbody className="divide-y">
                {listOfInvestors?.map((s, index) => {
                  return (
                    <tr key={index} className="bg-white odd:bg-gray-50">
                      <td className="whitespace-nowrap px-2 py-2 font-medium text-gray-900">
                        {s.url}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                        {s.name}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                        {s.type}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                        {s.image}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                        {s.gender}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                        {s.last_name}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                        {s.permalink}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                        {s.first_name}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                        {`${s.flag_lead_investor}`}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 flex gap-3">
                        <button
                          onClick={() => {
                            setFundingRound_list_investors(s);
                            setListOfInvestors((prev) => [
                              ...prev.filter((item) => item.id !== s.id),
                            ]);
                          }}
                          className="rounded bg-red p-2 bg-red-600 text-white text-xs"
                        >
                          <FaPen />
                        </button>
                        <button
                          onClick={() => {
                            setListOfInvestors((prev) => [
                              ...prev.filter((item) => item.id !== s.id),
                            ]);
                          }}
                          className="rounded bg-red p-2 bg-yellow-600 text-white text-xs"
                        >
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {fundingRoundArray.length > 0 && (
        <div className="">
          <Accordion arrowIcon={FaPlus} className="text-black border-0">
            {/* scarpper */}
            <Accordion.Panel className="!bg-white">
              <Accordion.Title className="text-xl flex gap-2 cursor-pointer items-center border font-normal rounded-xl px-2 py-2 mb-1 text-black dark:text-black">
                <div className="flex gap-2 items-center text-black">
                  List of Funding Rounds
                </div>
              </Accordion.Title>
              <Accordion.Content className="bg-gray-100 py-2 px-2 rounded-xl">
                {fundingRoundArray?.map((s, index) => {
                  return (
                    <div
                      key={index}
                      className="bg-white odd:bg-gray-50 border rounded-lg px-4 py-3 mb-2"
                    >
                      <div className="grid grid-cols-4 gap-2 text-sm">
                        <div className="whitespace-nowrap px-2 py-2  border-r w-full text-gray-900">
                          Date : <span className="font-semibold">{s.date}</span>
                        </div>
                        <div className="whitespace-nowrap px-2 py-2  border-r w-full  text-gray-900">
                        uuid_x :{" "}
                          <span className="font-semibold">{`${s.uuid_x}`}</span>
                        </div>
                        <div className="whitespace-nowrap px-2 py-2  border-r w-full  text-gray-900">
                        org_uuid :{" "}
                          <span className="font-semibold">{`${s.org_uuid}`}</span>
                        </div>
                        <div className="whitespace-nowrap px-2 py-2  border-r w-full  text-gray-900">
                          Image Id :{" "}
                          <span className="font-semibold">{s.image_id}</span>
                        </div>
                        <div className="whitespace-nowrap px-2 py-2  border-r w-full  text-gray-900">
                          PermaLink :{" "}
                          <span className="font-semibold">{s.permalink}</span>
                        </div>
                        <div className="whitespace-nowrap px-2 py-2  border-r w-full  text-gray-900">
                          Founding Round :{" "}
                          <span className="font-semibold">
                            {s.funding_round}
                          </span>
                        </div>
                        <div className="whitespace-nowrap px-2 py-2  border-r w-full  text-gray-900">
                          Numer of investors :{" "}
                          <span className="font-semibold">
                            {s.num_investors}
                          </span>
                        </div>
                        <div className="whitespace-nowrap px-2 py-2 border-r w-full  text-gray-900">
                          Amount raised USD :{" "}
                          <span className="font-semibold">
                            {s.amount_raised_usd}
                          </span>
                        </div>
                        <div className="whitespace-nowrap px-2 py-2 flex gap-3 w-full">
                          <button
                            onClick={() => {
                              setFundingRoundState(s);
                              setListOfInvestors(s.list_investors);
                              setFundingRounfArray((prev) => [
                                ...prev.filter((item) => item.id !== s.id),
                              ]);
                            }}
                            className="rounded bg-red p-2 bg-red-600 text-white text-xs"
                          >
                            <FaPen />
                          </button>
                          <button
                            onClick={() => {
                              setFundingRounfArray((prev) => [
                                ...prev.filter((item) => item.id !== s.id),
                              ]);
                            }}
                            className="rounded bg-red p-2 bg-yellow-600 text-white text-xs"
                          >
                            <FaTrash />
                          </button>
                        </div>
                      </div>
                      {s?.list_investors?.length > 0 && (
                        <>
                          <p className="text-sm font-semibold">
                            List of Investors
                          </p>
                          {/* <th className="px-2 py-1 text-gray-700 text-sm">url</th>
                <th className="px-2 py-1 text-gray-700 text-sm">name</th>
                <th className="px-2 py-1 text-gray-700 text-sm">type</th>
                <th className="px-2 py-1 text-gray-700 text-sm">image</th>
                <th className="px-2 py-1 text-gray-700 text-sm">gender</th>
                <th className="px-2 py-1 text-gray-700 text-sm">last_name</th>
                <th className="px-2 py-1 text-gray-700 text-sm">permalink</th>
                <th className="px-2 py-1 text-gray-700 text-sm">first_name</th>
                <th className="px-2 py-1 text-gray-700 text-sm">flag_lead_investor</th>
                <th className="px-2 py-1 text-gray-700 text-sm">Action</th>
               */}
                          <div className="overflow-x-auto min-w-full mt-4 border rounded-lg">
                            <table className="w-full">
                              <thead className="border-b text-left">
                                <th className="px-2 py-1 text-gray-700 text-sm">
                                url
                                </th>
                                <th className="px-2 py-1 text-gray-700 text-sm">
                                name
                                </th>
                                <th className="px-2 py-1 text-gray-700 text-sm">
                                type
                                </th>
                                <th className="px-2 py-1 text-gray-700 text-sm">
                                image
                                </th>
                                <th className="px-2 py-1 text-gray-700 text-sm">
                                gender
                                </th>
                                <th className="px-2 py-1 text-gray-700 text-sm">
                                last_name
                                </th>
                                <th className="px-2 py-1 text-gray-700 text-sm">
                                permalink
                                </th>
                                <th className="px-2 py-1 text-gray-700 text-sm">
                                first_name
                                </th>
                                <th className="px-2 py-1 text-gray-700 text-sm">
                                flag_lead_investor
                                </th>
                               
                              </thead>
                              <tbody className="divide-y">
                                {s?.list_investors?.map((s, index) => {
                                  return (
                                    <tr
                                      key={index}
                                      className="bg-white odd:bg-gray-50"
                                    >
                                      <td className="whitespace-nowrap px-2 py-2 font-medium text-gray-900">
                                        {s.url}
                                      </td>
                                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                                        {s.name}
                                      </td>
                                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                                        {s.type}
                                      </td>
                                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                                        {s.image}
                                      </td>
                                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                                        {s.gender}
                                      </td>
                                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                                        {s.last_name}
                                      </td>
                                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                                        {s.permalink}
                                      </td>
                                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                                        {s.first_name}
                                      </td>
                                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                                        {`${s.flag_lead_investor}`}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </>
                      )}
                    </div>
                  );
                })}
              </Accordion.Content>
            </Accordion.Panel>
          </Accordion>
        </div>
      )}

      <p className="text-2xl font-semibold mb-4"> Add investments</p>
      <div className="grid grid-cols-5 gap-4 mb-3 relative">
        <div>
          <div className="text-sm font-semibold mb-2">comp</div>
          <Input
            value={listInvestmenttsState.comp}
            onChange={(e) => {
              setlistInvestmenttsState({
                ...listInvestmenttsState,
                comp: e.target.value,
              });
            }}
            placeholder="comp"
          />
        </div>

        <div>
          <div className="text-sm font-semibold mb-2">Date</div>
          <Input
            type={'date'}
            value={listInvestmenttsState.date}
            onChange={(e) => {
              setlistInvestmenttsState({
                ...listInvestmenttsState,
                date: e.target.value,
              });
            }}
            placeholder="date"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">news</div>
          <Input
            value={listInvestmenttsState.news}
            onChange={(e) => {
              setlistInvestmenttsState({
                ...listInvestmenttsState,
                news: e.target.value,
              });
            }}
            placeholder="news"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">Image</div>
          <Input
            value={listInvestmenttsState.image}
            onChange={(e) => {
              setlistInvestmenttsState({
                ...listInvestmenttsState,
                image: e.target.value,
              });
            }}
            placeholder="Image"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">raised_amount_usd</div>
          <Input
            type='number'
            value={listInvestmenttsState.raised_amount_usd}
            onChange={(e) => {
              setlistInvestmenttsState({
                ...listInvestmenttsState,
                raised_amount_usd: Number(e.target.value),
              });
            }}
            placeholder="raised_amount_usd"
          />
        </div>    
        <div>
          <div className="text-sm font-semibold mb-2">Permalink</div>
          <Input
            value={listInvestmenttsState.permalink}
            onChange={(e) => {
              setlistInvestmenttsState({
                ...listInvestmenttsState,
                permalink: e.target.value,
              });
            }}
            placeholder="Permalink"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">Funding Round</div>
          <Input
            value={listInvestmenttsState.funding_round}
            onChange={(e) => {
              setlistInvestmenttsState({
                ...listInvestmenttsState,
                funding_round: e.target.value,
              });
            }}
            placeholder="Funding round"
          />
        </div>
        
        <div>
          <button
            onClick={() => {
              let id = uuid()
              setlistInvestmenttsArray((prev) => [
                ...prev,
                {
                  ...listInvestmenttsState,
                  id: id,
                  investment_uuid : id,
                  funding_round_uuid:uuid(),
                  org_uuid:getOrgUuid(listInvestmenttsState.permalink)
                },
              ]);
              setlistInvestmenttsState({
                date: "",
                news: "",
                comp:"",
                image: "",
                org_uuid:"",
                permalink: "",
                funding_round: "",
                investment_uuid:"",
                raised_amount_usd: 0,
                funding_round_uuid:""
              });
            }}
            className="border-black border rounded h-max py-2 right-0 px-3 absolute hover:bg-black hover:text-white mt-6"
          >
            Add this investment
          </button>
        </div>
      </div>

      {listInvestmenttsArray.length > 0 && (
        <div>
          <p className="text-sm font-semibold">List of Investments</p>
          <div className="overflow-x-auto max-w-[1100px] mt-4 border rounded-lg mb-8">
            <table className="w-full">
              <thead className="border-b text-left">
                <th className="px-2 py-1 text-gray-700 text-sm">comp</th>
                <th className="px-2 py-1 text-gray-700 text-sm">date</th>
                <th className="px-2 py-1 text-gray-700 text-sm">news</th>

                <th className="px-2 py-1 text-gray-700 text-sm">image</th>
                <th className="px-2 py-1 text-gray-700 text-sm">permalink</th>
                <th className="px-2 py-1 text-gray-700 text-sm">
                  funding_round
                </th>
                <th className="px-2 py-1 text-gray-700 text-sm">
                raised_amount_usd
                </th>
                <th className="px-2 py-1 text-gray-700 text-sm">
                org_uuid
                </th>
                <th className="px-2 py-1 text-gray-700 text-sm">
                funding_round_uuid
                </th><th className="px-2 py-1 text-gray-700 text-sm">
                investment_uuid
                </th>
                <th className="px-2 py-1 text-gray-700 text-sm">Action</th>
              </thead>
              <tbody className="divide-y">
                {listInvestmenttsArray?.map((s, index) => {
                  return (
                    <tr key={index} className="bg-white odd:bg-gray-50">
                      <td className="whitespace-nowrap px-2 py-2 font-medium text-gray-900">
                        {s.comp}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                        {s.date}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                        {s.news}
                      </td>
                      

                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                        {s.image}
                      </td>

                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                        {s.permalink}
                      </td>

                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                        {s.funding_round}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                        {s.raised_amount_usd}
                      </td>

                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                        {s.org_uuid}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                        {s.funding_round_uuid}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                        {s.investment_uuid}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 flex gap-3">
                        <button
                          onClick={() => {
                            setlistInvestmenttsState(s);
                            setlistInvestmenttsArray((prev) => [
                              ...prev.filter((item) => item.id !== s.id),
                            ]);
                          }}
                          className="rounded bg-red p-2 bg-red-600 text-white text-xs"
                        >
                          <FaPen />
                        </button>
                        <button
                          onClick={() => {
                            setlistInvestmenttsArray((prev) => [
                              ...prev.filter((item) => item.id !== s.id),
                            ]);
                          }}
                          className="rounded bg-red p-2 bg-yellow-600 text-white text-xs"
                        >
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <p className="text-2xl font-semibold mb-4"> Add Acquisitions</p>
      <div className="grid grid-cols-5 gap-4 mb-3 relative">
        <div>
          <div className="text-sm font-semibold mb-2">comp</div>
          <Input
            value={list_acquisitionsState.comp}
            onChange={(e) => {
              setlist_acquisitionsState({
                ...list_acquisitionsState,
                comp: e.target.value,
              });
            }}
            placeholder="comp"
          />
        </div>

        <div>
          <div className="text-sm font-semibold mb-2">Date</div>
          <Input
            type={'date'}
            value={list_acquisitionsState.date}
            onChange={(e) => {
              setlist_acquisitionsState({
                ...list_acquisitionsState,
                date: e.target.value,
              });
            }}
            placeholder="date"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">news</div>
          <Input
            value={list_acquisitionsState.news}
            onChange={(e) => {
              setlist_acquisitionsState({
                ...list_acquisitionsState,
                news: e.target.value,
              });
            }}
            placeholder="news"
          />
        </div>
        
        <div>
          <div className="text-sm font-semibold mb-2">Image Id</div>
          <Input
            value={list_acquisitionsState.image_id}
            onChange={(e) => {
              setlist_acquisitionsState({
                ...list_acquisitionsState,
                image_id: e.target.value,
              });
            }}
            placeholder="Image Id"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">Permalink</div>
          <Input
            value={list_acquisitionsState.permalink}
            onChange={(e) => {
              setlist_acquisitionsState({
                ...list_acquisitionsState,
                permalink: e.target.value,
              });
            }}
            placeholder="Permalink"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">url</div>
          <Input
            value={list_acquisitionsState.url}
            onChange={(e) => {
              setlist_acquisitionsState({
                ...list_acquisitionsState,
                url: e.target.value,
              });
            }}
            placeholder="url"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">amount_raised_usd</div>
          <Input
            type='number'
            value={list_acquisitionsState.amount_raised_usd}
            onChange={(e) => {
              let storing_value = e.target.value
              if(storing_value == ""){
                storing_value = ""
              }else{
                storing_value = Number(e.target.value)
              }
              setlist_acquisitionsState({
                ...list_acquisitionsState,
                amount_raised_usd: storing_value,
              });
            }}
            placeholder="amount_raised_usd"
          />
        </div>
      
        <div>
          <button
            onClick={() => {
              let id = uuid()
              setlist_acquisitionsArray((prev) => [
                ...prev,
                {
                  ...list_acquisitionsState,
                  id: id,
                  acquisition_uuid : id
                },
              ]);
              setlist_acquisitionsState({
                comp: "",
                date: "",
                news: "",
                image_id: "",
                permalink: "",
                url : "",
                amount_raised_usd : ""
              });
            }}
            className="border-black border rounded h-max py-2 right-0 px-3 absolute hover:bg-black hover:text-white mt-6"
          >
            Add this acquisition
          </button>
        </div>
      </div>

      {list_acquisitionsArray.length > 0 && (
        <div>
          <p className="text-sm font-semibold">List of Acquisitions</p>
          <div className="overflow-x-auto mt-4 border rounded-lg mb-8">
            <table className="w-full">
              <thead className="border-b text-left">
                <th className="px-2 py-1 text-gray-700 text-sm">comp</th>
                <th className="px-2 py-1 text-gray-700 text-sm">date</th>
                <th className="px-2 py-1 text-gray-700 text-sm">news</th>

                <th className="px-2 py-1 text-gray-700 text-sm">image_id</th>
                <th className="px-2 py-1 text-gray-700 text-sm">permalink</th>
                <th className="px-2 py-1 text-gray-700 text-sm">url</th>
                <th className="px-2 py-1 text-gray-700 text-sm">amount_raised_usd</th>
              
                <th className="px-2 py-1 text-gray-700 text-sm">Action</th>
              </thead>
              <tbody className="divide-y">
                {list_acquisitionsArray?.map((s, index) => {
                  return (
                    <tr key={index} className="bg-white odd:bg-gray-50">
                      <td className="whitespace-nowrap px-2 py-2 font-medium text-gray-900">
                        {s.comp}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                        {s.date}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                        {s.news}
                      </td>
                      

                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                        {s.image_id}
                      </td>

                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                        {s.permalink}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                        {s.url}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                        {s.amount_raised_usd}
                      </td>

                     
                      <td className="whitespace-nowrap px-2 py-2 flex gap-3">
                        <button
                          onClick={() => {
                            setlist_acquisitionsState(s);
                            setlist_acquisitionsArray((prev) => [
                              ...prev.filter((item) => item.id !== s.id),
                            ]);
                          }}
                          className="rounded bg-red p-2 bg-red-600 text-white text-xs"
                        >
                          <FaPen />
                        </button>
                        <button
                          onClick={() => {
                            setlist_acquisitionsArray((prev) => [
                              ...prev.filter((item) => item.id !== s.id),
                            ]);
                          }}
                          className="rounded bg-red p-2 bg-yellow-600 text-white text-xs"
                        >
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <p className="text-2xl font-semibold mb-4"> Add sub_orgs</p>
      <div className="grid grid-cols-5 gap-4 mb-3 relative">
        <div>
          <div className="text-sm font-semibold mb-2">name</div>
          <Input
            value={sub_orgsState.name}
            onChange={(e) => {
              setsub_orgsState({
                ...sub_orgsState,
                name: e.target.value,
              });
            }}
            placeholder="name"
          />
        </div>

        <div>
          <div className="text-sm font-semibold mb-2">permalink</div>
          <Input
            value={sub_orgsState.permalink}
            onChange={(e) => {
              setsub_orgsState({
                ...sub_orgsState,
                permalink: e.target.value,
              });
            }}
            placeholder="permalink"
          />
        </div>
        <div>
          <button
            onClick={() => {
              setsub_orgsArray((prev) => [
                ...prev,
                {
                  ...sub_orgsState,
                  id: uuid(),
                },
              ]);
              setsub_orgsState({
                name: "",
                permalink: "",
              });
            }}
            className="border-black border rounded h-max py-2 right-0 px-3 absolute hover:bg-black hover:text-white mt-6"
          >
            Add this Sub Org
          </button>
        </div>
      </div>

      {sub_orgsArray.length > 0 && (
        <div>
          <p className="text-sm font-semibold">List of suborgss</p>
          <div className="overflow-x-auto mt-4 border rounded-lg mb-8">
            <table className="w-full">
              <thead className="border-b text-left">
                <th className="px-2 py-1 text-gray-700 text-sm">name</th>
                <th className="px-2 py-1 text-gray-700 text-sm">permalink</th>
                <th className="px-2 py-1 text-gray-700 text-sm">Action</th>
              </thead>
              <tbody className="divide-y">
                {sub_orgsArray?.map((s, index) => {
                  return (
                    <tr key={index} className="bg-white odd:bg-gray-50">
                      <td className="whitespace-nowrap px-2 py-2 font-medium text-gray-900">
                        {s.name}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                        {s.permalink}
                      </td>

                      <td className="whitespace-nowrap px-2 py-2 flex gap-3">
                        <button
                          onClick={() => {
                            setsub_orgsState(s);
                            setsub_orgsArray((prev) => [
                              ...prev.filter((item) => item.id !== s.id),
                            ]);
                          }}
                          className="rounded bg-red p-2 bg-red-600 text-white text-xs"
                        >
                          <FaPen />
                        </button>
                        <button
                          onClick={() => {
                            setsub_orgsArray((prev) => [
                              ...prev.filter((item) => item.id !== s.id),
                            ]);
                          }}
                          className="rounded bg-red p-2 bg-yellow-600 text-white text-xs"
                        >
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}

  <p className="text-2xl font-semibold mb-4"> Add list_executives</p>
      <div className="grid grid-cols-5 gap-4 mb-3 relative">
        <div>
          <div className="text-sm font-semibold mb-2">name</div>
          <Input
            value={list_executivesState.name}
            onChange={(e) => {
              setlist_executivesState({
                ...list_executivesState,
                name: e.target.value,
              });
            }}
            placeholder="name"
          />
        </div>

        <div>
          <div className="text-sm font-semibold mb-2">permalink</div>
          <Input
            value={list_executivesState.permalink}
            onChange={(e) => {
              setlist_executivesState({
                ...list_executivesState,
                permalink: e.target.value,
              });
            }}
            placeholder="permalink"
          />
        </div>

        {/* 'executive', 'employee', 'board_member', 'advisor' */}
        <div>
          <div className="text-sm font-semibold mb-2">job_type</div>
          <select
            className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 disabled:bg-gray-300"
            value={list_executivesState.job_type}
            onChange={(e) => {
              setlist_executivesState({
                ...list_executivesState,
                job_type: e.target.value,
              });
            }}
            placeholder="job_type"
          >
            {['','executive', 'employee', 'board_member', 'advisor'].map(elem=>{
              return <option value={elem}>{elem==""? "choose":elem}</option>
            })}
          </select>
        </div>

        <div>
          <div className="text-sm font-semibold mb-2">title</div>
          <Input
            value={list_executivesState.title}
            onChange={(e) => {
              setlist_executivesState({
                ...list_executivesState,
                title: e.target.value,
              });
            }}
            placeholder="title"
          />
        </div>    

        <div>

          <button
            onClick={() => {
              setlist_executives((prev) => [
                ...prev,
                {
                  ...list_executivesState,
                  id: uuid(),
                },
              ]);
              setlist_executivesState({
                name: "",
                permalink: "",
                job_type :"",
                title :""
              });
            }}
            className="border-black border rounded h-max py-2 right-0 px-3 absolute hover:bg-black hover:text-white mt-6"
          >
            Add this executive
          </button>
        </div>
      </div>

      {list_executives.length > 0 && (
        <div>
          <p className="text-sm font-semibold">List of list_executives</p>
          <div className="overflow-x-auto mt-4 border rounded-lg mb-8">
            <table className="w-full">
              <thead className="border-b text-left">
                <th className="px-2 py-1 text-gray-700 text-sm">name</th>
                <th className="px-2 py-1 text-gray-700 text-sm">permalink</th>
                <th className="px-2 py-1 text-gray-700 text-sm">job_type</th>
                <th className="px-2 py-1 text-gray-700 text-sm">title</th>
                
                <th className="px-2 py-1 text-gray-700 text-sm">Action</th>
              </thead>
              <tbody className="divide-y">
                {list_executives?.map((s, index) => {
                  return (
                    <tr key={index} className="bg-white odd:bg-gray-50">
                      <td className="whitespace-nowrap px-2 py-2 font-medium text-gray-900">
                        {s.name}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                        {s.permalink}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 font-medium text-gray-900">
                        {s.job_type}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                        {s.title}
                      </td>

                      <td className="whitespace-nowrap px-2 py-2 flex gap-3">
                        <button
                          onClick={() => {
                            setlist_executivesState(s);
                            setlist_executives((prev) => [
                              ...prev.filter((item) => item.id !== s.id),
                            ]);
                          }}
                          className="rounded bg-red p-2 bg-red-600 text-white text-xs"
                        >
                          <FaPen />
                        </button>
                        <button
                          onClick={() => {
                            setlist_executives((prev) => [
                              ...prev.filter((item) => item.id !== s.id),
                            ]);
                          }}
                          className="rounded bg-red p-2 bg-yellow-600 text-white text-xs"
                        >
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableForm;
