import React, { useState } from "react";
import { FaSpinner } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import WokeloFileUploader from "../../components/wokeloFileUploader/wokeloFileUploader";
import RecentlyUploadedFiles from "./RecentlyUploadedFiles";

const FileUploader = () => {
  const [selectVerison, setSelectVersion] = useState("");
  const [steps, setSteps] = useState(0);
  const [data, setData] = useState({});

  return (
    <div
      className={`h-screen w-full justify-center p-5  overflow-y-auto ${
        steps === 1 ? "items-center" : "items-start "
      } flex`}
    >
      {steps === 0 ? (
        <div className="w-full p-3 mt-5 border rounded-lg bg-white">
          <p className="text-2xl font-semibold mb-4">File Uploader</p>
          <div>
          <div className="text-sm font-semibold mb-2">File Upload type</div>
          <select
            className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 disabled:bg-gray-300"
            value={selectVerison}
            onChange={(e) => {
              setSelectVersion(e.target.value);
            }}
          >
            <option value={""}>choose</option>
            <option value={"v1"}>Regular</option>
            <option value={"v2"}>Vision</option>
            </select>
           </div>
          
          {selectVerison!==''&&<><div className="text-sm font-semibold mb-2">Select files</div>
          <WokeloFileUploader version={selectVerison}/>
          <RecentlyUploadedFiles version={selectVerison}/></>}
        </div>
      ) : steps === 1 ? (
        <div className="animate-spin">
          <FaSpinner className="text-3xl" />
        </div>
      ) : (
        <>{/* <Results data={data}/> */}</>
      )}
    </div>
  );
};

export default FileUploader;
