import React, { useEffect, useState } from "react";
import useAxiosInstance from "../../hooks/useAxios";
import { toast } from "react-toastify";
import Select from "react-select";
import DataSynthesisImg from "../../assests/DataSynthesis.svg";
import marketMapImg from "../../assests/ShopIcon.svg";
import companyDeepDiveImg from "../../assests/companyDeepDive.svg";
import customInsightsImg from "../../assests/customInsights.svg";
import lockImg from "../../assests/lock.svg";
import peerAnalysisImg from "../../assests/peerAnalysis.svg";
import sectorSnapshotImg from "../../assests/sectorSnapshot.svg";
import portfolioMonitoringIcon from "../../assests/portfolioMonitoringIcon.svg";
import Card from "./component/card";
import { Spinner } from "flowbite-react";
import { useSearchParams } from "react-router-dom";

const DashboardTiles = () => {
  const [orgFilter, setOrgFilter] = useState("Wokelo");
  const [orgName,setOrgName] = useState("")
  const axios = useAxiosInstance();
  const [allOrg, setAllOrg] = useState([]);
  const [dashboardTiles, setDashboardTiles] = useState([]);
  const [isOrgLoading, setIsOrgLoading] = useState(false);
  const [isTilesLoading, setIsTilesLoading] = useState(false);
  const [overAllData,setOverAllData] = useState({}); 
  const workflowCardsMetaData = {
    dataRoomSynthesis_v2: {
      icon: "https://wkemails.blob.core.windows.net/fe-application/Data room synthesis.svg",
      lexicalFeatures: {
        source_modal: true,
        file_types_in_source_modal: true,
        add_question: true,
      },
    },
    qna: {
      icon: "https://wkemails.blob.core.windows.net/fe-application/Industry Q&A.svg",
    },
    qna_v2: {
      icon: "https://wkemails.blob.core.windows.net/fe-application/Industry Q&A.svg",
      lexicalFeatures: {
        show_articles: true,
        add_question: true,
        qna_advance_config: true,
      },
    },
    qna_v2_files: {
      icon: "https://wkemails.blob.core.windows.net/fe-application/Multi-file Q&A .svg",
      lexicalFeatures: {
        source_modal: true,
        file_types_in_source_modal: false,
        add_question: true,
      },
    },
    company_qna: {
      icon: "https://wkemails.blob.core.windows.net/fe-application/Multi-file Q&A .svg",
    },
    marketMap: {
      icon: "https://wkemails.blob.core.windows.net/fe-application/ShopIcon.svg",
    },
    market_map_v2: {
      icon: "https://wkemails.blob.core.windows.net/fe-application/ShopIcon.svg",
    },
    recentStrategy_v2: {
      icon: "https://wkemails.blob.core.windows.net/fe-application/Company primer.svg",
    },
    sectorSnapshot: {
      icon: "https://wkemails.blob.core.windows.net/fe-application/industry primer.svg",
    },
    sectorSnapshot_v3: {
      icon: "https://wkemails.blob.core.windows.net/fe-application/industry primer.svg",
      lexicalFeatures: {
        add_question: true,
      },
    },
    concise_company_primer: {
      icon: "https://wkemails.blob.core.windows.net/fe-application/premeeting brief.svg",
      lexicalFeatures: {
        add_question: false,
      },
    },
    newsletter: {
      icon: "https://wkemails.blob.core.windows.net/fe-application/Newsfeed.svg",
      url: "/dashboard/newsletter",
    },
    recentStrategy: {
      icon: "https://wkemails.blob.core.windows.net/fe-application/Company primer.svg",
    },
    dataRoomSynthesis: {
      icon: "https://wkemails.blob.core.windows.net/fe-application/Company primer.svg",
    },
    competitive_analysis: {
      icon: "https://wkemails.blob.core.windows.net/fe-application/Competitive landscape.svg",
    },
  };
  const [toggleView,setToggleView] = useState('user_org_products');

  useEffect(() => {
    setIsOrgLoading(true);
    axios
      .get("accounts/organisations/")
      .then((res) => {
        setAllOrg(
          Object.keys(res.data.organisations).map((elem) => ({ value: elem, label: elem }))
        );
        setIsOrgLoading(false);
        // setOrgFilter(Object.keys(res.data.organisations)[0]);
      })
      .catch((err) => {
        setIsOrgLoading(false);
        toast.error("Bad Network");
      });
  }, []);
  
  useEffect(() => {
    if (orgFilter !== "") {
      setIsTilesLoading(true);
      const controller = new AbortController();
      axios
        .get(`accounts/sandbox-dashboard/?org=${orgFilter}`,{
          signal: controller.signal
        })
        .then((res) => {
          setIsTilesLoading(false);
          setOverAllData(res.data)
          setOrgName(res.data.org_name)
        })
        .catch((err) => {
          // toast.error("Bad Network");
          setIsTilesLoading(false);
        });

      return () => {
        controller.abort();
      };    
    }
  }, [orgFilter]);

  useEffect(()=>{
    const data = overAllData[toggleView]?.data;
    const features = overAllData[toggleView]?.features;
      if(data && features){
      data?.forEach((tile) => (tile.tileType = "report"));
      if (features && Object.keys(features)?.length) {
        const additionalTiles = Object.values(features);
        const codes = additionalTiles.map((e) => e.code)
        const alreadyInCards = data.find((e) => codes.includes(e.code));
        if (alreadyInCards) {
          alreadyInCards.tileType = "feature";
        } else {
          additionalTiles.forEach((tile) => {
            tile.tileType = "feature";
          });
          data.push(...additionalTiles);
        }
      }

      let cardRes = [
        ...data?.sort((a, b) => a?.order_preference - b?.order_preference),
      ];

      cardRes = cardRes?.map((card) => {
        return {
          ...card,
          ...(workflowCardsMetaData[card.code] || {}),
          url: workflowCardsMetaData[card.code]?.url
            ? workflowCardsMetaData[card.code].url
            : `/dashboard/workflows/${card.code}`,
        };
      });
      // setOrgName(overAllData.org_name)
      setDashboardTiles(cardRes)
    }
  },[overAllData,toggleView])

  const [searchParams,setSearchParams] = useSearchParams()
  React.useEffect(()=>{
    console.log(searchParams)
    const userParamOrgName = searchParams.get('org_name')
    if(userParamOrgName){
      setOrgFilter(userParamOrgName)
    }
  },[])

  return (
    <div className="min-h-screen">
      <div className="p-6">
        <div className="flex justify-between mb-4">
          <div className="text-2xl font-semibold">Dashboard tiles</div>
          <div className="flex gap-3">
            <select
              className="border rounded-lg px-3 py-2 focus:ring-0 focus:outline-gray-400 disabled:bg-gray-300"
              value={toggleView}
              onChange={(e)=>{
                setToggleView(e.target.value)
              }}
              >
              <option value={'user_org_products'}>User view</option>
              <option value={'admin_org_products'}>Admin view</option>
            </select>
            <div className="border rounded-lg px-3 py-2">Org : {orgFilter}</div>{" "}
            <Select
              isLoading={isOrgLoading}
              className="w-[250px]"
              options={allOrg}
              onChange={(e) => setOrgFilter(e.value)}
            />
          </div>
        </div>
        {isTilesLoading || isOrgLoading ? (
          <div className="w-full h-full flex justify-center items-center">
            <Spinner />
          </div>
        ) : (
            <div
              className={"flex flex-col w-full max-w-[1292px] m-0 sm:m-auto"}
            >
              <div className="border rounded-lg px-3 py-2">Org : {orgName}</div>
              <div className="flex items-center gap-x-3 max-w-[1292px] m-0 self-start px-0 sm:px-4">
                {/* <WokeloIconGrayed /> */}
                {/* {userDetails.org_logo && <img
                                className="h-9 min-w-[48px] pb-2"
                                src={logoMain}
                            ></img>} */}
                <p className="text-2xl font-semibold font-Inter-Regular text-[#141416]  ">
                  Wokelo workflows
                </p>
              </div>
              <div
                className={
                  "grid grid-cols-4 max-xl:grid-cols-3 max-lg:grid-cols-2 max-md:grid-cols-2 max-sm:grid-cols-1 gap-4 w-full p-4 px-0 sm:p-4"
                }
              >
                {dashboardTiles?.filter((e) => !e.subscription.includes("Hidden"))
              .filter((e) => e.workflow_type === "wokelo").map((item, index) => {
                  return <Card item={item} key={item?.name + index} />;
                })}
              </div>

              <div className="mt-4 flex items-center gap-x-3 max-w-[1292px] m-0 self-start px-0 sm:px-4">
                {/* <WokeloIconGrayed /> */}
                {/* userDetails.org_logo && */}
                {/* {userDetails.org_logo && !errorImg && <div className='p-1 h-9 min-w-[48px]  rounded flex justify-center  items-center'>
                                <img
                                    onError={() => {
                                        setErrorImg(true);
                                    }}
                                    src={userDetails.org_logo}
                                    // src={`https://wklogo.blob.core.windows.net/logos-templates/berkshire-light.png`}
                                    className="h-auto w-10 "
                                />
                            </div>} */}

                <p className="text-2xl font-semibold font-Inter-Regular text-[#141416]  ">
                  Custom workflows
                </p>
              </div>

              <div
                className={
                  "grid grid-cols-4 max-xl:grid-cols-3 max-lg:grid-cols-2 max-md:grid-cols-2 max-sm:grid-cols-1 gap-4 w-full p-4 px-0 sm:p-4"
                }
              >
                {dashboardTiles
                  ?.filter((e) => !e.subscription.includes("Hidden")).filter((e) => e.workflow_type === "custom")
                  ?.map((item, index) => {
                    return <Card item={item} key={item?.name + index} />;
                  })}
              </div>
            </div>
        )}
      </div>
    </div>
  );
};

export default DashboardTiles;