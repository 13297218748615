// authSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  organisation:{}
};

const authSlice = createSlice({
  name: 'globalAuth',
  initialState,
  reducers: {
    setOrganisation: (state, action) => {
      state.organisation = action.payload;
    },
  },
});

export const { setOrganisation } = authSlice.actions;
export default authSlice.reducer;