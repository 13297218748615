import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import { logout } from "../store/slice/authSlice";

const useAxiosInstance = () => {
  const authDetails = useSelector((state) => state.auth);
  const localStorageData = JSON.parse(localStorage.getItem('wokelo-sandbox-auth-token'))
  
  const dispatch = useDispatch();
  const config = {
    source: {
      endpoint: process.env.REACT_APP_SOURCE_API_ENDPOINT,
    },
    test: {
      endpoint: process.env.REACT_APP_TEST_API_ENDPOINT,
    },
    prod: {
      endpoint: process.env.REACT_APP_PROD_API_ENDPOINT,
    },
  };  
  const axiosInstance = axios.create({
    baseURL: config[localStorageData?.env]?.endpoint,
    headers: {
      Authorization: `Bearer ${localStorageData?.access_token}`,
    },
  });
  axiosInstance.interceptors.response.use(res=>{
    console.log(res)
    return res
  },(err)=>{
    console.log(err.response.status)
    if(err.response.status==401){
      dispatch(logout())
    }
    return Promise.reject(err);
  })
  return axiosInstance;
};


export default useAxiosInstance;