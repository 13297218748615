import { ArrowBack } from "@mui/icons-material";
import React from "react";

const Preview = (props) => {
  const {submit,data,back,isLoading}=props;

  return (
    <div className="w-full p-3 mt-5 border rounded-lg bg-white">
      <p className="text-2xl font-semibold mb-4">Preview</p>
        <div
        className="border bg-gray-200 p-2 rounded-lg"
        >
            <p><pre>{JSON.stringify(data, null, '\t')}</pre></p>
        </div>
        <div className="flex">
        <button onClick={()=>{back()}} className="bg-[#202020] px-3 py-2 rounded text-white font-medium mt-2 text-sm "><ArrowBack/></button>
        <button 
        onClick={()=>{
            let con = window.confirm('Are you sure you want to save this ?')
            if(con){
                submit()
            }
        }}
        disabled={isLoading}
        className="bg-[#202020] disabled:cursor-not-allowed disabled:bg-slate-300 disabled:text-black px-3 py-2 rounded text-white font-medium ml-2 mt-2 text-sm ">{isLoading ? "saving...":"Save to db"}</button>
        </div>
    </div>
  );
};

export default Preview;
