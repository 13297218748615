import React, { useEffect, useState } from "react";
import { FaComment, FaSpinner, FaUserCircle } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import Input from "../../components/input";
import useAxiosInstance from "../../hooks/useAxios";
// import Preview from "./components/preview";
import { MdArrowBack, MdCheckCircle, MdDateRange, MdHourglassEmpty, MdHourglassFull, MdTypeSpecimen } from "react-icons/md";
import { useSelector } from "react-redux";
import { GoOrganization } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import {parseAbsoluteToLocal} from '@internationalized/date';
import {DatePicker} from '@adobe/react-spectrum'
// import DatePicker from "react-datepicker"
import {Button, defaultTheme, Provider} from '@adobe/react-spectrum';

const AddOrganisation = () => {
  const [formData, setFormData] = useState({
        name : "",
        label : "",
        comment: "",
        expiry_date: "2099-12-31T00:00:00Z",
        account_type: ""
      });
  const [steps, setSteps] = useState(0);
  const {organisation} = useSelector(s=>s.globalAuth)
  const [errorInName,setErrorInName] = useState('');
  const [isLoading,setIsLoading] = useState(false);
  const [formInfoLoading,setFormInfoLoading] = useState(false);
  const [accountTypeList,setAccountTypeList] = useState([])
  const [accountType_id_to_accountType_name,setAccountType_id_to_accountType_name] = useState({})  
  
  const axiosInstance = useAxiosInstance()
  const navigate = useNavigate()

  const handleSubmit = () => {
    if (formData.expiry_date == ""){
        delete formData.expiry_date
    }
    formData['account_type'] = Number(formData.account_type)

    setIsLoading(true)
    axiosInstance.post('/accounts/add_a_org/',formData).then(res=>{
        if(res.data.status === 'success'){
            toast.success(`Org is successfully created. Org Id: ${res?.data?.org_id}`)
            toast.success(`Redirecting you to edit page. Org Id: ${res?.data?.org_id}`)
            setTimeout(()=>{
                navigate(`/editOrganisation/?org_id=${res?.data?.org_id}`)
            },100)
            setIsLoading(false)
        }
    }).catch(err=>{
        console.log(err)
        setIsLoading(false)
        toast.error('something went wrong!')
    })
  }

  useEffect(()=>{
        if(Object.keys(organisation).includes(formData.name)){
            setErrorInName('A Org with Name already exists!')
        }else{
            setErrorInName('')
        }
    },[formData.name])
  
  useEffect(()=>{
    setFormInfoLoading(true)
    axiosInstance.get('/accounts/get_org_management_mappings/').then(res=>{
        setAccountTypeList(res.data?.mappings?.account_types)

        let mapping = {}
        res.data?.mappings?.account_types?.forEach(type=>{
            mapping[type.id] = type.name  
        })
        setAccountType_id_to_accountType_name(mapping)

        setFormInfoLoading(false)
    }).catch(err=>{
        setFormInfoLoading(false)
        console.log(err)
    }) 
  },[])  
    

  return (
    <div className="">
      <div className="flex justify-center">
        <div className="w-[676px] flex items-center mt-10">
          <div className="w-16 flex flex-col justify-center items-center">
            {steps === 0 ? <MdHourglassEmpty /> : <MdCheckCircle />}
            <div className="text-xs">Step 1</div>
          </div>
          <div
            className={`w-full h-1 ${
              steps === 0 ? "bg-slate-300" : "bg-black"
            }`}
          ></div>
          <div className="w-16 flex flex-col justify-center items-center">
            {steps === 0 ? <MdHourglassEmpty /> : <MdHourglassFull />}
            <div className="text-xs">Preview</div>
          </div>
        </div>
      </div>
      <div
        className={` w-full justify-center p-5 ${"items-start "} flex`}
      >
        {steps === 0 ? (
          <>
          {formInfoLoading ? <p className="mt-5 p-3">
            Loading...
          </p> :
          <div className="w-[676px] p-3 mt-5 border rounded-lg bg-white">
                <p className="text-2xl font-semibold mb-4">Add Organisation</p>
                <div className="text-sm font-semibold mb-2">Name*</div>
                {errorInName&&<p className="text-sm text-red-300">{errorInName}</p>}
                <Input
                  value={formData.name}
                  onChange={(e) => {
                    setFormData({ ...formData, name: e.target.value });
                  }}
                  placeholder="Enter the name"
                />
                
                <div className="text-sm mt-4 font-semibold mb-2">Label</div>
                <Input
                  value={formData.label}
                  onChange={(e) => {
                    setFormData({ ...formData, label: e.target.value });
                  }}
                  placeholder="Enter the label"
                />

                <div className="text-sm  mt-4  font-semibold mb-2">Comment</div>
                <Input
                  value={formData.comment}
                  onChange={(e) => {
                    setFormData({ ...formData, comment: e.target.value });
                  }}
                  placeholder="Enter the comment"
                />

                <div className="text-sm  mt-4  font-semibold mb-2">Expiry date</div>
                <Provider theme={defaultTheme}>
                <DatePicker
                  onChange={(value)=>{
                    setFormData({ ...formData, expiry_date: value.toAbsoluteString() })
                    // console.log()
                  }}  
                  defaultValue={parseAbsoluteToLocal(formData.expiry_date)}
                />
                </Provider>

            <div className="text-sm  mt-4  font-semibold mb-2">Account Type*</div>    
            <select
              value={formData.account_type}
              onChange={(e) => {
                setFormData({...formData,account_type:e.target.value});
              }}
              className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 "
              placeholder="Enter your password"
            >
              {[{id:'',name:'choose'},...accountTypeList]?.map(account_type=><option value={account_type.id}>{account_type.name}</option>)}  
            </select>

            <button
              className="bg-black disabled:bg-[#B6b6b6] disabled:cursor-not-allowed disabled:text-white text-white py-3 hover:shadow-lg  text-center w-full rounded-lg mt-4"
              type="button"
              disabled = {errorInName != "" || formData.name == "" || formData.account_type == ""}
              onClick={() => {
                setSteps(1);
              }}
            >
              Next
            </button>
          </div>}
          </>
        ) : (
            // <></>
          <Preview isLoading={isLoading} setSteps={setSteps} formData={formData} handleSubmit={handleSubmit} accountType_id_to_accountType_name={accountType_id_to_accountType_name}/>
        )}
      </div>
    </div>
  )
}

export default AddOrganisation

const Preview =({formData,setSteps,isLoading,handleSubmit,accountType_id_to_accountType_name})=>{
    return <div>
    <div className="w-[676px] p-3 mt-5 border rounded-lg bg-white">
      <p className="text-2xl font-semibold mb-4">Preview</p>
      <div className="border rounded-xl bg-gray-50 px-3 py-2">
        <p className="flex justify-between mb-2">
          <span className="flex gap-1 items-center">
            <GoOrganization />
            Organization Name{" "}
          </span>{" "}
          {formData.name}
        </p>
        <p className="flex justify-between mb-2">
          <span className="flex gap-1 items-center">
            <FaUserCircle />
            Label{" "}
          </span>{" "}
          {formData.label}
        </p>
        <p className="flex justify-between mb-2">
          <span className="flex gap-1 items-center">
            <FaComment />
            Comment{" "}
          </span>{" "}
          {formData.comment}
        </p>
        {formData?.expiry_date && <p className="flex justify-between  mb-2">
          <span className="flex gap-1 items-center">
            <MdDateRange />
            Expiry Date{" "}
          </span>{" "}
          {formData?.expiry_date}
        </p>}
        <p className="flex justify-between mb-2">
          <span className="flex gap-1 items-center">
            <MdTypeSpecimen/>
            Account Type{" "}
          </span>{" "}
          {accountType_id_to_accountType_name[formData.account_type]}
        </p>
      </div>
      <div className="flex justify-between">
        <button
          title="Go Back and Edit"
          className="flex gap-1 items-center px-2 py-1 mt-2 border rounded-lg"
          onClick={() => {
            setSteps(0);
          }}
        >
          <MdArrowBack />
        </button>

        <button
          disabled={isLoading}
          title="Go Back and Edit"
          className="flex gap-1 items-center px-2 py-1 mt-2 border border-black bg-black disabled:bg-[#b6b6b6] disabled:text-white text-white rounded-lg"
          onClick={() => {
            handleSubmit();
          }}
        >
          {!isLoading ? "Make this Org" : "Loading...."}
        </button>
      </div>
    </div>
  </div>
}