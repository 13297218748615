import React, { useState } from "react";
import Input from "../../../components/input";
import { ModalComp } from "../../../components/Modal";
import ReactSelect from "react-select";
import { ContientList, CountryList } from "../../../helper";
import { MdSearch } from "react-icons/md";

const Step1forms = (props) => {
  const { formData, setFormData, reset, nextPage } = props;
  const [countryModal,setCountryModal] = useState(false);
  const [continentModal,setContinentModal] = useState(false);
  
  return (
    <div className="w-full p-3 mt-5 border rounded-lg bg-white">
      <ModalComp
      width={400}
      open={countryModal}
      onClose={() => {
          setCountryModal(false);
      }}
      >
      <div className="p-4 rounded-xl bg-white h-[400px]  overflow-y-auto">
        Selected Country : {formData.country}
        <ReactSelect
              className="w-full"
              options={CountryList.map(elem=>({label:elem,value:elem}))}
              onChange={(e) => {
                setFormData({ ...formData, country: e.value });
                setCountryModal(false);
              }}
            />
        </div>
      </ModalComp>

      <ModalComp
      width={400}
      open={continentModal}
      onClose={() => {
          setContinentModal(false);
      }}
      >
      <div className="p-4 rounded-xl bg-white h-[400px] overflow-y-auto">
        Selected Continent : {formData.continent}
        <ReactSelect
              className="w-full"
              options={ContientList.map(elem=>({label:elem,value:elem}))}
              onChange={(e) => {
                setFormData({ ...formData, continent: e.value });
                setContinentModal(false);
              }}
            />
        </div>
      </ModalComp>        

      <p className="text-2xl font-semibold mb-4">Add Company</p>

      <div className="grid grid-cols-3 gap-4 mb-3">
        <div>
          <div className="text-sm font-semibold mb-2">Permalink*</div>
          <Input
            value={formData.permalink}
            onChange={(e) => {
              setFormData({ ...formData, permalink: e.target.value });
            }}
            placeholder="Enter the Permalink"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">Name*</div>
          <Input
            value={formData.name}
            onChange={(e) => {
              setFormData({ ...formData, name: e.target.value });
            }}
            placeholder="Enter the name"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">Headquarter</div>
          <Input
            value={formData.hq}
            onChange={(e) => {
              setFormData({ ...formData, hq: e.target.value });
            }}
            placeholder="Enter the headquarter"
          />
        </div>
      </div>

      <div className="mb-2">
        <div className="text-sm font-semibold mb-2">Short description*</div>
        <textarea
          className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400"
          value={formData.short_description}
          onChange={(e) => {
            setFormData({ ...formData, short_description: e.target.value });
          }}
          placeholder="Enter the short description"
        />
      </div>

      <div className="mb-2">
        <div className="text-sm font-semibold mb-2">Long description*</div>
        <textarea
          className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400"
          value={formData.long_description}
          onChange={(e) => {
            setFormData({ ...formData, long_description: e.target.value });
          }}
          placeholder="Enter the long description"
        />
      </div>

      <div className="grid grid-cols-3 gap-4 mb-3">
        <div>
          <div className="text-sm font-semibold mb-2">Industries*</div>
          <Input
            value={formData.industries}
            onChange={(e) => {
              setFormData({ ...formData, industries: e.target.value });
            }}
            placeholder="Enter the industries"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2 flex gap-x-2 items-center ">Continent <div className="p-1 rounded-md cursor-pointer bg-slate-200"><MdSearch onClick={()=>{setContinentModal(true)}}/></div></div>
          <Input
            value={formData.continent}
            onChange={(e) => {
              setFormData({ ...formData, continent: e.target.value });
            }}
            placeholder="Enter the continent"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2 flex gap-x-2 items-center ">Country <div className="p-1 rounded-md cursor-pointer bg-slate-200"><MdSearch onClick={()=>{setCountryModal(true)}}/></div></div>
          <Input
            value={formData.country}
            onChange={(e) => {
              setFormData({ ...formData, country: e.target.value });
            }}
            placeholder="Enter the Country"
          />
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4 mb-3">
        <div>
          <div className="text-sm font-semibold mb-2">Founded Year</div>
          <Input
            type={'year'}
            value={formData.founded_year}
            onChange={(e) => {
              setFormData({ ...formData, founded_year: e.target.value });
            }}
            placeholder="Enter the founded year"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">IPO Status</div>
          <select
            className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 disabled:bg-gray-300"
            value={formData.ipo_status}
            onChange={(e) => {
              setFormData({ ...formData, ipo_status: e.target.value });
            }}
            placeholder="Enter the IPO Status"
          >
            <option value={''}>choose</option>
            <option value={'public'}>public</option>
            <option value={'private'}>private</option>
            <option value={'closed'}>closed</option>
          </select>
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">Active Status (True Value)-({`${formData.active_status}`})</div>
          <Input
            type={"checkbox"}
            // value={formData.active_status}
            checked={formData.active_status}
            onChange={(e) => {
              formData.active_status ? 
              setFormData({ ...formData, active_status: false })
              :
              setFormData({ ...formData, active_status: true })
            }}
            placeholder="Enter the active status"
          />
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4 mb-3">
        <div>
          <div className="text-sm font-semibold mb-2">Total funding</div>
          <Input
            disabled={true}
            type="Number"
            value={formData.total_funding}
            onChange={(e) => {
              setFormData({ ...formData, total_funding: e.target.value });
            }}
            placeholder="Enter the total funding"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">Last Funding Amount</div>
          <Input
            disabled={true}
            type="Number"
            value={formData.last_funding_amount}
            onChange={(e) => {
              setFormData({
                ...formData,
                last_funding_amount: e.target.value,
              });
            }}
            placeholder="Enter the Last funding amount"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">Last Funding Date</div>
          <Input
            disabled={true}
            value={formData.last_funding_date}
            onChange={(e) => {
              setFormData({
                ...formData,
                last_funding_date: e.target.value,
              });
            }}
            placeholder="Enter the Last Funding Date"
          />
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4 mb-3">
        <div>
          <div className="text-sm font-semibold mb-2">Website URL*</div>
          <Input
            value={formData.website_url}
            onChange={(e) => {
              setFormData({ ...formData, website_url: e.target.value });
            }}
            placeholder="Enter the website Url"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">
            Number of acquisitions
          </div>
          <Input
            type="Number"
            disabled={true}
            value={formData.number_of_acquisitions}
            onChange={(e) => {
              setFormData({
                ...formData,
                number_of_acquisitions: e.target.value,
              });
            }}
            placeholder="Enter the number of acquisitions"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">
            Number of investments
          </div>
          <Input
            type="Number"
            disabled={true}
            value={formData.number_of_investments}
            onChange={(e) => {
              setFormData({
                ...formData,
                number_of_investments: e.target.value,
              });
            }}
            placeholder="Enter the Number of investments"
          />
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4 mb-3">
        <div>
          <div className="text-sm font-semibold mb-2">Rank</div>
          <Input
            type="Number"
            value={formData.rank}
            onChange={(e) => {
              setFormData({ ...formData, rank: e.target.value });
            }}
            placeholder="Enter the Rank"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">FC name</div>
          <Input
            disabled={true}
            value={formData.fc_name}
            onChange={(e) => {
              setFormData({ ...formData, fc_name: e.target.value });
            }}
            placeholder="Enter the FC name"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">Flag wokelo select (True Value)-({`${formData.flag_wokelo_select}`})</div>
          <Input
            checked={formData.flag_wokelo_select}
            type={'checkbox'}
            onChange={(e) => {
              formData.flag_wokelo_select?
              setFormData({
                ...formData,
                flag_wokelo_select: false,
              })
              :
              setFormData({
                ...formData,
                flag_wokelo_select: true,
              });
            }}
            placeholder="Enter the Flag wokelo select"
          />
        </div>
      </div>
      <div className="flex justify-end w-full">
        <button
          className="border-black px-3 py-1 rounded  mr-2 font-semibold border-2"
          onClick={() => {
            let confirm = window.confirm("Are you sure you want to reset ?");
            if (confirm) {
              reset();
            }
          }}
        >
          Reset
        </button>
        <button
          disabled={
            formData.permalink === "" ||
            formData.name === "" ||
            formData.short_description === "" ||
            formData.long_description === "" ||
            formData.industries === "" ||
            formData.website_url === ""
          }
          onClick={() => nextPage()}
          className="border-black disabled:bg-[#b6b6b6] disabled:text-white bg-black text-white px-3 py-1 rounded  mr-2 font-semibold border-2"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Step1forms;
