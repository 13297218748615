import React from "react";
import { MdEdit, MdSearch } from "react-icons/md";
import { ModalComp } from "../../components/Modal";
import { toast } from "react-toastify";
import useAxiosInstance from "../../hooks/useAxios";
import { Spinner } from "flowbite-react";

const Layouts = () => {
  const [data, setData] = React.useState({});
  const axios = useAxiosInstance();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  React.useEffect(() => {
    setIsLoading(true);
    axios.get("/accounts/sandbox_layout_data_table_wise/").then((res) => {
      setIsLoading(false);
      setData(res.data);
      set_all_layout_options(res.data.all_layout);
    });
  }, []);
  const handleSave = () => {
    setIsSaving(true);
    axios
      .post("/accounts/update_org_layouts/", {
        org_name: perticularData.org_name,
        layouts: perticularData.layouts_data,
        locked_layouts: perticularData.locked_layout_data,
      })
      .then(() => {
        setIsSaving(false);
        setOpenModal(false);
        setPerticularData({});
        setIsLoading(true);
        axios
          .get("/accounts/sandbox_layout_data_table_wise/")
          .then((res) => {
            toast.success("Save successfully");
            setIsLoading(false);
            setData(res.data);
          })
          .catch((err) => {
            setIsSaving(false)
            toast.error("Loading updated data failed");
            setIsLoading(false);
          });
      });
  };
  const [all_layout_options, set_all_layout_options] = React.useState([]);
  const [companySearch, setCompanySearch] = React.useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [perticularData, setPerticularData] = React.useState({});
  const [layout_option, set_layout_option] = React.useState("layouts");
  const [layoutSearch, setLayoutSearch] = React.useState("");
  return (
    <>
      <ModalComp
        width={800}
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <div className="p-4 rounded-xl bg-white max-h-[800px] overflow-y-auto">
          <p className="text-base font-semibold">{perticularData.org_name}</p>
          <div className="flex justify-between items-center">
            <div className="flex gap-2">
              <p
                onClick={() => {
                  set_layout_option("layouts");
                }}
                className={`text-sm  border-t-2 border-l-2 border-r-2 rounded-xl rounded-b-none max-w-max px-2 py-2 ${
                  layout_option === "layouts" ? "bg-gray-300" : ""
                } font-semibold cursor-pointer mt-3`}
              >
                Layouts
              </p>
              <p
                onClick={() => {
                  set_layout_option("locked_layouts");
                }}
                className={`text-sm  border-t-2 border-l-2 border-r-2 rounded-xl rounded-b-none max-w-max px-2 py-2 ${
                  layout_option === "locked_layouts" ? "bg-gray-300" : ""
                } font-semibold cursor-pointer mt-3`}
              >
                Locked Layouts
              </p>
            </div>
            <div className="flex items-center">
              <input
                value={layoutSearch}
                onChange={(e) => {
                  setLayoutSearch(e.target.value);
                }}
                className="border rounded focus:ring-0 focus:outline-none pl-2 pr-6 -mr-[16px] py-1"
              />
              <MdSearch className="-translate-x-2" />
            </div>
          </div>
          {layout_option === "layouts" && (
            <div className="overflow-y-auto grid grid-cols-2 gap-2 w-full h-[300px] mt-3">
              {all_layout_options
                ?.filter((item) =>
                  item.label.toLowerCase().includes(layoutSearch.toLowerCase())
                )
                ?.map((layout) => {
                  return (
                    <div className="relative">
                      <input
                        type="checkbox"
                        onClick={() => {
                          perticularData.layouts_data?.find(
                            (item) => item.identifier === layout.identifier
                          )
                            ? setPerticularData((prev) => ({
                                ...prev,
                                layouts_data: prev.layouts_data.filter(
                                  (item) =>
                                    item.identifier !== layout.identifier
                                ),
                              }))
                            : setPerticularData((prev) => ({
                                ...prev,
                                layouts_data: [
                                  ...prev.layouts_data,
                                  layout,
                                ],
                              }));
                        }}
                        checked={perticularData.layouts_data?.find(
                          (item) => item.identifier === layout.identifier
                        )}
                        className="absolute top-1 left-1"
                      />
                      <div className="p-2 rounded-lg bg-white border mb-3">
                        <div className="flex w-full justify-between font-medium text-sm">
                          <p>Label</p> {layout.label}
                        </div>
                        <div className="flex w-full justify-between font-medium text-sm">
                          <p>Identifier</p> {layout.identifier}
                        </div>
                        <div className="flex w-full justify-between font-medium text-sm">
                          <p>Status</p> {layout.status}
                        </div>
                        <div className="flex w-full justify-between font-medium text-sm">
                          <p>Created by</p> {layout.created_by}
                        </div>
                        <div className="flex w-full justify-between font-medium text-sm">
                          <p>Image Url</p> {layout.image_url}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
          {layout_option === "locked_layouts" && (
            <div className="overflow-y-auto grid grid-cols-2 gap-2 w-full h-[300px] mt-3">
              {all_layout_options
                ?.filter((item) =>
                  item.label.toLowerCase().includes(layoutSearch.toLowerCase())
                )
                ?.map((layout) => {
                  return (
                    <div className="relative">
                      <input
                        type="checkbox"
                        checked={perticularData.locked_layout_data?.find(
                          (item) => item.identifier === layout.identifier
                        )}
                        onClick={() => {
                          perticularData.locked_layout_data?.find(
                            (item) => item.identifier === layout.identifier
                          )
                            ? setPerticularData((prev) => ({
                                ...prev,
                                locked_layout_data:
                                  prev.locked_layout_data.filter(
                                    (item) =>
                                      item.identifier !== layout.identifier
                                  ),
                              }))
                            : setPerticularData((prev) => ({
                                ...prev,
                                locked_layout_data: [
                                  ...prev.locked_layout_data,
                                  layout,
                                ],
                              }));
                        }}
                        className="absolute top-1 left-1"
                      />
                      <div className="p-2 rounded-lg bg-white border mb-3">
                        <div className="flex w-full justify-between font-medium text-sm">
                          <p>Label</p> {layout.label}
                        </div>
                        <div className="flex w-full justify-between font-medium text-sm">
                          <p>Identifier</p> {layout.identifier}
                        </div>
                        <div className="flex w-full justify-between font-medium text-sm">
                          <p>Status</p> {layout.status}
                        </div>
                        <div className="flex w-full justify-between font-medium text-sm">
                          <p>Created by</p> {layout.created_by}
                        </div>
                        <div className="flex w-full justify-between font-medium text-sm">
                          <p>Image Url</p> {layout.image_url}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
          <div className="flex justify-end">
            {!isSaving ? (
              <button
                disabled={isSaving}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSave();
                }}
                className="px-3 py-1 rounded-md disabled:bg-[#b6b6b6] bg-black text-white"
              >
                Save
              </button>
            ) : (
              <button
                disabled={isSaving}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSave();
                }}
                className="px-3 py-1 rounded-md disabled:bg-[#b6b6b6] bg-black text-white"
              >
                <Spinner />
              </button>
            )}
          </div>
        </div>
      </ModalComp>
      {isLoading ? (
        <div className="h-screen w-full flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <div className="h-screen overflow-y-auto">
          <div className="p-6">
            <div className="flex justify-between items-center w-full">
              <div className="text-2xl font-semibold">Layouts</div>
              <div className="flex items-center">
                <input
                  value={companySearch}
                  onChange={(e) => setCompanySearch(e.target.value)}
                  className="border rounded focus:ring-0 focus:outline-none pl-2 pr-6 -mr-[16px] py-1"
                />
                <MdSearch className="-translate-x-2" />
              </div>
            </div>
            <div className="mt-4">
              {data?.data
                ?.filter((item) =>
                  item.org_name
                    .toLowerCase()
                    .includes(companySearch.toLowerCase())
                )
                .map((elem) => {
                  return (
                    <div className="bg-white mb-3 rounded-md border p-4">
                      <div className="flex justify-between w-full mb-2">
                        <p className="text-base font-semibold tracking-tight text-black">
                          {elem.org_name}
                        </p>
                        <MdEdit
                          onClick={() => {
                            setPerticularData(elem);
                            setOpenModal(true);
                          }}
                          className="cursor-pointer"
                        />
                      </div>
                      <div className="flex gap-x-2">
                        <div className="w-[50%] bg-gray-200 p-3 rounded">
                          <div className="text-sm font-medium border-b border-white mb-3 pb-1">
                            Layouts
                          </div>
                          <div className="max-h-[340px] overflow-y-auto">
                            {elem.layouts_data.length == 0 ? (
                              <p className="text-center text-sm font-medium">
                                No Layouts
                              </p>
                            ) : (
                              elem.layouts_data.map((layout) => {
                                return (
                                  <div className="p-2 rounded-lg bg-white border mb-3">
                                    <div className="flex w-full justify-between font-medium text-sm">
                                      <p>Label</p> {layout.label}
                                    </div>
                                    <div className="flex w-full justify-between font-medium text-sm">
                                      <p>Identifier</p> {layout.identifier}
                                    </div>
                                    <div className="flex w-full justify-between font-medium text-sm">
                                      <p>Status</p> {layout.status}
                                    </div>
                                    <div className="flex w-full justify-between font-medium text-sm">
                                      <p>Created by</p> {layout.created_by}
                                    </div>
                                    <div className="flex w-full justify-between font-medium text-sm">
                                      <p>Image Url</p> {layout.image_url}
                                    </div>
                                  </div>
                                );
                              })
                            )}
                          </div>
                        </div>
                        <div className="w-[50%] bg-gray-200 p-3 rounded">
                          <div className="text-sm font-medium border-b border-white mb-3 pb-1">
                            Locked Layouts
                          </div>
                          <div>
                            {elem.locked_layout_data.length == 0 ? (
                              <p className="text-center text-sm font-medium">
                                No Locked Layouts
                              </p>
                            ) : (
                              elem.locked_layout_data.map((layout) => {
                                return (
                                  <div className="p-2 rounded-lg bg-white border mb-3">
                                    <div className="flex w-full justify-between font-medium text-sm">
                                      <p>Label</p> {layout.label}
                                    </div>
                                    <div className="flex w-full justify-between font-medium text-sm">
                                      <p>Identifier</p> {layout.identifier}
                                    </div>
                                    <div className="flex w-full justify-between font-medium text-sm">
                                      <p>Status</p> {layout.status}
                                    </div>
                                    <div className="flex w-full justify-between font-medium text-sm">
                                      <p>Created by</p> {layout.created_by}
                                    </div>
                                    <div className="flex w-full justify-between font-medium text-sm">
                                      <p>Image Url</p> {layout.image_url}
                                    </div>
                                  </div>
                                );
                              })
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Layouts;
