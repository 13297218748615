import React, { useEffect, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import Input from "../../components/input";
import Step1forms from "./components/step1forms";
import Step2forms from "./components/step2form";
import Preview from "./components/preview";
import { v4 as uuid } from "uuid";

import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import extractDomain from 'extract-domain';
import axios from "axios";

const AddCompany = () => {
  const [fundingRoundArray, setFundingRounfArray] = React.useState([]);
  const [sub_orgsArray, setsub_orgsArray] = React.useState([]);
  const [list_acquisitionsArray, setlist_acquisitionsArray] = React.useState(
    []
  );
  const authDetails = useSelector((state) => state.auth);
  const [listInvestmenttsArray, setlistInvestmenttsArray] = React.useState([]);
  const [list_executives,setlist_executives] = React.useState([]);
  
  const [steps, setSteps] = React.useState(0);
  const initialState = {
    permalink: "",
    name: "",
    short_description: "",
    long_description: "",
    hq: "",
    industries: "",
    continent: "",
    country: "",
    founded_year: "",
    ipo_status: "",
    active_status: false,
    total_funding: 0,
    last_funding_amount: 0,
    last_funding_date: "",
    website_url: "",
    number_of_acquisitions: 0,
    number_of_investments: 0,
    rank: 999999,
    extra_info: {},
    // "dt_createdOn": null,
    // "dt_lastUpdatedOn": null,
    fc_name: "",
    flag_wokelo_select: false,
  };

  const [formData, setFormData] = React.useState(initialState);

  const extraDetailsInitialState = {
    hq: "",
    url: "",
    logo: "",
    roles: "",
    primary_role :"",
    social_links : {
      twitter_url : "",
      facebook_url : "",
      linkedin_url : "",
    },
    domain:"",
    continent:'',
    uuid: uuid(),
    cb_rank: 0,
    website: "",
    founders: [],
    org_name: "",
    sub_orgs: [],
    permalink: "",
    industries: [],
    ipo_status: "",
    legal_name: "",
    data_source: "",
    description: "",
    company_type: "",
    related_hubs: "",
    stock_symbol: "",
    year_founded: "",
    num_employees: "",
    num_investors: 0,
    funding_rounds: [],
    list_investors: [],
    num_investments: 0,
    list_investments: [],
    num_acquisitions: 0,
    operating_status: "",
    last_funding_date: "",
    list_acquisitions: [],
    short_description: "",
    similar_companies: [],
    last_funding_round: "",
    num_funding_rounds: 0,
    last_funding_amount: 0,
    amount_funding_total: 0,
    location_identifier: "",
    revenue_range : "",
    stock_exchange : "",
    old_ticker_name : "",
    list_executives:[]
  };

  const [extraDetailsformData, setExtraDetailsFormData] = React.useState(
    extraDetailsInitialState
  );

  const [finalData, setFinalData] = React.useState({});
  const [isLoading,setIsLoading] = React.useState(false)
  
  const submit = async() => {
    // delete sub_orgsArray['id']
    // delete fundingRoundArray['id']
    // delete listInvestmenttsArray['id']
    // delete list_acquisitionsArray['id']
    let  last_funding_amount = formData.last_funding_amount
    if(last_funding_amount == undefined){
      last_funding_amount = 0
    }
    let last_funding_date = formData.last_funding_date
    if(last_funding_date == undefined){
      last_funding_date = ""
    }
    let last_funding_round = extraDetailsformData.last_funding_round
    if(last_funding_round == undefined){
      last_funding_round = ""
    }
    const data = {
      ...formData,
      website: formData.website_url,
      founded_year: formData.founded_year,
      total_funding: Number(formData.total_funding),
      last_funding_amount: last_funding_amount,
      number_of_acquisitions: Number(formData.number_of_acquisitions),
      number_of_investments: Number(formData.number_of_investments),
      last_funding_date : last_funding_date,
      rank: Number(formData.rank),
      extra_info: {
        ...extraDetailsformData,
        
        continent:formData.continent,
        cb_rank:Number(extraDetailsformData.cb_rank),
        sub_orgs: sub_orgsArray.map((obj) => {
          delete obj.id;
          return obj;
        }),
        funding_rounds: fundingRoundArray.map((obj) => {
          const listOfInvestors = obj?.list_investors;
          if (listOfInvestors) {
            listOfInvestors.map((obj) => {
              delete obj.id;
              return obj;
            });
          }
          delete obj.id;
          return obj;
        }),
        list_investments: listInvestmenttsArray.map((obj) => {
          delete obj.id;
          return obj;
        }),
        list_acquisitions: list_acquisitionsArray.map((obj) => {
          delete obj.id;
          return obj;
        }),
        list_executives: list_executives.map((obj) => {
          delete obj.id;
          return obj;
        }),
        last_funding_amount : last_funding_amount,
        num_investors: extraDetailsformData.list_investors.length,
        last_funding_date : last_funding_date,
        last_funding_round : last_funding_round,
        operating_status : extraDetailsformData.operating_status?.trim()?.toLowerCase(),
        company_type : extraDetailsformData.company_type?.trim()?.toLowerCase(),
        industries : extraDetailsformData.industries.map(elem=>{return elem.trim()}),
        founders : extraDetailsformData.founders.map(elem=>{return elem.trim()}),
        active_status : formData.active_status,
        country : formData.country,
      },
    };
    // setFinalData(data);
    setIsLoading(true)
    
    if(last_funding_date == ""){
      delete data.last_funding_date
    }
    console.log(data)

    const config = {
      source: {
        endpoint: process.env.REACT_APP_SOURCE_API_ENDPOINT,
      },
      test: {
        endpoint: process.env.REACT_APP_TEST_API_ENDPOINT,
      },
      prod: {
        endpoint: process.env.REACT_APP_PROD_API_ENDPOINT,
      },
    };
  
  await axios.post(config['source']?.endpoint+'/assets/create_company/',{...data,email:authDetails.username},).then(res=>{
      setIsLoading(false)
      if(res.data.status==='success'){toast.success('sucessfully added.')}else{toast.error('This company already exists.')}
  }).catch(err=>{
      setIsLoading(false)
      toast.error('Something went wrong')
  })

  };

  const make_data = async() => {
    // delete sub_orgsArray['id']
    // delete fundingRoundArray['id']
    // delete listInvestmenttsArray['id']
    // delete list_acquisitionsArray['id']
    let  last_funding_amount = formData.last_funding_amount
    if(last_funding_amount == undefined){
      last_funding_amount = 0
    }
    let last_funding_date = formData.last_funding_date
    if(last_funding_date == undefined){
      last_funding_date = ""
    }
    let last_funding_round = extraDetailsformData.last_funding_round
    if(last_funding_round == undefined){
      last_funding_round = ""
    }
    
    const data = {
      ...formData,
      website: formData.website_url,
      founded_year: formData.founded_year,
      total_funding: Number(formData.total_funding),
      last_funding_amount: last_funding_amount,
      number_of_acquisitions: Number(formData.number_of_acquisitions),
      number_of_investments: Number(formData.number_of_investments),
      last_funding_date : last_funding_date,
      rank: Number(formData.rank),
      extra_info: {
        ...extraDetailsformData,
        
        continent:formData.continent,
        cb_rank:Number(extraDetailsformData.cb_rank),
        sub_orgs: sub_orgsArray.map((obj) => {
          delete obj.id;
          return obj;
        }),
        funding_rounds: fundingRoundArray.map((obj) => {
          const listOfInvestors = obj?.list_investors;
          if (listOfInvestors) {
            listOfInvestors.map((obj) => {
              delete obj.id;
              return obj;
            });
          }
          delete obj.id;
          return obj;
        }),
        list_investments: listInvestmenttsArray.map((obj) => {
          delete obj.id;
          return obj;
        }),
        list_acquisitions: list_acquisitionsArray.map((obj) => {
          delete obj.id;
          return obj;
        }),
        list_executives: list_executives.map((obj) => {
          delete obj.id;
          return obj;
        }),
        last_funding_amount : last_funding_amount,
        num_investors: extraDetailsformData.list_investors.length,
        last_funding_date : last_funding_date,
        last_funding_round : last_funding_round,
        operating_status : extraDetailsformData.operating_status?.trim()?.toLowerCase(),
        company_type : extraDetailsformData.company_type?.trim()?.toLowerCase(),
        industries : extraDetailsformData.industries.map(elem=>{return elem.trim()}),
        founders : extraDetailsformData.founders.map(elem=>{return elem.trim()}),
        active_status : formData.active_status,
        country : formData.country,
      },
    };
    if(last_funding_date == ""){
      delete data.last_funding_date
    }
    // setFinalData(data);
    // setIsLoading(true)
    console.log(data)
    setFinalData(data)
  };

  React.useEffect(() => {
    setFormData({
      ...formData,
      fc_name: formData.name.charAt(0).toUpperCase(),
    });
  }, [formData.name]);

  const reset = () => {
    setFormData(initialState);
  };

  useEffect(() => {
    let storing_industies = formData.industries
    if(storing_industies.length == 0){
      storing_industies = []
    }else{
      storing_industies = formData.industries.split(',')
    }
    setExtraDetailsFormData({
      ...extraDetailsformData,
      org_name:formData.name,
      legal_name:formData.name,
      industries : storing_industies,
      website: formData.website_url,
      hq: formData.hq,
      cb_rank: formData.rank,
      permalink: formData.permalink,
      ipo_status: formData.ipo_status,
      description: formData.long_description,
      short_description: formData.short_description,
      last_funding_amount: formData.last_funding_amount,
      last_funding_date: formData.last_funding_date,
      website_url: formData.website_url,
      num_acquisitions: formData.number_of_acquisitions,
      num_investments: formData.number_of_investments,
      year_founded: formData.founded_year,
      domain : extractDomain(formData.website_url)
    });
  }, [formData]);

  useEffect(() => {
    let datesArray = fundingRoundArray.map((round) => new Date(round.date));
    let maxDate = new Date(Math.max(...datesArray));
    let data = fundingRoundArray.find(
      (item) => new Date(item.date).getTime() === maxDate.getTime()
    );
    let amount_funding_total = 0;
    fundingRoundArray.forEach((elem) => {
      amount_funding_total += elem.amount_raised_usd;
    });
    const listOfInvestors____ = (fundingRoundArray) => {
      let newData = [];
      fundingRoundArray.forEach((round) => {
        round.list_investors.forEach((investor) => {
          const existingInvestor = newData.find(
            (elem) => elem.permalink === investor.permalink
          );
          if (existingInvestor) {
            existingInvestor.amount += Number(investor.amount);
          } else {
            newData.push({ ...investor, amount: Number(investor.amount) }); // Clone the investor object before pushing
          }
        });
      });
      return newData.sort((a, b) => b.amount - a.amount).slice(0, 5);
    };
    const listOfInvestors____count = (fundingRoundArray) => {
      let newData = [];
      fundingRoundArray.forEach((round) => {
        round.list_investors.forEach((investor) => {
          const existingInvestor = newData.find(
            (elem) => elem.permalink === investor.permalink
          );
          if (existingInvestor) {
            existingInvestor.amount += Number(investor.amount);
          } else {
            newData.push({ ...investor, amount: Number(investor.amount) }); // Clone the investor object before pushing
          }
        });
      });
      return newData.sort((a, b) => b.amount - a.amount).length
    };
    setFormData((prev) => ({
      ...prev,
      last_funding_amount: data?.amount_raised_usd,
      last_funding_date: data?.date,
      total_funding: amount_funding_total,
    }));
    setExtraDetailsFormData((prev) => ({
      ...prev,
      last_funding_round: data?.funding_round,
      num_funding_rounds: fundingRoundArray.length,
      amount_funding_total: amount_funding_total,
      list_investors:listOfInvestors____(fundingRoundArray).map(elem=>elem.name),
      // num_investors:listOfInvestors____count(fundingRoundArray)
    }));
  }, [fundingRoundArray]);

  useEffect(()=>{
    setFormData(prev=>({
      ...prev,
      number_of_acquisitions:list_acquisitionsArray.length,
      number_of_investments:listInvestmenttsArray.length
    }))
  },[listInvestmenttsArray,list_acquisitionsArray])

  useEffect(()=>{
    let all_inputs = (document.querySelectorAll('input'))
    all_inputs.forEach(elem=>{
          if(elem.value === ''){
            elem.style.borderColor = ''
          }else{
            elem.style.borderColor = 'inherit'
          }
    })
  },[formData,extraDetailsformData,steps])

  const [org_uuids,setorg_uuids] = useState({})
  useEffect(()=>{
    console.log(org_uuids)
  },[
    org_uuids
  ])
  return (
    <div
      id='company_fill_form'
      className={`h-screen w-full justify-center p-5  overflow-y-auto ${"items-start "} flex`}
    >
      {steps === 0 ? (
        <Step1forms
          setFormData={setFormData}
          formData={formData}
          reset={reset}
          nextPage={() => {
            setSteps(1);
          }}
        />
      ) : steps === 1 ? (
        <>
          <Step2forms
            org_uuids = {org_uuids}
            setorg_uuids = {setorg_uuids}
            fundingRoundArray={fundingRoundArray}
            setFundingRounfArray={setFundingRounfArray}
            sub_orgsArray={sub_orgsArray}
            setsub_orgsArray={setsub_orgsArray}
            list_acquisitionsArray={list_acquisitionsArray}
            setlist_acquisitionsArray={setlist_acquisitionsArray}
            listInvestmenttsArray={listInvestmenttsArray}
            setlistInvestmenttsArray={setlistInvestmenttsArray}
            setFormData={setExtraDetailsFormData}
            formData={extraDetailsformData}
            reset={() => [setExtraDetailsFormData(extraDetailsInitialState)]}
            submit={() => {
              make_data()
              setSteps(2);
            }}
            isLoading={isLoading}
            list_executives={list_executives}
            setlist_executives={setlist_executives}
            nextPage={() => {
              setSteps(2);
            }}
            // fillInfoFromPreviousStep={fillInfoFromPreviousStep}
            back={() => {
              setSteps(0);
            }}
          />
        </>
      ) : (
        <>
          <Preview 
            isLoading={isLoading}
            data={finalData}  
            back={() => {
              setSteps(1);
            }}
            submit={submit}/>
        </>
      )}
    </div>
  );
};

export default AddCompany;
