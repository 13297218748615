import React from 'react'
import { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Input from '../../components/input';
import { Spinner } from 'flowbite-react';

const CompanyMigrations = () => {
    //states
    const [permalink, setPermalink] = useState("") 
    const [migrateFrom, setMigrateFrom] = useState('')
    const [migrateFromCheckLoading,setMigrateFromCheckLoading] = useState(false)
    const [migrateFromResult,setMigrateFromResult] = useState("");
    const [migrateTo, setMigrateTo] = useState('')
    const [migrateToCheckLoading,setMigrateToCheckLoading] = useState(false)
    const [migrateToResult,setMigrateToResult] = useState("");
    const [migrationLoading,setMigrateLoading] = useState(false);
    const [companyData,setCompanyData] = useState({});
    
    const authDetails = useSelector((state) => state.auth);


    //functions
    const MigrateCheck = async(env,setter,loadingSetter,permalink,isFirst=false) => {
        loadingSetter(true);
        const config = {
            source: {
              endpoint: process.env.REACT_APP_SOURCE_API_ENDPOINT,
            },
            test: {
              endpoint: process.env.REACT_APP_TEST_API_ENDPOINT,
            },
            prod: {
              endpoint: process.env.REACT_APP_PROD_API_ENDPOINT,
            },
          };

        await axios.post(config[env]?.endpoint+'/assets/do_company_exists/',{permalink:permalink,email:authDetails.username}).then(res=>{
            setter(res.data.do_exists)
            if(isFirst){
              setCompanyData(res.data.compnay_data)
            }
            loadingSetter(false)
        }).catch(err=>{
            loadingSetter(false)
            toast.error('Something went wrong!')
        })
    }

    return (
    <div className="min-h-screen">
       <div className="flex justify-center pt-10">
       <div className="w-[676px] flex bg-white border rounded-lg items-center">  
    <div className="p-6 w-full">
    <div className="mb-4">
      <div className="text-2xl font-semibold mb-4">Company Migrations</div>
      <div>
      <div className="text-sm font-semibold mb-2">Permalink*</div>
      <Input
        value={permalink}
        onChange={(e) => {
            setPermalink(e.target.value);
        }}
        placeholder="Enter the Permalink"
      />

      {permalink&&<><div className="text-sm font-semibold mb-2 mt-4">Migrate From*</div>
      <select
        className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 disabled:bg-gray-300"
        value={migrateFrom}
        onChange={(e) => {
            setMigrateFrom(e.target.value);
            if(e.target.value!==''){
                MigrateCheck(e.target.value,setMigrateFromResult,setMigrateFromCheckLoading,permalink,true)
            }    
        }}
        placeholder="Select Migrate From"
      >
        <option value={""}>choose</option>
        <option value={"source"}>source</option>
        <option value={"test"}>test</option>
        <option value={"prod"}>prod</option>
      </select></>}
       
      {migrateFromCheckLoading && <p className='mt-2 flex flex-row-reverse'>Checking if permalink exists in the {migrateFrom} env.....<Spinner className='mr-2'/></p>} 
      {(!migrateFromCheckLoading && !migrateFromResult ) && <p className='mt-2 flex text-red-300 flex-row-reverse'>This permalink doesn't exists in the {migrateFrom} env</p>} 
      
      {(migrateFrom && !migrateFromCheckLoading && migrateFromResult)&&  
      <div>  
      <div className="text-sm font-semibold mt-4 mb-2">Migrate to*</div>  
      <select
        className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 disabled:bg-gray-300"
        value={migrateTo}
        onChange={(e) => {
            setMigrateTo(e.target.value);
            if(e.target.value!==''){
              MigrateCheck(e.target.value,setMigrateToResult,setMigrateToCheckLoading,permalink)
          }
        }}
        placeholder="Select Migrate From"
      >
        <option value={""}>choose</option>
        <option value={"source"}>source</option>
        <option value={"test"}>test</option>
        <option value={"prod"}>prod</option>
      </select> 
      {migrateToCheckLoading && <p className='mt-2 flex flex-row-reverse'>Checking if permalink doesn't exists in the {migrateTo} env.....<Spinner className='mr-2'/></p>} 
      {(!migrateToCheckLoading && migrateToResult ) && <p className='mt-2 flex text-red-300 flex-row-reverse'>This permalink does exists in the {migrateFrom} env</p>} 
      
      </div>}
      </div>
      {( permalink && migrateFrom && migrateTo && !migrateToResult && migrateFromResult && !migrateToCheckLoading) &&   
      <button
        disabled={migrationLoading}
        onClick={async()=>{
        let confirm = window.confirm(`Are you sure you want to migrate ${permalink} from ${migrateFrom} to ${migrateTo} env ?`)
        
        if(confirm){
        const config = {
            source: {
              endpoint: process.env.REACT_APP_SOURCE_API_ENDPOINT,
            },
            test: {
              endpoint: process.env.REACT_APP_TEST_API_ENDPOINT,
            },
            prod: {
              endpoint: process.env.REACT_APP_PROD_API_ENDPOINT,
            },
          };

        await axios.post(config[migrateTo]?.endpoint+'/assets/create_company/',{...companyData,email:authDetails.username}).then(res=>{
            setMigrateLoading(false)
            if(res.data.status==='success'){toast.success('sucessfully added. Migration successfull')}else{toast.error('This company already exists. Migration Failed.')}
        }).catch(err=>{
            setMigrateLoading(false)
            toast.error('Something went wrong')
        })
        }
        
      }
      }
      className='px-3 py-2 mt-4 disabled:bg-slate-100 disabled:text-black disabled:cursor-not-allowed rounded-xl bg-black text-white'>Migrate</button>  
      }
      </div>
    </div>
    </div>
    </div>
    </div>
  )
}

export default CompanyMigrations