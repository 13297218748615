import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

// import Danger from '../../assests/danger.svg';
// import pdfIcon from '../../assets/pdf.svg';
// import pptIcon from '../../assets/ppt.svg';
// import wordIcon from '../../assets/word.svg';
import DragDrop from "./dropZone";
import SourceEntry from "./sourceEnty";
import { FaRadiation } from "react-icons/fa";
import { Table } from "flowbite-react";
import { useNavigate } from "react-router-dom";

const FileUploadSection = ({
  fileTypes,
  handleFileUpload,
  fileUploadLoading,
  files,
  errorFiles,
  showDetailErrorFiles,
  handleAddFiles,
  handleRemoveFile,
  toggleDetailErrorFiles,
  setFileError,
  disabled = false,
  setErrorFiles,
}) => {
  const navigate = useNavigate();
  const [uploaderErrors, setUploaderErrors] = useState([]);
  const [showDetailErrorFilesUploader, setShowDetailErrorFilesUploader] =
    useState(0);

  function toggleDetailErrorFilesUploader(event, dataLength) {
    if (showDetailErrorFilesUploader === 0) {
      event.target.innerText = "Hide details";
      setShowDetailErrorFilesUploader(dataLength);
    } else {
      event.target.innerText = "See details";
      setShowDetailErrorFilesUploader(0);
    }
  }

  // const renderFile = (item) => {
  // 	let icon = File;
  // 	const uploadedFile = item?.split('.');
  // 	if (uploadedFile?.length > 0) {
  // 		switch (uploadedFile[uploadedFile?.length - 1]) {
  // 			case 'pdf':
  // 				icon = pdfIcon;
  // 				break;
  // 			case 'ppt':
  // 				icon = pptIcon;
  // 				break;
  // 			case 'pptx':
  // 				icon = pptIcon;
  // 				break;
  // 			case 'docx':
  // 				icon = wordIcon;
  // 				break;
  // 			case 'doc':
  // 				icon = wordIcon;
  // 				break;
  // 			default:
  // 				icon = File;
  // 				break;
  // 		}
  // 	}
  // 	return icon;
  // };

  useEffect(() => {
    setShowDetailErrorFilesUploader(uploaderErrors?.length);
  }, [uploaderErrors]);

  return (
    <div>
      <div className="w-[100%]">
        <DragDrop
          disabled={disabled}
          className="mb-4"
          types={fileTypes}
          onChange={handleFileUpload}
          loading={fileUploadLoading}
          onError={(error) => {
            setFileError(error);
            // setTimeout(() => {
            //   setFileError('');
            // }, 3000);
          }}
          setErrorFiles={setUploaderErrors}
        />
      </div>
      {/* <h4 className="font-medium text-center mb-4 text-newTheme-gray text-xs">
        or upload from
      </h4> */}
      <div className="flex gap-6 justify-center items-center mb-4">
        {/* <GoogleDrive
					disabled={disabled}
					handleAddFiles={handleAddFiles}
				/>
				<Dropbox
					disabled={disabled}
					handleAddFiles={!disabled && handleAddFiles}
				/>
				<OneDrivePicker
					disabled={disabled}
					handleAddFiles={handleAddFiles}
				/> */}
      </div>
      {files?.length !== 0 && (
        <h1 className="font-bold mt-2 text-sm text-[#1A1A1A]">
          Uploaded Files
        </h1>
      )}
      {/* {files?.map((s, i) => (
				<SourceEntry
					key={i}
					name={s.original}
					// icon={
					// 	<img
					// 		src={renderFile(s.original)}
					// 		alt="file"
					// 		className={'w-[20px] h-[20px]'}
					// 	/>
					// }
					disabled={disabled}
					onDelete={() => !disabled && handleRemoveFile(s.uniqueName)}
				/>
			))} */}
			{/* {
    "original": "1. Wokelo AI Pitch Deck.pdf",
    "fileQuality": "Low",
    "fileRelevance": "Low",
    "infoMessage": "29/38 pages read successfully.",
    "uniqueName": "file_33cba596-d436-40ef-ba62-b92e66e0517b" */}
	{files.length!==0&&
      <div className="overflow-x-auto mt-4 border rounded-lg">
        <table className="w-full">
          <thead className="border-b text-left">
            <th className="px-2 py-1 text-gray-700 text-sm">File Name</th>
            <th className="px-2 py-1 text-gray-700 text-sm">Unique Name</th>
            <th className="px-2 py-1 text-gray-700 text-sm">Quality</th>
            <th className="px-2 py-1 text-gray-700 text-sm">Relavance</th>
            <th className="px-2 py-1 text-gray-700 text-sm">Info Message</th>
			<th className="px-2 py-1 text-gray-700 text-sm">Action</th>
          </thead>
          <tbody className="divide-y">
            {files?.map((s,index)=>{return <tr key={index} className="bg-white odd:bg-gray-50">
              <td className="whitespace-nowrap px-2 py-2 font-medium text-gray-900">
                {s.original}
              </td>
			  <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                {s.uniqueName}
              </td>
			  <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                {s.fileQuality}
              </td>
			  <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                {s.fileRelevance}
              </td>
			  <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                {s.infoMessage}
              </td>
			  <td  className="whitespace-nowrap px-2 py-2  text-blue-500">
               <button className="bg-black px-2 py-1 text-s text-white rounded-lg" onClick={()=>navigate('/fileUploader/'+s.uniqueName)}>View</button> 
              </td>
            </tr>})}	
          </tbody>
        </table>
      </div>
	}	
      {uploaderErrors?.length ? (
        <div
          className={`w-full bg-[#FFF2F0] rounded-lg p-3 flex flex-col my-2 h-fit transition-all duration-300 ${
            disabled ? "cursor-not-allowed opacity-60" : ""
          }`}
        >
          <div className="flex mt-1 justify-between">
            <div className="flex">
              {/* <img src={Danger} /> */}
              <FaRadiation />
              <p
                className={
                  "text-[#D33030] font-normal text-xs leading-[18px] ml-2 font-DM-Sans-Medium"
                }
              >
                {uploaderErrors?.length +
                  ` ${
                    uploaderErrors?.length === 1 ? " File " : " Files "
                  } with invalid filetype`}
              </p>
            </div>
            <div>
              <p
                onClick={(event) =>
                  toggleDetailErrorFilesUploader(event, uploaderErrors?.length)
                }
                className="text-xs underline cursor-pointer text-[#D33030]"
              >
                {showDetailErrorFilesUploader ? "Hide details" : "See details"}
              </p>
            </div>
          </div>

          <ul className="mt-2 pl-5">
            {uploaderErrors
              ?.slice(0, showDetailErrorFilesUploader)
              ?.map((items, index) => {
                return (
                  <li
                    className="font-DM-Sans-Medium text-xs list-disc"
                    key={index}
                  >
                    {items}
                  </li>
                );
              })}
          </ul>
        </div>
      ) : null}

      {errorFiles?.length ? (
        <div
          className={`w-full bg-[#FFF2F0] rounded-lg p-3 flex flex-col my-2 h-fit transition-all duration-300 ${
            disabled ? "cursor-not-allowed opacity-60" : ""
          }`}
        >
          <div className="flex mt-1 justify-between">
            <div className="flex">
              <FaRadiation />
              <p
                className={
                  "text-[#D33030] font-normal text-xs leading-[18px] ml-2 font-DM-Sans-Medium"
                }
              >
                {errorFiles?.length +
                  ` ${
                    errorFiles?.length === 1 ? " File " : " Files "
                  }could not be processed`}
              </p>
            </div>
            <div>
              <p
                onClick={(event) =>
                  toggleDetailErrorFiles(event, errorFiles?.length)
                }
                className="text-xs underline cursor-pointer text-[#D33030]"
              >
                {showDetailErrorFiles ? "Hide details" : "See details"}
              </p>
            </div>
          </div>

          <ul className="mt-2 pl-5">
            {errorFiles?.slice(0, showDetailErrorFiles)?.map((items, index) => {
              return (
                <li
                  className="font-DM-Sans-Medium text-xs list-disc"
                  key={index}
                >
                  {items?.originalName}
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default FileUploadSection;
