import React from 'react'
import { useNavigate } from 'react-router-dom';

const RecentlyUploadedFiles = ({version='v2'}) => {
  let recentlySubmittedFiles; 
  if(version==='v2'){
    recentlySubmittedFiles = JSON.parse(localStorage.getItem('recent-files'));
  }else{
    recentlySubmittedFiles = JSON.parse(localStorage.getItem('recent-files-v1'));
  }
  const navigate = useNavigate();  
  return (
    <div>
      {recentlySubmittedFiles!==null&&<div>
        <p className='text-sm font-semibold'>Recently Uploaded Files</p>
      <div className="overflow-x-auto mt-4 border rounded-lg">
        <table className="w-full">
          <thead className="border-b text-left">
            <th className="px-2 py-1 text-gray-700 text-sm">File Name</th>
            <th className="px-2 py-1 text-gray-700 text-sm">Unique Name</th>
            <th className="px-2 py-1 text-gray-700 text-sm">Quality</th>
            <th className="px-2 py-1 text-gray-700 text-sm">Relavance</th>
            <th className="px-2 py-1 text-gray-700 text-sm">Info Message</th>
			<th className="px-2 py-1 text-gray-700 text-sm">Action</th>
          </thead>
          <tbody className="divide-y">
            {recentlySubmittedFiles?.map((s,index)=>{return <tr key={index} className="bg-white odd:bg-gray-50">
              <td className="whitespace-nowrap px-2 py-2 font-medium text-gray-900">
                {s.original}
              </td>
			  <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                {s.uniqueName}
              </td>
			  <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                {s.fileQuality}
              </td>
			  <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                {s.fileRelevance}
              </td>
			  <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                {s.infoMessage}
              </td>
			  <td  className="whitespace-nowrap px-2 py-2  text-blue-500">
               <button className="bg-black text-white text-sm px-2 py-1 rounded-lg" onClick={()=>navigate(version=='v2'?'/fileUploader/'+s.uniqueName:'/fileUploader-v1/'+s.uniqueName)}>View</button> 
              </td>
            </tr>})}	
          </tbody>
        </table>
      </div>
      </div>
	}	
    </div>
  )
}

export default RecentlyUploadedFiles
