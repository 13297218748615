// store.js

import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slice/authSlice';
import GlobalAuth from './slice/GlobalAuth';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    globalAuth: GlobalAuth,
  },
});
