import React, { useEffect, useState } from 'react'
import { BsEye } from 'react-icons/bs'
import { MdEdit } from 'react-icons/md'
import Input from '../../components/input'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import useAxiosInstance from '../../hooks/useAxios'
import { useDebounce } from 'use-debounce';

const AllUsers = () => {
  const [AllOrganisation,setAllOrganisation] = useState([])
  const [searchOrg,setSearchOrg] = useState("");
  const [orgLoading,setOrgLoading] = useState(false);
  const [selectedOrg,setSelectedOrg] = useState([]);
  const [orgIdToUserMappning,SetOrgIdToUserMappning] = useState({});
  
  const [userList,setUserList] = useState([]);
  const [userSearch, setUserSearch] = useState("");
  const [userQuery] = useDebounce(userSearch, 1000);
  const [
    userPaginatedIntermidatePageNumber,
    setUserPaginatedIntermidatePageNumber,
  ] = useState(1);
  const [userPaginatedPageNumber, setUserPaginatedPageNumber] = useState(1);
  const [isUserListLoading, setIsUserListLoading] = useState(false);
  
  const axiosInstance = useAxiosInstance()  
  
  const getOrgImage = (img,name) =>{
    if(img === null || img == ''){
        return `https://wklogo.blob.core.windows.net/logos-small/${name[0]?.toUpperCase()}.png`
    }else{
        return img
    }
    }
  const getFilteredOrgs = (AllOrganisation,searchOrg) =>{
    if(!searchOrg){
        return AllOrganisation
    }
    return AllOrganisation?.filter(item=>item?.name?.toLowerCase().includes(searchOrg?.toLowerCase()) || item?.label?.toLowerCase().includes(searchOrg?.toLowerCase()))
  }

  useEffect(() => {
    setOrgLoading(true);
    axiosInstance
      .get("admin/organisations/")
      .then((res) => {
        setAllOrganisation(res.data.organisations);
        let orgIdToUserMapping = {}
        res.data.organisations.forEach(org=>{
            orgIdToUserMapping[org.org_id] = org
        })
        SetOrgIdToUserMappning(orgIdToUserMapping) 
        setOrgLoading(false)
        })
      .catch((err) => {
        console.log(err);
        toast.error("Something went while getting orgs");
        setOrgLoading(false);
      });
  }, []);

  useEffect(() => {
    setIsUserListLoading(true);
    // let new_orgs_ids = Object.keys(selectedOrg).map(elem=>Number(elem))
    const controller = new AbortController();
    axiosInstance
      .get(
        `/admin/get-org-users/?&pgsz=20&page=${userPaginatedPageNumber}&org_ids=${selectedOrg}&query=${userQuery}`
      ,{
        signal: controller.signal
      }
      )
      .then((res) => {
        setUserList(res.data);
        setIsUserListLoading(false);
        setUserPaginatedIntermidatePageNumber(res.data.current_page)
      })
      .catch((err) => {
        console.log(err);
        // toast.error("Something went while getting users");
        // setIsUserListLoading(false);
      });
    
    return () => {
      controller.abort();
    };  
  }, [userPaginatedPageNumber,userQuery, selectedOrg]);

  return (
    <>
     <div className='p-6 w-full'>
     <div className='text-2xl font-semibold'>User Managment</div>
      {<div className='mt-4'>

        <div className='flex justify-between items-center w-full'> 
            <p className='text-sm font-semibold mb-4'>All Users</p>
            <Link to={'/addUsers'}><button 
                className='bg-black text-white text-sm rounded-xl px-2 py-1'>Add</button>
            </Link>
        </div>

        {/* <input pla/> */}
        <Input
        className='w-full'
        placeholder='Search orgs'
        value = {searchOrg}
        onChange = {e=>setSearchOrg(e.target.value)}
        />

       {orgLoading && <p className='mt-4'>Loading orgs...</p>} 
      {!orgLoading &&
      <div className="overflow-x-auto h-[300px]  overflow-y-auto mt-4 border rounded-lg">
        <table className="w-full">
          <thead className="border-b text-left">
            <th className="px-2 py-1 text-gray-700 text-sm">Action</th>
            <th className="px-2 py-1 text-gray-700 text-sm">Name</th>
            <th className="px-2 py-1 text-gray-700 text-sm">Image</th>
            <th className="px-2 py-1 text-gray-700 text-sm">Logo</th>
            <th className="px-2 py-1 text-gray-700 text-sm">ID</th>
            <th className="px-2 py-1 text-gray-700 text-sm">Label</th>
            <th className="px-2 py-1 text-gray-700 text-sm">Account Type</th>
			<th className="px-2 py-1 text-gray-700 text-sm">Report Count</th>
          </thead>
          <tbody className="divide-y">
            {(getFilteredOrgs(AllOrganisation,searchOrg) || []).map((org,index)=>{ return <tr key={index} className="bg-white odd:bg-gray-50">
                <td className="whitespace-nowrap px-2 py-1  text-gray-900">
                    {/* <button className='bg-yellow-200 text-white text-xl rounded-xl p-2'><MdEdit/></button> */}
                    <input type='checkbox' checked={selectedOrg.includes(org.org_id)} onClick={
                        ()=>selectedOrg.includes(org.org_id) ? setSelectedOrg(prev=>prev.filter(item=>item!=org.org_id)): setSelectedOrg(prev=>[...prev, org.org_id])}
                    />
                </td>
                <td className="whitespace-nowrap px-2 py-1  text-gray-900">
                    {org.name}
                </td>
                <td className="whitespace-nowrap px-2 py-1  text-gray-900">
                    <div 
                    style={{backgroundImage:`url('${getOrgImage(org?.org_img,org?.name)}')`}} 
                    className="h-[30px] w-full bg-center bg-no-repeat bg-contain"/>
                </td>
                <td className="whitespace-nowrap px-2 py-1  text-gray-900">
                    <div 
                    style={{backgroundImage:`url('${getOrgImage(org?.org_logo,org?.name)}')`}} 
                    className="h-[30px] w-full bg-center bg-no-repeat bg-contain"/>
                </td>
                <td className="whitespace-nowrap px-2 py-1  text-gray-900">
                    {org.org_id}
                </td>
                <td className="whitespace-nowrap px-2 py-1  text-gray-900">
                    {org.label}
                </td>
                <td className="whitespace-nowrap px-2 py-1  text-gray-900">
                    {org?.account_type?.name}
                </td>
                <td className="whitespace-nowrap px-2 py-1  text-gray-900">
                    {org?.report_count}
                </td>
                </tr>    
            })}
          </tbody>
        </table>
      </div>
      }
      </div>
	    }
        <div className="mt-4">
            <Input
              value={userSearch}
              onChange={(e) => {
                setUserSearch(e.target.value);
              }}
              type="text"
              placeholder="Search User"
            />
                <div className="overflow-y-auto h-[70vh] mt-2 border  rounded-lg w-full">
                  <table className="w-full ">
                    <thead className="border-b text-left">
                      <th className="px-2 py-1 text-gray-700 text-sm ">
                        Select
                      </th>
                      <th className="px-2 py-1 text-gray-700 text-sm ">
                        UserName
                      </th>
                      <th className="px-2 py-1 text-gray-700 text-sm ">
                        First Name
                      </th>
                      <th className="px-2 py-1 text-gray-700 text-sm ">
                        Last Name
                      </th>
                      <th className="px-2 py-1 text-gray-700 text-sm ">
                        Org Name
                      </th>
                      <th className="px-2 py-1 text-gray-700 text-sm ">
                        Org Type
                      </th>
                    </thead>
                    <tbody className="divide-y">
                      {isUserListLoading ? (
                        <div className="flex justify-center flex-col items-center">
                          <p>Loading...</p>
                        </div>
                      ) : (
                        userList?.users?.map((user) => {
                          return (
                            <tr
                              className="bg-white odd:bg-gray-50"
                              key={user.user_id}
                            >
                              <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                                {/* <button className='bg-yellow-200 text-white text-xl rounded-xl p-2'><MdEdit/></button> */}
                                <Link to={`/editUsers/?user_id=${user.user_id}`}><button className='bg-black text-white text-xl rounded-xl p-2'><MdEdit/></button></Link>
                              </td>
                              <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                                {user.username}
                              </td>
                              <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                                {user.first_name}
                              </td>
                              <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                                {user.last_name}
                              </td>
                              <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                                {orgIdToUserMappning[user.org_id]
                                  ?.name || "not known"}
                              </td>
                              <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                                {orgIdToUserMappning[user.org_id]
                                  ?.account_type?.name || "not known"}
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="mt-2 flex justify-between items-center">
                  <div className="flex space-x-1 items-center">
                    {userList.current_page > 1 && (
                      <button
                        disabled={isUserListLoading}
                        onClick={() => {
                          setUserPaginatedPageNumber((prev) => prev - 1);
                        }}
                        className="disabled:cursor-not-allowed text-black underline px-0 py-0 rounded-lg"
                      >
                        Back
                      </button>
                    )}

                    <div className="p-1 rounded border-black border-2 flex space-x-1 items-center">
                      <input
                        value={
                          isUserListLoading
                            ? "loading..."
                            : userPaginatedIntermidatePageNumber
                        }
                        type="number"
                        className="disabled:cursor-not-allowed mx-2 w-10"
                        disabled={isUserListLoading}
                        onChange={(e) => {
                          setUserPaginatedIntermidatePageNumber(
                            Number(e.target.value)
                          );
                        }}
                      />
                      <button
                        onClick={() => {
                          if(userPaginatedIntermidatePageNumber>0 && userPaginatedIntermidatePageNumber<=userList.num_pages)
                          {setUserPaginatedPageNumber(
                            userPaginatedIntermidatePageNumber
                          );}else{
                            alert('Please enter correct number range')
                          }
                        }}
                        className="bg-black px-1 py-1 text-xs rounded text-white"
                      >
                        Apply
                      </button>
                    </div>
                    {userList.current_page < userList.num_pages && (
                      <button
                        disabled={isUserListLoading}
                        onClick={() => {
                          setUserPaginatedPageNumber((prev) => prev + 1);
                        }}
                        className="ml-3 disabled:cursor-not-allowed text-black underline px-0 py-0 rounded-lg"
                      >
                        Next
                      </button>
                    )}
                  </div>
                  <div className="flex space-x-1">
                    <div>Total Pages: {userList.num_pages}</div>

                    <div>Total Users: {userList.total_users}</div>
                  </div>
                </div>
            </div>	
    </div>
    </> 
  )
}

export default AllUsers