import React, { useEffect, useState } from "react";
import { FaComment, FaSpinner, FaUserCircle } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import Input from "../../components/input";
import useAxiosInstance from "../../hooks/useAxios";
// import Preview from "./components/preview";
import {
  MdArrowBack,
  MdCheckCircle,
  MdDateRange,
  MdHourglassEmpty,
  MdHourglassFull,
  MdTypeSpecimen,
} from "react-icons/md";
import { useSelector } from "react-redux";
import { GoOrganization } from "react-icons/go";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";
import { Spinner } from "flowbite-react";
import { useDebounce } from "use-debounce";
import { parseAbsoluteToLocal } from "@internationalized/date";
import { DatePicker } from "@adobe/react-spectrum";
// import DatePicker from "react-datepicker"
import { Button, defaultTheme, Provider } from "@adobe/react-spectrum";

const EditOrganisation = () => {
  const [formData, setFormData] = useState({
    id: 29,
    name: "frontend_testing_1",
    label: "",
    comment: "",
    enabled_google: false,
    enabled_onedrive: false,
    enabled_dropbox: false,
    expiry_date: "2099-12-31T00:00:00Z",
    account_type: 3,
    save_upload: true,
    template: 7,
    meta: {
      report_limits: {
        daily: 50,
        monthly: 500,
        running: 20,
        yearly:0
      },
    },
    layouts: [1],
    locked_layouts: [],
    workflow: [1, 2],
    locked_workflow: [3, 4, 5, 6, 8],
  });
  const [OrginalCompanyName, setOrginalCompnayName] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const org_id = searchParams.get("org_id");
  //   console.log(data)
  const [steps, setSteps] = useState(0);
  const { organisation } = useSelector((s) => s.globalAuth);
  const [errorInName, setErrorInName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formInfoLoading, setFormInfoLoading] = useState(false);
  const [seachWorkFlow, setSearchWorkFlow] = useState("");
  const [workflowTypesList, setWorkflowTypesList] = useState([
    {
      id: 8,
      name: "Private company materials",
    },
    {
      id: 1,
      name: "Industry reports",
    },
    {
      id: 2,
      name: "Call transcripts",
    },
    {
      id: 3,
      name: "Internal knowledge repo",
    },
    {
      id: 5,
      name: "Public company fillings",
    },
    {
      id: 6,
      name: "Company financials",
    },
    {
      id: 4,
      name: "Pitch decks",
    },
  ]);
  const [templatesList, setTemplatesList] = useState([
    {
      id: 6,
      name: "Sage Collective",
    },
    {
      id: 3,
      name: "Guggenheim Partners",
    },
    {
      id: 8,
      name: "Storyhouse",
    },
    {
      id: 4,
      name: "Pale Blue Dot",
    },
    {
      id: 7,
      name: "wokelo",
    },
    {
      id: 2,
      name: "Bison Ventures",
    },
    {
      id: 12,
      name: "Anthos",
    },
    {
      id: 11,
      name: "Premji",
    },
    {
      id: 10,
      name: "Berkshire Partners",
    },
    {
      id: 9,
      name: "Sevensevensix",
    },
    {
      id: 5,
      name: "TSMG",
    },
  ]);
  const [accountTypeList, setAccountTypeList] = useState([
    {
      id: 1,
      name: "Trial",
    },
    {
      id: 2,
      name: "Paid",
    },
    {
      id: 3,
      name: "sandbox",
    },
    {
      id: 4,
      name: "internal",
    },
  ]);
  const [LayoutList, setLayoutList] = useState([
    {
      id: 1,
      name: "Default",
    },
  ]);
  const [
    accountType_id_to_accountType_name,
    setAccountType_id_to_accountType_name,
  ] = useState({});
  const [searchLayout, setSearchLayout] = useState("");
  const [enabledMetaKeys, setEnabledMetaKeys] = useState(
    Object.keys(formData.meta)
  );
  const [isLogoUploading, setIsLogoUploading] = useState(false);
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const [metaCompanySearchForPermalink, setMetaCompanySearchForPermalink] =
    useState("");
  const [metaCompanySearchResults, setMetaCompanySearchResults] = useState([]);
  const [metaPermalinkQuery] = useDebounce(metaCompanySearchForPermalink, 1000);
  const [metaPeramlinkResultsLoading, setMetaPermalinkResultsLoading] =
    useState(false);
  const [isMetaPermalinkShowMoreOn, setIsMetaPermalinkShowMoreButtonOn] =
    useState(false);

  const [orgsProducts, setOrgsProducts] = useState([]);

  useEffect(() => {
    if (metaPermalinkQuery !== "") {
      setMetaPermalinkResultsLoading(true);
      axiosInstance
        .post("/assets/get_company_list/", {
          company_type: "all",
          name: metaPermalinkQuery,
          search_by: "company name",
        })
        .then((res) => {
          setIsMetaPermalinkShowMoreButtonOn(true);
          setMetaCompanySearchResults(res.data.data);
          setMetaPermalinkResultsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setMetaPermalinkResultsLoading(false);
        });
    }
  }, [metaPermalinkQuery]);

  const [metaData, setMetaData] = useState({
    logo: "",
    webhook: "",
    permalink: "",
    sso_enabled: [],
    report_limits: {
      daily: 0,
      monthly: 0,
      running: 0,
      yearly: 0
    },
    zapier_webhook: "",
    disabled_export: 0,
    external_access: {
      enabled: false,
      sasToken: "",
    },
    msal_config:{
      clientId:"",
      authority:""
    },
    pitchbook_proxy: "",
    pitchbook_api_key: "",
  });
  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();

  const handleSubmit = () => {
    if (formData.expiry_date == "") {
      delete formData.expiry_date;
    }
    formData["account_type"] = Number(formData.account_type);
    setIsLoading(true);
    axiosInstance
      .post("/accounts/edit_a_org/", { ...formData, org_id: Number(org_id) })
      .then((res) => {
        if (res.data.status === "success") {
          toast.success(`Org is successfully edited.`);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        toast.error("something went wrong!");
      });
  };

  useEffect(() => {
    if (Object.keys(organisation).includes(formData?.name)) {
      setErrorInName("A Org with Name already exists!");
    } else {
      setErrorInName("");
    }
    if (formData?.name == OrginalCompanyName) {
      setErrorInName("");
    }
  }, [formData?.name]);

  useEffect(() => {
    setFormInfoLoading(true);
    axiosInstance
      .get("/accounts/get_org_management_mappings/")
      .then((res) => {
        setAccountTypeList(res.data?.mappings?.account_types);
        setTemplatesList(res.data?.mappings?.templates);
        let mapping = {};
        res.data?.mappings?.account_types?.forEach((type) => {
          mapping[type.id] = type.name;
        });
        setAccountType_id_to_accountType_name(mapping);
        setWorkflowTypesList(res.data?.mappings?.workflow_types);

        setLayoutList(res?.data?.mappings?.layouts);
        axiosInstance
          .get(`/accounts/get_a_org_details/?org_id=${org_id}`)
          .then((org_details_response) => {
            setFormData(org_details_response.data.org_data);
            setOrginalCompnayName(org_details_response.data.org_data.name);
            // let expiry_date = org_details_response.data.org_data.expiry_date
            // let new_expiry_date = new Date(expiry_date)
            // new_expiry_date = (`${new_expiry_date.getFullYear()}-${new_expiry_date.getMonth()}-${new_expiry_date.getDate()}T${new_expiry_date.getHours()}:${new_expiry_date.getMinutes()}`)
            // setFormData((prev)=>({...prev,expiry_date:new_expiry_date}))
            // axiosInstance
            //   .get(
            //     `/accounts/sandbox-dashboard/?org=${org_details_response?.data?.org_data?.name}`
            //   )
            //   .then((res) => {
            //     let datata = res.data.user_org_products;
            //     // setOrgsProducts([
            //     //   ...datata.data,
            //     //   datata.features["newsletter"],
            //     // ]);

                setFormInfoLoading(false);
            //   });
            setEnabledMetaKeys(
              Object.keys(org_details_response.data.org_data.meta)
            );
            //upadting metaData form data based on response from backend
            let newMetaData = JSON.parse(JSON.stringify(metaData));
            Object.keys(org_details_response.data.org_data.meta).forEach(
              (metakey) => {
                newMetaData[metakey] =
                  org_details_response.data.org_data.meta[metakey];
              }
            );
            console.log(newMetaData);
            setMetaData(newMetaData);
          });
      })
      .catch((err) => {
        setFormInfoLoading(false);
        console.log(err);
      });
  }, [org_id]);

  const get_workflow_search_results = (allWorkflows, search) => {
    console.log(allWorkflows);
    if (search === "") {
      return allWorkflows;
    } else
      return allWorkflows?.filter(
        (item) =>
          item?.name?.toLowerCase()?.includes(search?.toLowerCase()) ||
          String(item?.id)?.includes(String(search))
      );
  };

  const initate_file_upload = (file, fileName) => {
    setIsLogoUploading(true);
    const formData_ = new FormData();
    formData_.append("files", file);
    formData_.append("permalink", fileName);
    formData_.append("is_template_logos", true);
    axiosInstance
      .post("/assets/upload_logo_image/", formData_)
      .then((res) => {
        console.log(res.data);
        setIsLogoUploading(false);
      })
      .catch((err) => {
        setIsLogoUploading(false);
      });
  };

  useEffect(() => {
    console.log("meta_data", metaData);
    console.log("formData", formData);
    console.log("enabledMetaKeys", enabledMetaKeys);
  }, [metaData, formData, enabledMetaKeys]);

  return (
    <div className="">
      <div className="flex justify-center">
        <div className="w-[680px] flex items-center mt-10">
          
          <div className="w-18 flex flex-col justify-center items-center">
            {steps <= 0 ? <MdHourglassEmpty /> : <MdCheckCircle />}
            <div className="text-xs px-2 text-center">Step 1</div>
          </div>
          <div
            className={`w-full h-1 ${
              steps <= 0 ? "bg-slate-300" : "bg-black"
            }`}
          ></div>
          <div className="w-18 flex flex-col justify-center items-center">
            {(steps <= 1 )  ? <MdHourglassEmpty /> : <MdHourglassFull />}
            <div className="text-xs px-2 text-center">Meta 1</div>
          </div>
          
          <div
            className={`w-full h-1 ${
                (steps <= 1 ) ? "bg-slate-300" : "bg-black"
            }`}
          ></div>

            <div className="w-18 flex flex-col justify-center items-center">
            {(steps <= 2 )  ? <MdHourglassEmpty /> : <MdHourglassFull />}
            <div className="text-xs px-2 text-center">Meta 2</div>
          </div>
          
          <div
            className={`w-full h-1 ${
                (steps <= 2 ) ? "bg-slate-300" : "bg-black"
            }`}
          ></div>

            <div className="w-18 flex flex-col justify-center items-center">
            {(steps <= 3 )  ? <MdHourglassEmpty /> : <MdHourglassFull />}
            <div className="text-xs px-2 text-center">Meta 3</div>
          </div>
          
          <div
            className={`w-full h-1 ${
                (steps <= 3 ) ? "bg-slate-300" : "bg-black"
            }`}
          ></div>
         
          <div className="w-18 flex flex-col justify-center items-center">
            {steps <= 4 ? <MdHourglassEmpty /> : <MdHourglassFull />}
            <div className="text-xs px-2 text-center">Preview</div>
          </div>

        </div>
      </div>
      <div className={` w-full justify-center p-5 ${"items-start "} flex`}>
        {steps === 0 && (
          <>
            {formInfoLoading ? (
              <p className="mt-5 p-3">Loading...</p>
            ) : (
              <div className="w-[676px] p-3 mt-5 border rounded-lg bg-white">
                <p className="text-2xl font-semibold mb-4">Edit Organisation</p>
                <div className="text-sm font-semibold mb-2">Name*</div>
                {errorInName && (
                  <p className="text-sm text-red-300">{errorInName}</p>
                )}
                <Input
                  value={formData.name}
                  onChange={(e) => {
                    setFormData({ ...formData, name: e.target.value });
                  }}
                  placeholder="Enter the name"
                />

                <div className="text-sm mt-4 font-semibold mb-2">Label</div>
                <Input
                  value={formData.label}
                  onChange={(e) => {
                    setFormData({ ...formData, label: e.target.value });
                  }}
                  placeholder="Enter the label"
                />

                <div className="text-sm  mt-4  font-semibold mb-2">Comment</div>
                <Input
                  value={formData.comment}
                  onChange={(e) => {
                    setFormData({ ...formData, comment: e.target.value });
                  }}
                  placeholder="Enter the comment"
                />

                <div className="text-sm  mt-4  font-semibold mb-2">
                  Expiry date
                </div>
                {/* <Input
                  type={'datetime-local'}
                  value={formData.expiry_date}
                  onChange={(e) => {
                    setFormData({ ...formData, expiry_date: e.target.value });
                  }}
                  placeholder="Enter the Expiry date"
                /> */}
                <Provider theme={defaultTheme}>
                  <DatePicker
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        expiry_date: value.toAbsoluteString(),
                      });
                      // console.log()
                    }}
                    defaultValue={parseAbsoluteToLocal(formData.expiry_date)}
                  />
                </Provider>

                <div className="text-sm  mt-4  font-semibold mb-2">
                  Account Type*
                </div>
                <select
                  value={formData.account_type}
                  onChange={(e) => {
                    setFormData({ ...formData, account_type: e.target.value });
                  }}
                  className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 "
                  placeholder="Enter your password"
                >
                  {[{ id: "", name: "choose" }, ...accountTypeList]?.map(
                    (account_type) => (
                      <option value={account_type.id}>
                        {account_type.name}
                      </option>
                    )
                  )}
                </select>

                <div className="text-sm  mt-4  font-semibold mb-2">
                  Data Upload
                </div>
                <div className="ml-2 grid grid-cols-3">
                  <div className="flex items-center gap-1">
                    Google{" "}
                    <input
                      checked={formData.enabled_google}
                      onClick={() => {
                        setFormData({
                          ...formData,
                          enabled_google: !formData.enabled_google,
                        });
                      }}
                      type="checkbox"
                    />
                  </div>
                  <div className="flex items-center gap-1">
                    Onedrive{" "}
                    <input
                      disabled
                      checked={formData.enabled_onedrive}
                      onClick={() => {
                        setFormData({
                          ...formData,
                          enabled_onedrive: !formData.enabled_onedrive,
                        });
                      }}
                      type="checkbox"
                    />
                  </div>
                  <div className="flex items-center gap-1">
                    Dropbox{" "}
                    <input
                      checked={formData.enabled_dropbox}
                      onClick={() => {
                        setFormData({
                          ...formData,
                          enabled_dropbox: !formData.enabled_dropbox,
                        });
                      }}
                      type="checkbox"
                    />
                  </div>
                </div>

                <div className="text-sm  mt-4  font-semibold mb-2">
                  Templates
                </div>
                <Select
                  value={formData.template}
                  onChange={(e) => {
                    setFormData({ ...formData, template: e.value });
                  }}
                  options={[{ id: "", name: "choose" }, ...templatesList].map(
                    (option) => ({ value: option.id, label: option.name })
                  )}
                  className="w-full"
                  placeholder={
                    templatesList.find(
                      (template) => template.id === formData.template
                    ).name
                  }
                />

                <div className="text-sm  mt-4  font-semibold mb-2">
                  Save Uploaded files :{" "}
                  <input
                    checked={formData.save_upload}
                    type="checkbox"
                    onClick={() => {
                      setFormData({
                        ...formData,
                        save_upload: !formData.save_upload,
                      });
                    }}
                  />
                </div>

                <div className="text-xl  mt-4  font-semibold mb-2">Access</div>

                <div className="text-sm  mt-2  font-semibold mb-2">
                  Multifile Qna
                </div>
                <div className="text-sm  mt-2  font-semibold mb-2 ml-5">
                  Workflows
                </div>
                <div>
                  <div className="mx-5">
                    <Input
                      placeholder={"Search workflow"}
                      value={seachWorkFlow}
                      onChange={(e) => {
                        setSearchWorkFlow(e.target.value);
                      }}
                    />
                    <div className="h-64 overflow-y-auto">
                      <table className="w-full mt-3">
                        <thead className="border-b text-left">
                          <th className="px-2 py-1 text-gray-700 text-sm">
                            Name
                          </th>
                          <th className="px-2 py-1 text-gray-700 text-sm">
                            isLocked
                          </th>
                          <th className="px-2 py-1 text-gray-700 text-sm">
                            isUnlocked
                          </th>
                        </thead>
                        <tbody className="divide-y">
                          {get_workflow_search_results(
                            workflowTypesList,
                            seachWorkFlow
                          )?.map((workflow_type) => {
                            return (
                              <tr>
                                <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                                  {workflow_type.name}
                                </td>

                                <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                                  <input
                                    checked={formData?.locked_workflow?.includes(
                                      workflow_type.id
                                    )}
                                    onClick={() => {
                                      let locked_workflow = [];
                                      if (
                                        formData?.locked_workflow?.includes(
                                          workflow_type.id
                                        )
                                      ) {
                                        locked_workflow =
                                          formData?.locked_workflow?.filter(
                                            (id) => id != workflow_type.id
                                          );
                                      } else {
                                        locked_workflow = [
                                          ...formData?.locked_workflow,
                                          workflow_type.id,
                                        ];
                                      }
                                      setFormData({
                                        ...formData,
                                        locked_workflow: locked_workflow,
                                      });
                                    }}
                                    type="checkbox"
                                  />
                                </td>

                                <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                                  <input
                                    checked={formData?.workflow?.includes(
                                      workflow_type.id
                                    )}
                                    onClick={() => {
                                      let workflow = [];
                                      if (
                                        formData?.workflow?.includes(
                                          workflow_type.id
                                        )
                                      ) {
                                        workflow = formData?.workflow?.filter(
                                          (id) => id != workflow_type.id
                                        );
                                      } else {
                                        workflow = [
                                          ...formData?.workflow,
                                          workflow_type.id,
                                        ];
                                      }
                                      setFormData({
                                        ...formData,
                                        workflow: workflow,
                                      });
                                    }}
                                    type="checkbox"
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="text-sm  mt-2  font-semibold mb-2">DRS</div>
                <div className="text-sm  mt-2  font-semibold mb-2 ml-5">
                  Layouts
                </div>
                <div>
                  <div className="mx-5">
                    <Input
                      placeholder={"Search Layout"}
                      value={searchLayout}
                      onChange={(e) => {
                        setSearchLayout(e.target.value);
                      }}
                    />
                    <div className="h-64 overflow-y-auto">
                      <table className="w-full mt-3">
                        <thead className="border-b text-left">
                          <th className="px-2 py-1 text-gray-700 text-sm">
                            Name
                          </th>
                          <th className="px-2 py-1 text-gray-700 text-sm">
                            isLocked
                          </th>
                          <th className="px-2 py-1 text-gray-700 text-sm">
                            isUnlocked
                          </th>
                        </thead>
                        <tbody className="divide-y">
                          {get_workflow_search_results(
                            LayoutList,
                            searchLayout
                          )?.map((layout_obj) => {
                            return (
                              <tr>
                                <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                                  {layout_obj.name}
                                </td>

                                <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                                  <input
                                    checked={formData?.locked_layouts?.includes(
                                      layout_obj.id
                                    )}
                                    onClick={() => {
                                      let locked_layouts = [];
                                      if (
                                        formData?.locked_layouts?.includes(
                                          layout_obj.id
                                        )
                                      ) {
                                        locked_layouts =
                                          formData?.locked_layouts?.filter(
                                            (id) => id != layout_obj.id
                                          );
                                      } else {
                                        locked_layouts = [
                                          ...formData?.locked_layouts,
                                          layout_obj.id,
                                        ];
                                      }
                                      setFormData({
                                        ...formData,
                                        locked_layouts: locked_layouts,
                                      });
                                    }}
                                    type="checkbox"
                                  />
                                </td>

                                <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                                  <input
                                    checked={formData?.layouts?.includes(
                                      layout_obj.id
                                    )}
                                    onClick={() => {
                                      let layouts = [];
                                      if (
                                        formData?.layouts?.includes(
                                          layout_obj.id
                                        )
                                      ) {
                                        layouts = formData?.layouts?.filter(
                                          (id) => id != layout_obj.id
                                        );
                                      } else {
                                        layouts = [
                                          ...formData?.layouts,
                                          layout_obj.id,
                                        ];
                                      }
                                      setFormData({
                                        ...formData,
                                        layouts: layouts,
                                      });
                                    }}
                                    type="checkbox"
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/* Orgs Products */}
                <div>
                  <div className="text-xl flex justify-between w-full mt-4  font-semibold mb-2">
                    <p>Orgs Products</p>
                    
                    <div>
                    <Link to={`/dashboardTiles/?org_name=${formData.name}`}>
                      <button className="text-sm mr-2 underline">View</button>
                    </Link>
                    <Link to={`/subscriptions/?org_name=${formData.name}`}>
                      <button className="text-sm underline">Edit</button>
                    </Link>
                    </div>
                  </div>

                  {/* <div className="overflow-x-auto h-[180px]  overflow-y-auto mt-4 border rounded-lg">
                    <table className="w-full">
                      <thead className="border-b text-left">
                        <th className="px-2 py-1 text-gray-700 text-sm">
                          code
                        </th>
                        <th className="px-2 py-1 text-gray-700 text-sm">
                          workflow_type
                        </th>
                        <th className="px-2 py-1 text-gray-700 text-sm">
                          name
                        </th>
                        <th className="px-2 py-1 text-gray-700 text-sm">
                          order_preference
                        </th>
                        <th className="px-2 py-1 text-gray-700 text-sm">
                          description
                        </th>
                        <th className="px-2 py-1 text-gray-700 text-sm">
                          subscription
                        </th>
                        <th className="px-2 py-1 text-gray-700 text-sm">
                          resultPageType
                        </th>
                      </thead>
                      <tbody className="divide-y">
                        {orgsProducts?.filter((e) => !e.subscription.includes("Hidden"))?.map((product, index) => {
                            return (
                              <tr
                                key={index}
                                className="bg-white odd:bg-gray-50"
                              >
                                <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                                  {product?.code}
                                </td>
                                <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                                  {product?.workflow_type}
                                </td>
                                <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                                  {product?.name}
                                </td>
                                <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                                  {product?.order_preference}
                                </td>
                                <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                                  {product?.description}
                                </td>
                                <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                                  {product?.subscription}
                                </td>
                                <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                                  {product?.resultPageType}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div> */}
                </div>
                <button
            disabled={isLoading}
            className="flex gap-1 items-center px-2 py-1 mt-2 border border-black bg-black disabled:bg-[#b6b6b6] disabled:text-white text-white rounded-lg"
            onClick={() => {
                setSteps((prev) => prev + 1);
            }}
          > 
             Next
          </button>
              </div>
            )}
          </>
        )}
        {steps == 1 && (
          <div className="w-[676px] p-3 mt-5 border rounded-lg bg-white">
            <p className="text-2xl font-semibold mb-4">Meta</p>

            {/* Logo          */}
            <div className="p-2 mt-4 bg-slate-200 rounded">
              <div className="text-lg flex justify-between items-center font-semibold mb-2">
                <p>Logo</p>
                <input
                  type="checkbox"
                  checked={enabledMetaKeys.includes("logo")}
                  onClick={() => {
                    setEnabledMetaKeys((prev) =>
                      enabledMetaKeys.includes("logo")
                        ? prev.filter((item) => item !== "logo")
                        : [...prev, "logo"]
                    );
                  }}
                />
              </div>

              <div className="text-sm  mt-4  font-semibold mb-2">
                Logo Value
              </div>
              <Input
                type={"text"}
                value={metaData.logo}
                onChange={(e) => {
                  setFileName(`${e.target.value}-light.png`);
                  setMetaData({ ...metaData, logo: e.target.value });
                }}
              />
              <div className="text-sm  mt-4  font-semibold mb-2">File Name</div>
              <Input
                type={"text"}
                value={fileName}
                onChange={(e) => {
                  setFileName(e.target.value);
                }}
              />
              <p className="text-sm  mt-4  font-semibold mb-2">Logo file</p>
              <Input
                type={"file"}
                // value={formData.logo}
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
                placeholder="Enter the Logo"
              />
              {file && (
                <>
                  <p className="mt-2">Preview</p>
                  <img className="h-14" src={URL.createObjectURL(file)} />
                </>
              )}
              <button
                onClick={() => initate_file_upload(file, fileName)}
                className="bg-black text-white rounded-lg px-2 py-1 text-sm"
              >
                {!isLogoUploading ? "upload" : "Loading..."}
              </button>
              {isLogoUploading && <Spinner />}
            </div>
            {/* Reports_limit */}
            <div className="p-2 mt-4 bg-slate-200 rounded">
              <div className="text-lg flex justify-between items-center font-semibold mb-2">
                <p>Report limits</p>
                <input
                  type="checkbox"
                  checked={enabledMetaKeys.includes("report_limits")}
                  onClick={() => {
                    setEnabledMetaKeys((prev) =>
                      enabledMetaKeys.includes("report_limits")
                        ? prev.filter((item) => item !== "report_limits")
                        : [...prev, "report_limits"]
                    );
                  }}
                />
              </div>

              <div className="text-sm  mt-4  font-semibold mb-2">running</div>
              <Input
                type={"Number"}
                value={metaData.report_limits.running}
                onChange={(e) => {
                  let new_meta_data = JSON.parse(JSON.stringify(metaData));
                  new_meta_data.report_limits["running"] = Number(
                    e.target.value
                  );
                  setMetaData(new_meta_data);
                }}
              />
              <div className="text-sm  mt-4  font-semibold mb-2">daily</div>
              <Input
                type={"Number"}
                value={metaData.report_limits.daily}
                onChange={(e) => {
                  let new_meta_data = JSON.parse(JSON.stringify(metaData));
                  new_meta_data.report_limits["daily"] = Number(e.target.value);
                  setMetaData(new_meta_data);
                }}
              />
              <div className="text-sm  mt-4  font-semibold mb-2">monthly</div>
              <Input
                type={"Number"}
                value={metaData.report_limits.monthly}
                onChange={(e) => {
                  let new_meta_data = JSON.parse(JSON.stringify(metaData));
                  new_meta_data.report_limits["monthly"] = Number(
                    e.target.value
                  );
                  setMetaData(new_meta_data);
                }}  
              />

              <div className="text-sm  mt-4  font-semibold mb-2">yearly</div>
              <Input
                type={"Number"}
                value={metaData.report_limits.yearly}
                onChange={(e) => {
                  let new_meta_data = JSON.parse(JSON.stringify(metaData));
                  new_meta_data.report_limits["yearly"] = Number(
                    e.target.value
                  );
                  setMetaData(new_meta_data);
                }}
              />
            </div>

            {/* msal_config */}
            <div className="p-2 mt-4 bg-slate-200 rounded">
              <div className="text-lg flex justify-between items-center font-semibold mb-2">
                <p>msal_config</p>
                <input
                  type="checkbox"
                  checked={enabledMetaKeys.includes("msal_config")}
                  onClick={() => {
                    setEnabledMetaKeys((prev) =>
                      enabledMetaKeys.includes("msal_config")
                        ? prev.filter((item) => item !== "msal_config")
                        : [...prev, "msal_config"]
                    );
                  }}
                />
              </div>

              <div className="text-sm  mt-4  font-semibold mb-2">clientId</div>
              <Input
                type={"text"}
                value={metaData.msal_config.clientId}
                placeholder={'f2109ae0-xxxx-xxxx-xxxx-ac3ba17fbf9a'}
                onChange={(e) => {
                  let new_meta_data = JSON.parse(JSON.stringify(metaData));
                  new_meta_data.msal_config["clientId"] = e.target.value;
                  setMetaData(new_meta_data);
                }}
              />

              <div className="text-sm  mt-4  font-semibold mb-2">authority</div>
              <Input
                type={"text"}
                placeholder={'https://login.microsoftonline.com/a6917215-25de-xxxx-xxxx-fd224e9238e7'}
                value={metaData.msal_config.authority}
                onChange={(e) => {
                  let new_meta_data = JSON.parse(JSON.stringify(metaData));
                  new_meta_data.msal_config["authority"] = e.target.value;
                  setMetaData(new_meta_data);
                }}
              />
            </div>

            {/* external_access     */}
            <div className="p-2 mt-4 bg-slate-200 rounded">
              <div className="text-lg flex justify-between items-center font-semibold mb-2">
                <p>External access</p>
                <input
                  type="checkbox"
                  checked={enabledMetaKeys.includes("external_access")}
                  onClick={() => {
                    setEnabledMetaKeys((prev) =>
                      enabledMetaKeys.includes("external_access")
                        ? prev.filter((item) => item !== "external_access")
                        : [...prev, "external_access"]
                    );
                  }}
                />
              </div>
              <div className="text-sm  mt-4  font-semibold mb-2">
                enabled :
                <input
                  type="checkbox"
                  checked={metaData.external_access.enabled}
                  onChange={(e) => {
                    let new_meta_data = JSON.parse(JSON.stringify(metaData));
                    new_meta_data.external_access["enabled"] =
                      !metaData.external_access.enabled;
                    setMetaData(new_meta_data);
                  }}
                />
              </div>
              <div className="text-sm  mt-4  font-semibold mb-2">sasToken</div>
              <Input
                type={"text"}
                placeholder={"Enter sasToken"}
                value={metaData.external_access.sasToken}
                onChange={(e) => {
                  let new_meta_data = JSON.parse(JSON.stringify(metaData));
                  new_meta_data.external_access["sasToken"] = e.target.value;
                  setMetaData(new_meta_data);
                }}
              />
            </div>

            <div className="flex gap-x-2 items-center">  
            <button
            title="Go Back and Edit"
            className="flex gap-1 items-center px-2 py-1 mt-2 border rounded-lg"
            onClick={() => {
              setSteps((prev) => prev - 1);
            }}
          >
            <MdArrowBack />
            </button>

            <button
            disabled={isLoading}
            className="flex gap-1 items-center px-2 py-1 mt-2 border border-black bg-black disabled:bg-[#b6b6b6] disabled:text-white text-white rounded-lg"
            onClick={() => {
                setSteps((prev) => prev + 1);
            }}
          > 
             Next
          </button>
          </div>

          </div>
        )}
        {steps == 2 && (
          <div className="w-[676px] p-3 mt-5 border rounded-lg bg-white">
            <p className="text-2xl font-semibold mb-4">Meta Part 2</p>

            {/* pitchbook_api_key     */}
            <div className="p-2 mt-4 bg-slate-200 rounded">
              <div className="text-lg flex justify-between items-center font-semibold mb-2">
                <p>Pitchbook Api Key</p>
                <input
                  type="checkbox"
                  checked={enabledMetaKeys.includes("pitchbook_api_key")}
                  onClick={() => {
                    setEnabledMetaKeys((prev) =>
                      enabledMetaKeys.includes("pitchbook_api_key")
                        ? prev.filter((item) => item !== "pitchbook_api_key")
                        : [...prev, "pitchbook_api_key"]
                    );
                  }}
                />
              </div>
              <Input
                type={"text"}
                placeholder={"Enter pitchbook_api_key"}
                value={metaData.pitchbook_api_key}
                onChange={(e) => {
                  setMetaData({
                    ...metaData,
                    pitchbook_api_key: e.target.value,
                  });
                }}
              />
            </div>

            {/* pitchbook_proxy     */}
            <div className="p-2 mt-4 bg-slate-200 rounded">
              <div className="text-lg flex justify-between items-center font-semibold mb-2">
                <p>Pitchbook Proxy</p>
                <input
                  type="checkbox"
                  checked={enabledMetaKeys.includes("pitchbook_proxy")}
                  onClick={() => {
                    setEnabledMetaKeys((prev) =>
                      enabledMetaKeys.includes("pitchbook_proxy")
                        ? prev.filter((item) => item !== "pitchbook_proxy")
                        : [...prev, "pitchbook_proxy"]
                    );
                  }}
                />
              </div>
              <Input
                type={"text"}
                value={metaData.pitchbook_proxy}
                placeholder={"Enter pitchbook_proxy"}
                onChange={(e) => {
                  setMetaData({ ...metaData, pitchbook_proxy: e.target.value });
                }}
              />
            </div>

            {/* pitchbook_proxy     */}
            {/* <div className="p-2 mt-4 bg-slate-200 rounded">
              <div className="text-lg flex justify-between items-center font-semibold mb-2">
                <p>Pitchbook Proxy</p>
                <input
                  type="checkbox"
                  checked={enabledMetaKeys.includes("pitchbook_proxy")}
                  onClick={() => {
                    setEnabledMetaKeys((prev) =>
                      enabledMetaKeys.includes("pitchbook_proxy")
                        ? prev.filter((item) => item !== "pitchbook_proxy")
                        : [...prev, "pitchbook_proxy"]
                    );
                  }}
                />
              </div>
              <Input
                type={"text"}
                value={metaData.pitchbook_proxy}
                placeholder={"Enter pitchbook_proxy"}
                onChange={(e) => {
                  setMetaData({ ...metaData, pitchbook_proxy: e.target.value });
                }}
              />
            </div> */}

            {/* webhook     */}
            <div className="p-2 mt-4 bg-slate-200 rounded">
              <div className="text-lg flex justify-between items-center font-semibold mb-2">
                <p>Webhook</p>
                <input
                  type="checkbox"
                  checked={enabledMetaKeys.includes("webhook")}
                  onClick={() => {
                    setEnabledMetaKeys((prev) =>
                      enabledMetaKeys.includes("webhook")
                        ? prev.filter((item) => item !== "webhook")
                        : [...prev, "webhook"]
                    );
                  }}
                />
              </div>
              <Input
                type={"text"}
                value={metaData.webhook}
                placeholder={"Enter webhook"}
                onChange={(e) => {
                  setMetaData({ ...metaData, webhook: e.target.value });
                }}
              />
            </div>

            {/* zapier_webhook     */}
            <div className="p-2 mt-4 bg-slate-200 rounded">
              <div className="text-lg flex justify-between items-center font-semibold mb-2">
                <p>Zapier webhook</p>
                <input
                  type="checkbox"
                  checked={enabledMetaKeys.includes("zapier_webhook")}
                  onClick={() => {
                    setEnabledMetaKeys((prev) =>
                      enabledMetaKeys.includes("zapier_webhook")
                        ? prev.filter((item) => item !== "zapier_webhook")
                        : [...prev, "zapier_webhook"]
                    );
                  }}
                />
              </div>
              <Input
                type={"text"}
                value={metaData.zapier_webhook}
                placeholder={"Enter zapier_webhook"}
                onChange={(e) => {
                  setMetaData({ ...metaData, zapier_webhook: e.target.value });
                }}
              />
            </div>

            <div className="flex gap-x-2 items-center">  
            <button
            title="Go Back and Edit"
            className="flex gap-1 items-center px-2 py-1 mt-2 border rounded-lg"
            onClick={() => {
              setSteps((prev) => prev - 1);
            }}
          >
            <MdArrowBack />
            </button>

            <button
            disabled={isLoading}
            className="flex gap-1 items-center px-2 py-1 mt-2 border border-black bg-black disabled:bg-[#b6b6b6] disabled:text-white text-white rounded-lg"
            onClick={() => {
                setSteps((prev) => prev + 1);
            }}
          > 
             Next
          </button>
          </div>

          </div>
        )}
        {steps == 3 && (
          <div className="w-[676px] p-3 mt-5 border rounded-lg bg-white">
            <p className="text-2xl font-semibold mb-4">Meta Part 3</p>

            {/* disabled export     */}
            <div className="p-2 mt-4 bg-slate-200 rounded">
              <div className="text-lg flex justify-between items-center font-semibold mb-2">
                <p>disabled_export</p>
                <input
                  type="checkbox"
                  checked={enabledMetaKeys.includes("disabled_export")}
                  onClick={() => {
                    setEnabledMetaKeys((prev) =>
                      enabledMetaKeys.includes("disabled_export")
                        ? prev.filter((item) => item !== "disabled_export")
                        : [...prev, "disabled_export"]
                    );
                  }}
                />
              </div>
              <input
                type="checkbox"
                checked={metaData.disabled_export == true || metaData.disabled_export == 1}
                onClick={() => {
                  let newPrev = JSON.parse(JSON.stringify(metaData));
                  if (newPrev.disabled_export == true || newPrev.disabled_export == 1) {
                    newPrev["disabled_export"] = 0;
                  } else {
                    newPrev["disabled_export"] = 1;
                  }
                  console.log(newPrev);
                  setMetaData(newPrev);
                }}
              />
            </div>

            {/* Permalink     */}
            <div className="p-2 mt-4 bg-slate-200 rounded">
              <div className="text-lg flex justify-between items-center font-semibold mb-2">
                <p>Permalink</p>
                <input
                  type="checkbox"
                  checked={enabledMetaKeys.includes("permalink")}
                  onClick={() => {
                    setEnabledMetaKeys((prev) =>
                      enabledMetaKeys.includes("permalink")
                        ? prev.filter((item) => item !== "permalink")
                        : [...prev, "permalink"]
                    );
                  }}
                />
              </div>
              <Input
                type={"text"}
                value={metaData.permalink}
                placeholder={"Enter permalink"}
                readOnly={true}
              />

              <div className="mt-2" />
              <Input
                type={"text"}
                value={metaCompanySearchForPermalink}
                placeholder={"Enter company name"}
                onChange={(e) => {
                  setMetaCompanySearchForPermalink(e.target.value);
                }}
              />
              <div className="overflow-x-auto h-[180px]  overflow-y-auto mt-4 border rounded-lg">
                <table className="w-full">
                  <thead className="border-b text-left">
                    <th className="px-2 py-1 text-gray-700 text-sm">Action</th>
                    <th className="px-2 py-1 text-gray-700 text-sm">
                      permalink
                    </th>
                    <th className="px-2 py-1 text-gray-700 text-sm">name</th>
                    <th className="px-2 py-1 text-gray-700 text-sm">
                      founded_year
                    </th>
                    <th className="px-2 py-1 text-gray-700 text-sm">
                      ipo_status
                    </th>
                    <th className="px-2 py-1 text-gray-700 text-sm">country</th>
                    <th className="px-2 py-1 w-[100px] text-gray-700 text-sm">
                      short_description
                    </th>
                  </thead>
                  <tbody className="divide-y">
                    {metaPeramlinkResultsLoading ? (
                      <>Loading...</>
                    ) : (
                      <>
                        {metaCompanySearchResults.map((result, index) => {
                          return (
                            <tr key={index} className="bg-white odd:bg-gray-50">
                              <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                                <button
                                  onClick={() => {
                                    setMetaData({
                                      ...metaData,
                                      permalink: result.permalink,
                                    });
                                  }}
                                  className="bg-black rounded-sm px-2 py-1 text-xs text-white"
                                >
                                  set permalink
                                </button>
                              </td>
                              <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                                {result.permalink}
                              </td>
                              <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                                {result.name}
                              </td>
                              <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                                {result.founded_year}
                              </td>
                              <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                                {result.ipo_status}
                              </td>
                              <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                                {result.country}
                              </td>
                              <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                                {result.short_description}
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    )}
                  </tbody>
                  {isMetaPermalinkShowMoreOn && (
                    <button
                      disabled={metaPeramlinkResultsLoading}
                      className="px-2 py-1 text-sm rounded-lg disabled:cursor-not-allowed"
                      onClick={() => {
                        setIsMetaPermalinkShowMoreButtonOn(false);
                        setMetaPermalinkResultsLoading(true);
                        axiosInstance
                          .post("/assets/get_company_list/", {
                            company_type: "all",
                            name: metaPermalinkQuery,
                            search_by: "company name",
                            global: true,
                          })
                          .then((res) => {
                            setMetaCompanySearchResults(res.data.data);
                            setMetaPermalinkResultsLoading(false);
                          })
                          .catch((err) => {
                            console.log(err);
                            setMetaPermalinkResultsLoading(false);
                          });
                      }}
                    >
                      {metaPeramlinkResultsLoading ? "Loading..." : "Show more"}
                    </button>
                  )}
                </table>
              </div>
            </div>

            {/* SSO ENABLED     */}
            <div className="p-2 mt-4 bg-slate-200 rounded">
              <div className="text-lg flex justify-between items-center font-semibold mb-2">
                <p>sso_enabled</p>
                <input
                  type="checkbox"
                  checked={enabledMetaKeys.includes("sso_enabled")}
                  onClick={() => {
                    setEnabledMetaKeys((prev) =>
                      enabledMetaKeys.includes("sso_enabled")
                        ? prev.filter((item) => item !== "sso_enabled")
                        : [...prev, "sso_enabled"]
                    );
                  }}
                />
              </div>
              {["azuread", "google"].map((item) => {
                return (
                  <div>
                    <p>{item}</p>
                    <input
                      type="checkbox"
                      checked={metaData.sso_enabled.includes(item)}
                      onClick={() => {
                        setMetaData((prev) => {
                          return {
                            ...prev,
                            sso_enabled: metaData.sso_enabled.includes(item)
                              ? JSON.parse(
                                  JSON.stringify(metaData.sso_enabled)
                                ).filter((i) => i != item)
                              : [
                                  ...JSON.parse(
                                    JSON.stringify(metaData.sso_enabled)
                                  ),
                                  item,
                                ],
                          };
                        });
                      }}
                    />
                  </div>
                );
              })}

            </div>

            <div className="flex gap-x-2 items-center">  
            <button
            title="Go Back and Edit"
            className="flex gap-1 items-center px-2 py-1 mt-2 border rounded-lg"
            onClick={() => {
              setSteps((prev) => prev - 1);
            }}
          >
            <MdArrowBack />
            </button>

            

          <button
                  className="bg-black disabled:bg-[#B6b6b6] disabled:cursor-not-allowed disabled:text-white text-white py-3 hover:shadow-lg  text-center w-full rounded-lg mt-4"
                  type="button"
                  disabled={
                    errorInName != "" ||
                    formData.name == "" ||
                    formData.account_type == ""
                  }
                  onClick={() => {
                    let metaNewData = JSON.parse(JSON.stringify(metaData));
                    if (metaNewData.logo === null) {
                      metaNewData["logo"] = "";
                    }
                    if (metaNewData.webhook == null) {
                      metaNewData["webhook"] = "";
                    }
                    if (metaNewData.permalink == null) {
                      metaNewData["permalink"] = "";
                    }
                    if (metaNewData.zapier_webhook == null) {
                      metaNewData["zapier_webhook"] = "";
                    }
                    if (metaNewData.pitchbook_proxy == null) {
                      metaNewData["pitchbook_proxy"] = "";
                    }
                    if (metaNewData.pitchbook_api_key == null) {
                      metaNewData["pitchbook_api_key"] = "";
                    }
                    if (metaNewData.external_access.sasToken == null) {
                      let external_access = JSON.parse(
                        JSON.stringify(metaNewData.external_access)
                      );
                      external_access["sasToken"] = "";
                      metaNewData.external_access = external_access;
                    }
                    Object.keys(metaNewData).forEach((key) => {
                      if (!enabledMetaKeys.includes(key)) {
                        delete metaNewData[key];
                      }
                    });
                    setFormData((prev) => ({ ...prev, meta: metaNewData }));
                    setSteps((prev) => prev + 1);
                  }}
                >
                  Next
                </button>
          </div>

          </div>
        )}
        {steps == 4 && (
          <Preview
            isLoading={isLoading}
            setSteps={setSteps}
            formData={formData}
            handleSubmit={handleSubmit}
          />
        )}
      </div>
    </div>
  );
};

export default EditOrganisation;

const Preview = ({ formData, setSteps, isLoading, handleSubmit }) => {
  return (
    <div>
      <div className="w-[676px] p-3 mt-5 border rounded-lg bg-white">
        <p className="text-2xl font-semibold mb-4">Meta Data Preview</p>
        <div className="border rounded-xl bg-gray-50 px-3 py-2">
          {/* "overflow-y:scroll; max-width:400px; height:80px; border:0.5px solid lightgray; padding:5px; white-space:pre-wrap;" */}
          <pre
            style={{
              overflowY: "scroll",
              maxWidth: "100%",
              height: "80vh",
              whiteSpace: "pre-wrap",
            }}
          >
            {JSON.stringify(formData.meta, {}, 4)}
          </pre>
        </div>
        <div className="flex justify-between">
          <button
            title="Go Back and Edit"
            className="flex gap-1 items-center px-2 py-1 mt-2 border rounded-lg"
            onClick={() => {
              setSteps((prev) => prev - 1);
            }}
          >
            <MdArrowBack />
          </button>

          <button
            disabled={isLoading}
            title="Go Back and Edit"
            className="flex gap-1 items-center px-2 py-1 mt-2 border border-black bg-black disabled:bg-[#b6b6b6] disabled:text-white text-white rounded-lg"
            onClick={() => {
              handleSubmit();
            }}
          >
            {!isLoading ? "Edit this Org" : "Loading...."}
          </button>
        </div>
      </div>
    </div>
  );
};
