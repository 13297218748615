import Results from "./components/Results";
import React, { useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { toast } from "react-toastify";
import Input from "../../components/input";
import useAxiosInstance from "../../hooks/useAxios";

const UrlScrapper = () => {
  const [url, setURL] = useState("");
  const [steps, setSteps] = useState(0);
  const [data, setData] = useState({});
  const axiosInstance = useAxiosInstance()

  const handleSubmit = () => {
    setSteps(1);
    axiosInstance
      .post(
        "/assets/run_scrapper/",
        {
          urls:url.split(','),
        }
      )
      .then((res) => {
        setSteps(2);
        console.log(res.data);
        toast.success("Successfull");
        setData(res.data.data);
      })
      .catch((res) => {
        console.log(res);
        toast.error("Something gone wrong");
        setSteps(0);
      });
  };

  return (
    <div
      className={`h-screen w-full justify-center p-5  overflow-y-auto ${
        steps === 1 ? "items-center flex" : "items-start flex"
      } `}
    >
      {steps === 0 ? (
        <div className="w-[676px] p-3 mt-5 border rounded-lg bg-white">
          <p className="text-2xl font-semibold mb-4">URL Scrapper</p>
          <div className="text-sm font-semibold mb-2">URL</div>
          <Input
            value={url}
            onChange={(e) => {
              setURL(e.target.value);
            }}
            placeholder="Enter the URLs(comma seprated)"
          />
          <button
            disabled={url === ""}
            className="bg-black disabled:bg-[#B6b6b6] disabled:text-white text-white py-3 hover:shadow-lg  text-center w-full rounded-lg mt-4"
            type="button"
            onClick={() => {
              handleSubmit();
            }}
          >
            Srape
          </button>
        </div>
      ) : steps === 1 ? (
        <div className="animate-spin">
          <FaSpinner className="text-3xl" />
        </div>
      ) : (
        <div className="">
          {data.map(elem=>{
            return <div className="border-b  mb-16"><Results data={elem}/></div>
          })}
        </div>
      )}
    </div>
  );
};

export default UrlScrapper;
