import React from "react";
import Input from "../../../components/input";
import TableForm from "./TableForm";
import useAxiosInstance from "../../../hooks/useAxios";
import { Spinner } from "flowbite-react";

const Step2forms = (props) => {
  const axios = useAxiosInstance()
  const [isLogoUploading,setIsLogoUploading] = React.useState(false)
  const {
    formData,
    setFormData,
    reset,
    nextPage,
    back,
    // fillInfoFromPreviousStep,
    fundingRoundArray,
    setFundingRounfArray,
    sub_orgsArray,
    setsub_orgsArray,
    list_acquisitionsArray,
    setlist_acquisitionsArray,
    listInvestmenttsArray,
    setlistInvestmenttsArray,
    submit,
    isLoading,
    setlist_executives,
    list_executives,
    org_uuids,
    setorg_uuids
  } = props;
  return (
    <div className="w-full p-3 mt-5 border rounded-lg bg-white">
      <div className="flex justify-between">
        <p className="text-2xl font-semibold mb-4">Extra Details</p>
       
      </div>
      <div className="grid grid-cols-3 gap-4 mb-3">
        <div>
          <div className="text-sm font-semibold mb-2">Headquarter</div>
          <Input
            disabled
            value={formData.hq}
            onChange={(e) => {
              setFormData({ ...formData, hq: e.target.value });
            }}
            placeholder="Enter the Headquarter"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">Crunchbase URL</div>
          <Input
            value={formData.url}
            onChange={(e) => {
              setFormData({ ...formData, url: e.target.value });
            }}
            placeholder="Enter the URL"
          />
        </div>
        <div>
          <div className="text-sm flex gap-x-1 font-semibold mb-2">Logo</div>
          <Input
            type={'file'}
            // value={formData.logo}
            onChange={(e)=>{
              setIsLogoUploading(true)
              const formData_ = new FormData()
              formData_.append('files',e.target.files[0])
              formData_.append('permalink',formData.permalink)
              axios.post('/assets/upload_logo_image/',formData_).then(res=>{
                console.log(res.data)
                setIsLogoUploading(false)
              }).catch(err=>{
                setIsLogoUploading(false)
              })
            }}
            placeholder="Enter the Logo"
          /> {isLogoUploading && <Spinner/>}
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">UUID</div>
          <Input
            disabled={true}
            value={formData.uuid}
            onChange={(e) => {
              setFormData({ ...formData, uuid: e.target.value });
            }}
            placeholder="Enter UUID"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">CB Rank</div>
          <Input
          disabled
          type={'Number'}
            value={formData.cb_rank}
            onChange={(e) => {
              setFormData({ ...formData, cb_rank: Number(e.target.value) });
            }}
            placeholder="Enter CB Rank"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">Website</div>
          <Input
            disabled
            value={formData.website}
            onChange={(e) => {
              setFormData({ ...formData, website: e.target.value });
            }}
            placeholder="Enter Website"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">Organization Name</div>
          <Input
            value={formData.org_name}
            onChange={(e) => {
              setFormData({ ...formData, org_name: e.target.value });
            }}
            placeholder="Enter Organization Name"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">Permalink</div>
          <Input
            disabled
            value={formData.permalink}
            onChange={(e) => {
              setFormData({ ...formData, permalink: e.target.value });
            }}
            placeholder="Enter Permalink"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">Industries</div>
          <Input
            disabled
            value={formData.industries.join(",")}
            onChange={(e) => {
              setFormData({
                ...formData,
                industries: e.target.value.split(","),
              });
            }}
            placeholder="Enter Industries (comma-separated)"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">Founders</div>
          <Input
            value={formData.founders.join(",")}
            onChange={(e) => {
              let storing_value = e.target.value
              if(storing_value.length == 0){
                storing_value = []
              }else{
                storing_value = e.target.value.split(',')
              }
              setFormData({
                ...formData,
                founders: storing_value,
              });
            }}
            placeholder="Enter Founders (comma-separated)"
          />
        </div>
        {/* <div>
          <div className="text-sm font-semibold mb-2">List investors</div>
          <Input
            value={formData.list_investors.join(", ")}
            onChange={(e) => {
              setFormData({
                ...formData,
                list_investors: e.target.value.split(","),
              });
            }}
            placeholder="Enter List investors (comma-separated)"
          />
        </div> */}
        <div>
          <div className="text-sm font-semibold mb-2">IPO Status</div>
          <Input
            disabled
            value={formData.ipo_status}
            onChange={(e) => {
              setFormData({ ...formData, ipo_status: e.target.value });
            }}
            placeholder="Enter IPO Status"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">Legal Name</div>
          <Input
            value={formData.legal_name}
            onChange={(e) => {
              setFormData({ ...formData, legal_name: e.target.value });
            }}
            placeholder="Enter Legal Name"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">Data Source*</div>
          <select
            className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 disabled:bg-gray-300"
            value={formData.data_source}
            onChange={(e) => {
              setFormData({ ...formData, data_source: e.target.value });
            }}
            placeholder="Enter Data Source"
          >
            <option value={""}>choose</option>
            <option value={"wokelo"}>wokelo</option>
            <option value={"crunchbase"}>crunchbase</option>
          </select>
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">Description</div>
          <Input
            disabled
            value={formData.description}
            onChange={(e) => {
              setFormData({ ...formData, description: e.target.value });
            }}
            placeholder="Enter Description"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">Company Type</div>
          <select
            className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 disabled:bg-gray-300"
            value={formData.company_type}
            onChange={(e) => {
              setFormData({ ...formData, company_type: e.target.value });
            }}
            placeholder="Enter Company Type"
          >
           {['','public','private','startup','organization'].map(elem=>{return <option value={elem}>{elem==''?'choose':elem}</option>})} 
          </select>
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">Related Hubs</div>
          <Input
            value={formData.related_hubs}
            onChange={(e) => {
              setFormData({ ...formData, related_hubs: e.target.value });
            }}
            placeholder="Enter Related Hubs"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">Stock Symbol</div>
          <Input
            value={formData.stock_symbol}
            onChange={(e) => {
              setFormData({ ...formData, stock_symbol: e.target.value });
            }}
            placeholder="Enter Stock Symbol"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">Year Founded</div>
          <Input
            disabled
            value={formData.year_founded}
            onChange={(e) => {
              setFormData({ ...formData, year_founded: e.target.value });
            }}
            placeholder="Enter Year Founded"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">Number of Employees</div>
          <select
            className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 disabled:bg-gray-300"
            value={formData.num_employees}
            onChange={(e) => {
              setFormData({ ...formData, num_employees: e.target.value });
            }}
            placeholder="Enter Number of Employees"
          >
            <option value={''}>choose</option>
            <option value={'less than 10'}>less than 10</option>
            <option value={'11-50'}>11-50</option>
            <option value={'51-200'}>51-200</option>
            <option value={'201-500'}>201-500</option>
            <option value={'501-1000'}>501-1000</option>
            <option value={'1001-5000'}>1001-5000</option>
            <option value={'5001-10000'}>5001-10000</option>
            <option value={'10000+'}>10000+</option>
          </select>
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">Number of Investors</div>
          <Input
          disabled  
          type='Number'
            value={formData.num_investors}
            onChange={(e) => {
              setFormData({ ...formData, num_investors: Number(e.target.value) });
            }}
            placeholder="Enter Number of Investors"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">
            Number of investments
          </div>
          <Input
          disabled
          type='Number'
            value={formData.num_investments}
            onChange={(e) => {
              setFormData({ ...formData, num_investments: Number(e.target.value) });
            }}
            placeholder="Enter Number of investments"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">
            Number of Acquisitions
          </div>
          <Input
          disabled
          type='Number'
            value={formData.num_acquisitions}
            onChange={(e) => {
              setFormData({ ...formData, num_acquisitions: Number(e.target.value) });
            }}
            placeholder="Enter Number of Acquisitions"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">Operating Status</div>
          <select
            className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 disabled:bg-gray-300"
            value={formData.operating_status}
            onChange={(e) => {
              setFormData({ ...formData, operating_status: e.target.value });
            }}
            placeholder="Enter Operating Status"
          >
            {['','active','inactive','acquired'].map(elem=>{return <option value={elem}>{elem==''?'choose':elem}</option>})} 
          </select>
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">Last Funding Date</div>
          <Input
            disabled
            value={formData.last_funding_date}
            onChange={(e) => {
              setFormData({ ...formData, last_funding_date: e.target.value });
            }}
            placeholder="Enter Last Funding Date"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">Short Description</div>
          <Input
            disabled
            value={formData.short_description}
            onChange={(e) => {
              setFormData({ ...formData, short_description: e.target.value });
            }}
            placeholder="Enter Short Description"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">Last Funding Round</div>
          <Input
            disabled
            value={formData.last_funding_round}
            onChange={(e) => {
              setFormData({ ...formData, last_funding_round: e.target.value });
            }}
            placeholder="Enter Last Funding Round"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">
            Number of Funding Rounds
          </div>
          <Input
          disabled
          type='Number'
            value={formData.num_funding_rounds}
            onChange={(e) => {
              setFormData({ ...formData, num_funding_rounds: Number(e.target.value) });
            }}
            placeholder="Enter Number of Funding Rounds"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">Last Funding Amount</div>
          <Input
          disabled
          type='Number'
            value={formData.last_funding_amount}
            onChange={(e) => {
              setFormData({ ...formData, last_funding_amount: Number(e.target.value) });
            }}
            placeholder="Enter Last Funding Amount"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">Amount Funding Total</div>
          <Input
          disabled
          type='Number'
            value={formData.amount_funding_total}
            onChange={(e) => {
              setFormData({
                ...formData,
                amount_funding_total: Number(e.target.value),
              });
            }}
            placeholder="Enter Amount Funding Total"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">Location Identifiers</div>
          <Input
            value={formData.location_identifier}
            onChange={(e) => {
              setFormData({
                ...formData,
                location_identifier: e.target.value,
              });
            }}
            placeholder="Enter Location Identifiers"
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">Roles</div>
          <Input
            value={formData.roles}
            onChange={(e) => {
              setFormData({
                ...formData,
                roles: e.target.value.toLowerCase(),
              });
            }}
            placeholder="Enter Roles"
          />
        </div>

        <div>
          <div className="text-sm font-semibold mb-2">Primary Role</div>
          <Input
            value={formData.primary_role}
            onChange={(e) => {
              setFormData({
                ...formData,
                primary_role: e.target.value.toLowerCase(),
              });
            }}
            placeholder="Enter Primary Role"
          />
        </div>

        <div>
          <div className="text-sm font-semibold mb-2">Revenue Range</div>
          <Input
            value={formData.revenue_range}
            onChange={(e) => {
              setFormData({
                ...formData,
                revenue_range: e.target.value,
              });
            }}
            placeholder="Enter Revenue Range"
          />
        </div>

        <div>
          <div className="text-sm font-semibold mb-2">Stock exchange</div>
          <Input
            value={formData.stock_exchange}
            onChange={(e) => {
              setFormData({
                ...formData,
                stock_exchange: e.target.value,
              });
            }}
            placeholder="Enter Stock exchange"
          />
        </div>

        <div>
          <div className="text-sm font-semibold mb-2">Old ticker name</div>
          <Input
            value={formData.old_ticker_name}
            onChange={(e) => {
              setFormData({
                ...formData,
                old_ticker_name: e.target.value,
              });
            }}
            placeholder="Enter Old ticker name"
          />
        </div>

        <div>
          <div className="text-sm font-semibold mb-2">Domain</div>
          <Input
            value={formData.domain}
            onChange={(e) => {
              setFormData({
                ...formData,
                domain: e.target.value,
              });
            }}
            placeholder="Enter domain"
          />
        </div>

       

      </div>

      

      <TableForm
        fundingRoundArray={fundingRoundArray}
        setFundingRounfArray={setFundingRounfArray}
        sub_orgsArray={sub_orgsArray}
        setsub_orgsArray={setsub_orgsArray}
        list_acquisitionsArray={list_acquisitionsArray}
        setlist_acquisitionsArray={setlist_acquisitionsArray}
        listInvestmenttsArray={listInvestmenttsArray}
        setlistInvestmenttsArray={setlistInvestmenttsArray}
        list_executives={list_executives}
        setlist_executives={setlist_executives}
        org_uuids = {org_uuids}
        setorg_uuids = {setorg_uuids}
      />
       <div className="mb-10">
          <div className="text-sm font-semibold mb-2">list_investors*</div>
          <Input
            value={formData.list_investors}
            onChange={(e) => {
              let storing_value = e.target.value

              if(e.target.value.length == 0){
                storing_value = []
              }else{
                storing_value = e.target.value.split(',')
              }
              setFormData({
                ...formData,
                list_investors: storing_value,
              });
            }}
            placeholder="Enter list_investors(comma seprated)"
          />
        </div>
      <div className="flex justify-end w-full">
        <button
          className="border-black px-3 py-1 rounded  mr-2 font-semibold border-2"
          onClick={() => {
            let confirm = window.confirm("Are you sure you want to reset ?");
            if (confirm) {
              reset();
            }
          }}
        >
          Reset
        </button>
        <button
          className="border-black px-3 py-1 rounded  mr-2 font-semibold border-2"
          onClick={() => {
            back();
          }}
        >
          Back
        </button>
        {/* <button
          onClick={() => nextPage()}
          className="border-black bg-black text-white px-3 py-1 rounded  mr-2 font-semibold border-2"
        >
          Preview
        </button> */}
        
        {isLoading ?
        <div
        className="border-black cursor-not-allowed min-w-[100px] flex justify-center bg-slate-100 text-black px-3 py-1 rounded  mr-2 font-semibold border-2"
      >
        <Spinner/>
      </div>:<button
          disabled={formData.data_source === ''}
          onClick={() => submit()}
          className="border-black disabled:cursor-not-allowed disabled:text-black disabled:bg-slate-100 bg-black text-white px-3 py-1 rounded  mr-2 font-semibold border-2"
        >
        Submit 
        </button>}
      </div>

    </div>
  );
};

export default Step2forms;
