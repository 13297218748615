import { Link } from 'react-router-dom';
const lockImg = 'https://wkemails.blob.core.windows.net/fe-application/lock.svg';


const workFlowToIconClassMap = {
    // [WorkflowTypeCode.CONCISE_CP]: 'p-[6px] h-[32px]'
}
const DashboardCard = ({ item, className = '' }) => {
    // const { minDaysToexpiry } = useAppSelector((s) => s?.auth || null);
	console.log(item)
    const openNewTab = false;
    const mainTitleIcon = null;
    const icon = item?.icon;
    const mainTitle = item?.name;
    const description = item?.description;
    const isLocked = item?.subscription?.includes('Lock')
    // const redirectUrl = !isLocked && minDaysToexpiry > 0 && item?.url;
    const iconSecondary = isLocked && lockImg
    // const derivedclassName = isLocked || minDaysToexpiry <= 0 ? 'cursor-not-allowed' : ''
    const isPro = item?.subscription?.includes('Pro')
    const isBeta = item?.subscription?.includes('Beta')

    return (
        <>
            <Link
                className={`flex flex-col w-full sm:w-auto min-w-[14rem] gap-y-3.5 justify-center 
                items-center p-5 border border-[#E5E6E4] 
                 transition-all rounded-lg  hover:bg-[#F7F7F7] ${className} cursor-not-allowed`}
                target={openNewTab ? '_blank' : ''}
                key={`${mainTitleIcon}mainTitle`}
                id={'dashboard-card-' + item?.code}
            >
                <div className={`flex justify-between items-center w-full ${isLocked ? ' opacity-60 ' : ''}`}>
                    <div className='flex items-center gap-x-3'>
                        <img src={icon} className={workFlowToIconClassMap[item?.code] || 'h-[24px] w-[24px]'} />
                        <h5
                            key={`${2}_LayoutSubHead`}
                            className={'font-medium font-Inter-Regular text-base text-[#343434]'}
                        >
                            {mainTitle}
                        </h5>
                    </div>
                    {isPro && !isLocked && <div>
                        <div
                            className={
                                'bg-newTheme-cream/30 p-2 rounded h-6 flex justify-center items-center ml-2 w-[41px]'
                            }
                        >
                            <p
                                className={'font-semibold font-Inter-Regular text-xs leading-[18px] text-newTheme-black text-center'}
                            >
                                PRO
                            </p>
                        </div>
                    </div>}

                    {isBeta && !isLocked && <div>
                        <div
                            className={
                                'bg-[#F4F3F4] p-2 rounded h-6 flex justify-center items-center ml-2 w-[41px]'
                            }
                        >
                            <p
                                className={'font-semibold font-Inter-Regular text-xs leading-[18px] text-newTheme-black text-center'}
                            >
                                Beta
                            </p>
                        </div>
                    </div>}
                    {isLocked &&
                        <DashboardCardLockIcon />
                    }
                </div>

            </Link>
        </>
    );
};

export default DashboardCard

const DashboardCardLockIcon = () => {
	return	<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.34375 11V7C8.34375 5.93913 8.76518 4.92172 9.51532 4.17157C10.2655 3.42143 11.2829 3 12.3438 3C13.4046 3 14.422 3.42143 15.1722 4.17157C15.9223 4.92172 16.3438 5.93913 16.3438 7V11M5.34375 13C5.34375 12.4696 5.55446 11.9609 5.92954 11.5858C6.30461 11.2107 6.81332 11 7.34375 11H17.3438C17.8742 11 18.3829 11.2107 18.758 11.5858C19.133 11.9609 19.3438 12.4696 19.3438 13V19C19.3438 19.5304 19.133 20.0391 18.758 20.4142C18.3829 20.7893 17.8742 21 17.3438 21H7.34375C6.81332 21 6.30461 20.7893 5.92954 20.4142C5.55446 20.0391 5.34375 19.5304 5.34375 19V13ZM11.3438 16C11.3438 16.2652 11.4491 16.5196 11.6366 16.7071C11.8242 16.8946 12.0785 17 12.3438 17C12.609 17 12.8633 16.8946 13.0509 16.7071C13.2384 16.5196 13.3438 16.2652 13.3438 16C13.3438 15.7348 13.2384 15.4804 13.0509 15.2929C12.8633 15.1054 12.609 15 12.3438 15C12.0785 15 11.8242 15.1054 11.6366 15.2929C11.4491 15.4804 11.3438 15.7348 11.3438 16Z" stroke="#202020" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

}