import React, { useState } from "react";
import { FaSpinner } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import Input from "../../components/input";
import useAxiosInstance from "../../hooks/useAxios";

const TaskRestarter = () => {
  const [paramterType, setParameterType] = useState("");
  const [steps, setSteps] = useState(0);
  const [taskId, setTaskId] = useState("");
  const [reportId, setReportId] = useState("");
  const [data, setData] = useState("");
  const axios = useAxiosInstance();

  const handleSubmit = () => {
    let data = {};
    setSteps(1);
    if (paramterType === "taskId") {
      data = {
        task_id: taskId,
      };
    } else if (paramterType === "reportId") {
      data = {
        report_id: reportId,
      };
    }

    axios
      .post("/assets/task_restart/", data)
      .then((res) => {
        setSteps(2);
        toast.info(res.data.status);
        console.log(data);
        console.log(res.data);
        setData(JSON.stringify(res.data));
      })
      .catch((err) => {
        setSteps(2);
        console.log(err);
      });
  };
  return (
    <div
      className={`h-screen w-full justify-center p-5  overflow-y-auto ${
        steps === 1 ? "items-center" : "items-start "
      } flex`}
    >
      {steps === 0 ? (
        <div className="w-[676px] p-3 mt-5 border rounded-lg bg-white">
          <p className="text-2xl font-semibold mb-4">Task Restarter</p>
          <div className="text-sm font-semibold mb-2">Paramter Type</div>
          <select
            value={paramterType}
            onChange={(e) => {
              setReportId("");
              setTaskId("");
              setParameterType(e.target.value);
            }}
            className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 "
            placeholder="Enter your password"
          >
            <option value={""}>Choose</option>
            <option value={"taskId"}>Task Id</option>
            <option value={"reportId"}>Report Id</option>
          </select>

          {paramterType === "taskId" && (
            <div>
              <div className="text-sm font-semibold mb-2 mt-4">Task Id</div>
              <Input
                value={taskId}
                onChange={(e) => {
                  setTaskId(e.target.value);
                }}
                placeholder="Enter the Task Id"
              />
            </div>
          )}

          {paramterType === "reportId" && (
            <div>
              <div className="text-sm font-semibold mb-2 mt-4">Report Id</div>
              <Input
                value={reportId}
                onChange={(e) => {
                  setReportId(e.target.value);
                }}
                placeholder="Enter the Report Id"
              />
            </div>
          )}

          <button
            disabled={
              paramterType === "" ||
              (paramterType === "taskId" && taskId === "") ||
              (paramterType === "reportId" && reportId === "")
            }
            className="bg-black disabled:bg-[#B6b6b6] disabled:text-white disabled:cursor-not-allowed text-white py-3 hover:shadow-lg  text-center w-full rounded-lg mt-4"
            type="button"
            onClick={() => {
              handleSubmit();
            }}
          >
            Submit
          </button>
        </div>
      ) : steps === 1 ? (
        <div className="animate-spin">
          <FaSpinner className="text-3xl" />
        </div>
      ) : (
        <>{data}</>
      )}
    </div>
  );
};

export default TaskRestarter;
