import React from 'react'
import { useNavigate } from 'react-router-dom'
import { options } from '../helper'

const Dashboard = () => {
  const navigate = useNavigate();
  return (
    <div className='mt-10 mr-4'>
      <h1 className='text-2xl bg-white px-3 py-2 rounded-xl mb-4 shadow-sm border font-medium'>Dashboard</h1>
      <div className='grid grid-cols-3 gap-x-3 gap-y-2'>
      {options.map(elem=>{return <div onClick={()=>{
        navigate('/'+elem.value)
      }} className='px-3 py-3 flex items-center text-xl border gap-2 shadow-sm bg-white rounded-xl cursor-pointer'>
        {elem.icon} {elem.label}
      </div>})}
      </div>
    </div>
  )
}

export default Dashboard