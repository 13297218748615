import React from "react";
import Sidebar from "../components/sidebar";

const Layout = (props) => {
  return (
    <div className="flex bg-slate-50 w-[100%]">
      <Sidebar />
      <div className="w-full h-screen overflow-y-auto">{props.children}</div>
    </div>
  );
};

export default Layout;
